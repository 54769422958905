import {
  AccountOwnerCuringsIssueType,
  AccountOwnerDetailsTaxDocumentationIssueType,
} from "@taxbit-dashboard/rest";

export const accountOwnerDetailsCuringIssueTypeToAccountOwnerCuringsIssueType =
  {
    [AccountOwnerDetailsTaxDocumentationIssueType.UsIndicia]:
      AccountOwnerCuringsIssueType.UsIndicia,
    [AccountOwnerDetailsTaxDocumentationIssueType.W8beneCareOf]:
      AccountOwnerCuringsIssueType.W8beneCareOf,
    [AccountOwnerDetailsTaxDocumentationIssueType.W8benePoBoxPermanentAddress]:
      AccountOwnerCuringsIssueType.W8benePoBoxPermanentAddress,
    [AccountOwnerDetailsTaxDocumentationIssueType.TreatyCountryMismatch]:
      AccountOwnerCuringsIssueType.TreatyCountryMismatch,
  };
