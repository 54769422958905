export enum HelpArticleLinkTrackingId {
  CuringDocumentation = "help-article-link-curing-documentation",
  TreatyCountryMismatchCuringDocumentation = "help-article-link-treaty-country-mismatch-curing-documentation",
}

export const curingDocumentationLink =
  "https://taxbitsupport.zendesk.com/hc/en-us/articles/28876526910861-Curing-Documentation-Review";

// TODO: Update help article link for both action required and resolution text https://taxbit.atlassian.net/browse/TAX-41676
export const treatyCountryMismatchCuringDocumentationLink = "#";
