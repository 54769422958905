import {
  DashboardPrimaryTemplate,
  useDefinedCurrentCompanyUser,
} from "@taxbit-dashboard/commons";

import { OverviewDataContextProvider } from "./context/useOverviewData";
import Overview from "./Overview";

const OverviewPage: React.FC = () => {
  const user = useDefinedCurrentCompanyUser();

  const [firstName] = user.name.split(" ");

  return (
    <DashboardPrimaryTemplate title={`Welcome, ${firstName}`}>
      <OverviewDataContextProvider>
        <Overview />
      </OverviewDataContextProvider>
    </DashboardPrimaryTemplate>
  );
};

export default OverviewPage;
