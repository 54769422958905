import { DispositionMethod } from "./sharedApiTypes";

export const payerDispositionMethodLabelMap: Record<DispositionMethod, string> =
  {
    ENTERPRISE_EXCHANGE_HIFO: "Highest In, First Out (HIFO)",
    ENTERPRISE_EXCHANGE_FIFO: "First In, First Out (FIFO) (Default)",
    ENTERPRISE_EXCHANGE_LIFO: "Last In, First Out (LIFO)",
    UNIVERSAL_FIFO: "Universal FIFO",
    UNIVERSAL_HIFO: "Universal HIFO",
    EXCHANGE_HIFO: "Exchange HIFO",
    GB_INDIVIDUAL: "GB Individual",
    GB_BUSINESS: "GB Business",
    LU_WAC: "LU WAC",
  };
