import { StringType } from "@taxbit-private/type-wrappers";
import { uuidSchema } from "@taxbit-private/uuids";
import { z } from "zod";

export type AccountId = StringType<"AccountId">;

export const accountIdSchema = z
  .string()
  .transform((accountId) => accountId as AccountId);

export type OrganizationId = StringType<"OrganizationId">;

export const organizationIdSchema = z
  .string()
  .transform((organizationId) => organizationId as OrganizationId);

export type CompanyUserId = StringType<"CompanyUserId">;

export const companyUserIdSchema = z
  .string()
  .transform((companyUserId) => companyUserId as CompanyUserId);

export type PayerId = StringType<"PayerId">;

export const payerIdSchema = z
  .string()
  .transform((payerId) => payerId as PayerId);

export type OffsetPaginationApiParams = {
  "page[limit]"?: number;
  "page[offset]"?: number;
};

export enum AccountType {
  UsIraTraditional = "US_IRA_TRADITIONAL",
  UsIraRoth = "US_IRA_ROTH",
  UsIraSimple = "US_IRA_SIMPLE",
  UsIraSep = "US_IRA_SEP",
}

export enum ValidationResultCode {
  ValidSsnMatch = "VALID_SSN_MATCH",
  InvalidData = "INVALID_DATA",
  Foreign = "FOREIGN",
  ValidEinMatch = "VALID_EIN_MATCH",
  Mismatch = "MISMATCH",
  Pending = "PENDING",
  TinNotIssued = "TIN_NOT_ISSUED",
  ValidSsnEinMatch = "VALID_SSN_EIN_MATCH",
}

export const validationResultCodeSchema = z.nativeEnum(ValidationResultCode);

export type AccountOwnerId = StringType<"AccountOwnerId">;

export const accountOwnerIdSchema = z
  .string()
  .transform((accountOwnerId) => accountOwnerId as AccountOwnerId);

export const accountTypeSchema = z.nativeEnum(AccountType);

export const yearEndFairMarketValueSchema = z.object({
  yearEnd: z.number(),
  fairMarketValue: z.string(),
});

export type YearEndFairMarketValue = z.infer<
  typeof yearEndFairMarketValueSchema
>;

const DISPOSITION_METHOD = [
  "UNIVERSAL_FIFO",
  "UNIVERSAL_HIFO",
  "EXCHANGE_HIFO",
  "ENTERPRISE_EXCHANGE_HIFO",
  "ENTERPRISE_EXCHANGE_FIFO",
  "ENTERPRISE_EXCHANGE_LIFO",
  "GB_INDIVIDUAL",
  "GB_BUSINESS",
  "LU_WAC",
] as const;

export const dispositionMethodSchema = z.enum(DISPOSITION_METHOD);

export type DispositionMethod = z.infer<typeof dispositionMethodSchema>;

const ACCOUNT_OWNER_TYPE = ["INDIVIDUAL", "ENTITY"] as const;

export const accountOwnerTypeSchema = z.enum(ACCOUNT_OWNER_TYPE);

export const addressSchema = z.object({
  firstLine: z.string().optional(),
  secondLine: z.string().optional(),
  city: z.string().optional(),
  stateOrProvince: z.string().optional(),
  country: z.string().optional(),
  postalCode: z.string().optional(),
});

export enum AccountServiceTinType {
  UsSsn = "US_SSN",
  UsEin = "US_EIN",
  UsAtin = "US_ATIN",
  UsItin = "US_ITIN",
  Other = "OTHER",
}

export const accountOwnerPiiDtoSchema = z.object({
  name: z.string().optional(),
  email: z.string().optional(),
  birthDate: z.string().optional(),
  tin: z.string().optional(),
  maskedTin: z.string().optional(),
  tinType: z.nativeEnum(AccountServiceTinType).optional(),
  taxCountryCode: z.string().optional(),
  address: addressSchema.optional(),
  birthCity: z.string().optional(),
  birthCountry: z.string().optional(),
  vatId: z.string().optional(),
  vatIdMasked: z.string().optional(),
  vatCountry: z.string().optional(),
  businessRegistrationNumber: z.string().optional(),
  businessRegistrationNumberMasked: z.string().optional(),
  businessRegistrationCountry: z.string().optional(),
  financialAccountId: z.string().optional(),
  financialAccountIdMasked: z.string().optional(),
  financialAccountHolderName: z.string().optional(),
});

export const irsTinMatchValidationSchema = z.object({
  validationType: z.literal("IRS_TIN_MATCH"),
  validationResult: validationResultCodeSchema.optional(),
  validationResponseDate: z.string().optional(),
});

export type TinMatchReportingValidation = z.infer<
  typeof irsTinMatchValidationSchema
>;

export const VAT_VALIDATION_TYPE = "VAT" as const;

export const vatValidationTypeSchema = z.literal(VAT_VALIDATION_TYPE);

export const validationStatusSchema = z.enum(["IN_PROGRESS", "COMPLETE"]);

export const VAT_VALIDATION_RESULT = [
  "PENDING",
  "VALID",
  "INVALID",
  "INSUFFICIENT_DATA",
  "NOT_REQUIRED",
] as const;

export const vatValidationResultSchema = z.enum(VAT_VALIDATION_RESULT);

export const TAX_DOCUMENT_STATUS_VALIDATION_RESULT = [
  "VALID",
  "INVALID",
  "UNDOCUMENTED",
  "UNDETERMINED",
] as const;

export const taxDocumentStatusValidationResultSchema = z.enum(
  TAX_DOCUMENT_STATUS_VALIDATION_RESULT
);

export type TaxDocumentStatusValidationResult = z.infer<
  typeof taxDocumentStatusValidationResultSchema
>;

export const TAX_DOCUMENT_STATUS_VALIDATION_TYPE =
  "TAX_DOCUMENT_STATUS" as const;

const taxDocumentStatusValidationTypeSchema = z.literal(
  TAX_DOCUMENT_STATUS_VALIDATION_TYPE
);

export const taxDocumentStatusValidationSchema = z.object({
  dateModified: z.string(),
  dateCreated: z.string(),
  accountOwnerId: accountOwnerIdSchema,
  validationVersion: z.number(),
  validationStatus: validationStatusSchema,
  validationType: taxDocumentStatusValidationTypeSchema,
  validationResult: taxDocumentStatusValidationResultSchema,
});

export const vatValidationSchema = z.object({
  dateModified: z.string(),
  dateCreated: z.string(),
  accountOwnerId: accountOwnerIdSchema,
  validationVersion: z.number(),
  validationStatus: validationStatusSchema,
  validationType: vatValidationTypeSchema,
  validationResult: vatValidationResultSchema,
});

const accountOwnerValidationMapSchema = z.object({
  irsTinMatch: irsTinMatchValidationSchema.optional(),
  vat: vatValidationSchema.optional(),
  taxDocumentStatus: taxDocumentStatusValidationSchema.optional(),
});

export const accountOwnerSchema = z.object({
  accountOwnerId: accountOwnerIdSchema,
  dateModified: z.string(),
  dateCreated: z.string(),
  organizationId: uuidSchema,
  externalId: z.string(),
  accountOwnerType: accountOwnerTypeSchema,
  prefersPhysicalMail: z.boolean().optional(),
  isTaxExempt: z.boolean().optional(),
  hasFatcaFilingRequirement: z.boolean().optional(),
  hasSecondTinNotice: z.boolean().optional(),
  isSubjectToBackupWithholding: z.boolean().optional(),
  pii: accountOwnerPiiDtoSchema,
  validation: accountOwnerValidationMapSchema,
});

export const accountV2Schema = z.object({
  accountId: accountIdSchema,
  dateModified: z.string(),
  dateCreated: z.string(),
  accountOwnerId: accountOwnerIdSchema,
  organizationId: organizationIdSchema,
  payerId: payerIdSchema.optional(),
  externalId: z.string(),
  accountType: accountTypeSchema.optional(),
  establishmentDate: z.string().optional(),
  yearEndFairMarketValue: yearEndFairMarketValueSchema.array().optional(),
  dispositionMethod: dispositionMethodSchema.optional(),
  accountOwner: accountOwnerSchema,
});

export enum AccountOwnerCuringsIssueType {
  W8benePoBoxPermanentAddress = "W8BENE_PO_BOX_PERMANENT_ADDRESS",
  UsIndicia = "US_INDICIA",
  W8beneCareOf = "W8BENE_CARE_OF_PERMANENT_ADDRESS",
  TreatyCountryMismatch = "TREATY_COUNTRY_MISMATCH",
}

export const createAccountOwnerCuringRequestSchema = z.object({
  accountOwnerId: accountOwnerIdSchema,
  issueType: z.nativeEnum(AccountOwnerCuringsIssueType),
  target: z.string(),
  note: z.string().optional(),
});

export const accountOwnerCuringSchema =
  createAccountOwnerCuringRequestSchema.extend({
    id: z.number(),
    organizationId: organizationIdSchema,
    companyUserId: companyUserIdSchema,
    createdAt: z.string(),
    updatedAt: z.string(),
  });

export const dateFiltersSchema = z.object({
  gte: z.string().optional(),
  lte: z.string().optional(),
});

export const pageSchema = z.object({
  offset: z.number(),
  limit: z.number(),
});

export type VatValidationResult = z.infer<typeof vatValidationResultSchema>;

export const W_FORM_TAX_CLASSIFICATION = [
  "INDIVIDUAL",
  "ENTITY",
  "LLC_C",
  "LLC_P",
  "LLC_S",
  "CORPORATION",
  "C_CORPORATION",
  "S_CORPORATION",
  "PARTNERSHIP",
  "TRUST_ESTATE",
  "SIMPLE_TRUST",
  "COMPLEX_TRUST",
  "GRANTOR_TRUST",
  "ESTATE",
  "CENTRAL_BANK_OF_ISSUE",
  "FOREIGN_GOVERNMENT_CONTROLLED_ENTITY",
  "FOREIGN_GOVERNMENT_INTEGRAL_PART",
  "TAX_EXEMPT_ORGANIZATION",
  "PRIVATE_FOUNDATION",
  "INTERNATIONAL_ORGANIZATION",
  "OTHER",
] as const;

export const TIN_STATUS = [
  "PENDING",
  "FOREIGN",
  "INVALID_DATA",
  "VALID_SSN_MATCH",
  "VALID_EIN_MATCH",
  "VALID_SSN_EIN_MATCH",
  "MISMATCH",
  "TIN_NOT_ISSUED",
  "ERROR",
] as const;

export const irsTinMatchValidationResultSchema = z.enum(TIN_STATUS);

export type IrsTinMatchValidationResult = z.infer<
  typeof irsTinMatchValidationResultSchema
>;
