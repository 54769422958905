import { StatCard } from "@taxbit-private/cosmic";
import { useCosmicLocalizationContext } from "@taxbit-private/cosmic-localization";

import { useOverviewDataContext } from "../context/useOverviewData";

export enum StatCardsTrackingId {
  TotalAccounts = "overview-stat-card-total-accounts",
  TotalAccountsErrorIcon = "overview-stat-card-total-accounts-error-icon",
}

const StatCards = () => {
  const { formatWholeQuantity } = useCosmicLocalizationContext();

  const {
    totalAccountsCountQuery: { data, isLoading, isError },
  } = useOverviewDataContext();

  return (
    <StatCard
      trackingId={StatCardsTrackingId.TotalAccounts}
      title="Total Accounts"
      value={formatWholeQuantity(data)}
      isLoading={isLoading}
      hasError={isError}
      errorIconProps={{
        trackingId: StatCardsTrackingId.TotalAccountsErrorIcon,
        tooltipProps: {
          content:
            "Could not load data. Refresh the page to try again or come back later.",
        },
      }}
    />
  );
};

export default StatCards;
