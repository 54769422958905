import { formatTaxId, isDefined } from "@taxbit-dashboard/commons";
import { ActionSnippet } from "@taxbit-private/cosmic";
import {
  COSMIC_VALUE_PLACEHOLDER,
  useCosmicLocalizationContext,
} from "@taxbit-private/cosmic-localization";
import { useMemo } from "react";

import { ReportingProfileRowData } from "./useGetReportingProfile";
import ValueList from "./ValueList";
import {
  RETIREMENT_TOOLTIP_COPY,
  accountTypeMap,
} from "../../../../api/account/accountApiTypes";
import { taxClassificationMap } from "../../../../api/kyc-tax-documentation/kycTaxDocumentationApiTypes";
import { getIsoCountryName } from "../../../../utils/getIsoCountryName";
import maskData from "../../../../utils/maskData";
import maskTaxId from "../../../../utils/maskTaxId";
import useFormatUsdValue from "../../../../utils/useFormatUsdValue";
import AccountAddress from "../AccountAddress";
import InvalidCountry from "../InvalidCountry";
import TinBadge from "../TinBadge";
import VatBadge from "../VatBadge";

export enum ReportingProfileRowTrackingId {
  AccountId = "payee-information-row-account-id",
  AccountExternalId = "payee-information-row-account-external-id",
  FilerId = "payee-information-row-filer-id",
  AccountOwnerId = "payee-information-row-account-owner-id",
  AccountOwnerExternalId = "payee-information-row-account-owner-external-id",
  ResidencyTin = "payee-information-row-residency-tin",
  VatId = "payee-information-row-vat-id",
  BusinessRegistrationNumber = "payee-information-row-business-registration-number",
  FinancialAccountId = "payee-information-row-financial-account-id",
  RetirementTooltipCopy = "payee-information-row-retirement-tooltip-copy",
}

type Props = {
  shouldUnmaskValue: boolean;
  globalReportingProfile?: ReportingProfileRowData;
  usReportingProfile?: ReportingProfileRowData;
};

export const useReportingProfileRows = (props: Props) => {
  const { shouldUnmaskValue, globalReportingProfile, usReportingProfile } =
    props;

  const { formatDateTime, formatBoolean } = useCosmicLocalizationContext();
  const formatUsdValue = useFormatUsdValue();

  return useMemo(() => {
    const buildReportingProfileRows = (
      reportingProfile?: ReportingProfileRowData
    ) => {
      if (!reportingProfile) {
        return undefined;
      }

      const birthCountry = getIsoCountryName(
        reportingProfile.profileData?.birthCountry
      );

      const vatCountry = getIsoCountryName(
        reportingProfile.profileData?.vatCountry
      );

      const businessRegistrationCountry = getIsoCountryName(
        reportingProfile.profileData?.businessRegistrationCountry
      );

      const reportingProfileRows = {
        key: "reporting-profile",
        rows: [
          {
            label: "Account Type",
            content: reportingProfile.accountType
              ? accountTypeMap[reportingProfile.accountType]
              : COSMIC_VALUE_PLACEHOLDER,
            key: "account-type",
          },
          {
            label: "Filer ID",
            content: (
              <ActionSnippet
                contents={reportingProfile.filerId}
                shouldCopy={true}
                trackingId={ReportingProfileRowTrackingId.FilerId}
              />
            ),
            key: ReportingProfileRowTrackingId.FilerId,
          },
          ...(reportingProfile.profileType === "US"
            ? [
                {
                  label: "Fair Market Value",
                  content: reportingProfile.mostRecentFairMarketValue
                    ? formatUsdValue(reportingProfile.mostRecentFairMarketValue)
                    : COSMIC_VALUE_PLACEHOLDER,
                  key: "fair-market-value",
                  helpIconProps: {
                    tooltipProps: {
                      content: RETIREMENT_TOOLTIP_COPY,
                    },
                    trackingId:
                      ReportingProfileRowTrackingId.RetirementTooltipCopy,
                  },
                },
              ]
            : []),
          {
            label: "Tax Documentation Type",
            content: reportingProfile.taxDocumentationType,
            key: "tax-documentation-type",
          },
          {
            label: "Valid Tax Documentation",
            content: isDefined(reportingProfile.isTaxDocumentationValid)
              ? formatBoolean(reportingProfile.isTaxDocumentationValid)
              : COSMIC_VALUE_PLACEHOLDER,
            key: "valid-tax-documentation",
          },
        ],
      };

      const profileDataRows = {
        key: "profile-data",
        rows: [
          {
            label: "Name",
            content: reportingProfile.profileData?.name,
            key: "name",
          },
          {
            label: "Email",
            content: reportingProfile.profileData?.email,
            key: "email",
          },
          {
            label: "Date of Birth",
            content: reportingProfile.profileData?.birthDate
              ? formatDateTime({
                  date: reportingProfile.profileData.birthDate,
                })
              : COSMIC_VALUE_PLACEHOLDER,
            key: "birth-date",
          },
          {
            label: "Tax Classification",
            content: reportingProfile.profileData?.taxClassification
              ? taxClassificationMap[
                  reportingProfile.profileData.taxClassification
                ]
              : COSMIC_VALUE_PLACEHOLDER,
            key: "tax-classification",
          },
          {
            label: "Address",
            content: reportingProfile.profileData?.address ? (
              <AccountAddress address={reportingProfile.profileData.address} />
            ) : (
              COSMIC_VALUE_PLACEHOLDER
            ),
            key: "address",
          },
          {
            label: "Mailing Address",
            content: reportingProfile.profileData?.mailingAddress ? (
              <AccountAddress
                address={reportingProfile.profileData.mailingAddress}
              />
            ) : (
              COSMIC_VALUE_PLACEHOLDER
            ),
            key: "mailing-address",
          },
          {
            label: "Permanent Address",
            content: reportingProfile.profileData?.permanentAddress ? (
              <AccountAddress
                address={reportingProfile.profileData.permanentAddress}
              />
            ) : (
              COSMIC_VALUE_PLACEHOLDER
            ),
            key: "permanent-address",
          },
          {
            label: "Mailing Address Is Different",
            content: isDefined(
              reportingProfile.profileData?.mailingAddressIsDifferent
            )
              ? formatBoolean(
                  reportingProfile.profileData.mailingAddressIsDifferent
                )
              : COSMIC_VALUE_PLACEHOLDER,
            key: "mailing-address-is-different",
          },
          {
            label: "Is Tax Exempt",
            content: isDefined(reportingProfile.profileData?.isTaxExempt)
              ? formatBoolean(reportingProfile.profileData.isTaxExempt)
              : COSMIC_VALUE_PLACEHOLDER,
            key: "is-tax-exempt",
          },
          {
            label: "Prefers Physical Mail",
            content: isDefined(
              reportingProfile.profileData?.prefersPhysicalMail
            )
              ? formatBoolean(reportingProfile.profileData.prefersPhysicalMail)
              : COSMIC_VALUE_PLACEHOLDER,
            key: "prefers-physical-mail",
          },
          ...(reportingProfile.profileType === "US"
            ? [
                {
                  label: "Doing Business As",
                  content: reportingProfile.profileData?.dbaName,
                  key: "dba-name",
                },
                {
                  label: "Exempt Fatca Code",
                  conent: reportingProfile.profileData?.exemptFatcaCode,
                  key: "exempt-fatca-code",
                },
                {
                  label: "Exempt Payee Code",
                  conent: reportingProfile.profileData?.exemptPayeeCode,
                  key: "exempt-payee-code",
                },
                {
                  label: "Has Fatca Filing Requirement",
                  content: isDefined(
                    reportingProfile.profileData?.hasFatcaFilingRequirement
                  )
                    ? formatBoolean(
                        reportingProfile.profileData.hasFatcaFilingRequirement
                      )
                    : COSMIC_VALUE_PLACEHOLDER,
                  key: "has-fatca-filing-requirement",
                },
                {
                  label: "Has Second TIN Notice",
                  content: isDefined(
                    reportingProfile.profileData?.hasSecondTinNotice
                  )
                    ? formatBoolean(
                        reportingProfile.profileData.hasSecondTinNotice
                      )
                    : COSMIC_VALUE_PLACEHOLDER,
                  key: "has-second-tin-notice",
                },
                {
                  label: "Subject to Backup Withholding",
                  content: isDefined(
                    reportingProfile.profileData
                      ?.isNotSubjectToBackupWithholding
                  )
                    ? formatBoolean(
                        !reportingProfile.profileData
                          .isNotSubjectToBackupWithholding
                      )
                    : COSMIC_VALUE_PLACEHOLDER,
                  key: "subject-to-backup-withholding",
                },
                {
                  label: "Reference Numbers",
                  content: reportingProfile.profileData?.referenceNumbers,
                  key: "reference-numbers",
                },
                {
                  label: "Establishment Date",
                  content: reportingProfile.profileData?.establishmentDate
                    ? formatDateTime({
                        date: reportingProfile.profileData.establishmentDate,
                      })
                    : COSMIC_VALUE_PLACEHOLDER,
                  key: "establishment-date",
                  helpIconProps: {
                    tooltipProps: {
                      content: RETIREMENT_TOOLTIP_COPY,
                    },
                    trackingId:
                      ReportingProfileRowTrackingId.RetirementTooltipCopy,
                  },
                },
              ]
            : []),
          ...(reportingProfile.profileType === "GLOBAL"
            ? [
                {
                  label: "Birth City",
                  content: reportingProfile.profileData?.birthCity,
                  key: "birth-city",
                },
                {
                  label: "Birth Country",
                  content:
                    birthCountry === "" ? <InvalidCountry /> : birthCountry,
                  key: "birth-country",
                },
                {
                  label: "Business Registration Number",
                  content: (
                    <ActionSnippet
                      contents={
                        reportingProfile.profileData
                          ?.businessRegistrationNumber ??
                        reportingProfile.profileData
                          ?.businessRegistrationNumberMasked
                      }
                      shouldMask={shouldUnmaskValue}
                      maskContents={(value) =>
                        maskData(value) ?? COSMIC_VALUE_PLACEHOLDER
                      }
                      trackingId={
                        ReportingProfileRowTrackingId.BusinessRegistrationNumber
                      }
                    />
                  ),
                  key: ReportingProfileRowTrackingId.BusinessRegistrationNumber,
                },
                {
                  label: "Business Registration Country",
                  content:
                    businessRegistrationCountry === "" ? (
                      <InvalidCountry />
                    ) : (
                      businessRegistrationCountry
                    ),
                  key: "business-registration-country",
                },
                {
                  label: "Financial Account ID",
                  content: (
                    <ActionSnippet
                      contents={
                        reportingProfile.profileData?.financialAccountId ??
                        reportingProfile.profileData?.financialAccountIdMasked
                      }
                      shouldMask={shouldUnmaskValue}
                      maskContents={(value) =>
                        maskData(value) ?? COSMIC_VALUE_PLACEHOLDER
                      }
                      trackingId={
                        ReportingProfileRowTrackingId.FinancialAccountId
                      }
                    />
                  ),
                  key: ReportingProfileRowTrackingId.FinancialAccountId,
                },
                {
                  label: "Financial Account Holder Name",
                  content:
                    reportingProfile.profileData?.financialAccountHolderName,
                  key: "financial-account-holder-name",
                },
              ]
            : []),
        ],
      };

      const vatRows = {
        key: "vat",
        rows: [
          {
            label: "VAT Number",
            content: (
              <ActionSnippet
                contents={
                  reportingProfile.profileData?.vatId ??
                  reportingProfile.profileData?.vatIdMasked
                }
                shouldMask={shouldUnmaskValue}
                maskContents={(value) =>
                  maskData(value) ?? COSMIC_VALUE_PLACEHOLDER
                }
                trackingId={ReportingProfileRowTrackingId.VatId}
              />
            ),
            key: ReportingProfileRowTrackingId.VatId,
          },
          {
            label: "VAT Number Status",
            content: (
              <VatBadge
                vatValidationStatus={reportingProfile.profileData?.vatStatus}
              />
            ),
            key: "vat-status",
          },
          {
            label: "VAT Country",
            content: vatCountry === "" ? <InvalidCountry /> : vatCountry,
            key: "vat-country",
          },
          {
            label: "VAT Number Not Required",
            content: isDefined(reportingProfile.profileData?.vatinNotRequired)
              ? formatBoolean(reportingProfile.profileData.vatinNotRequired)
              : COSMIC_VALUE_PLACEHOLDER,
            key: "vat-number-not-required",
          },
        ],
      };

      const residencyAndTinRows = reportingProfile.profileData
        ?.residenciesAndTins
        ? reportingProfile.profileData.residenciesAndTins.map(
            (residencyAndTin) => {
              const taxCountry = getIsoCountryName(
                residencyAndTin.taxCountryCode
              );
              return {
                key: `residency-and-tin-${residencyAndTin.taxCountryCode}`,
                rows: [
                  {
                    label: "Tax Country Code",
                    content:
                      taxCountry === "" ? <InvalidCountry /> : taxCountry,
                    key: "tax-country-code",
                  },
                  {
                    label: "TIN",
                    content: (
                      <ActionSnippet
                        contents={
                          residencyAndTin.tin
                            ? formatTaxId(
                                residencyAndTin.tin,
                                residencyAndTin.tinType
                              )
                            : residencyAndTin.maskedTin
                        }
                        shouldMask={shouldUnmaskValue}
                        maskContents={(value) =>
                          maskTaxId(value, residencyAndTin.tinType) ??
                          COSMIC_VALUE_PLACEHOLDER
                        }
                        trackingId={ReportingProfileRowTrackingId.ResidencyTin}
                      />
                    ),
                    key: ReportingProfileRowTrackingId.ResidencyTin,
                  },
                  {
                    label: "TIN Type",
                    content: residencyAndTin.tinType,
                    key: "tin-type",
                  },
                  {
                    label: "TIN Not Required",
                    content: isDefined(residencyAndTin.tinNotRequired)
                      ? formatBoolean(residencyAndTin.tinNotRequired)
                      : COSMIC_VALUE_PLACEHOLDER,
                    key: "tin-not-required",
                  },
                  ...(residencyAndTin.taxCountryCode === "US" &&
                  reportingProfile.profileType === "US"
                    ? [
                        {
                          label: "US TIN Status",
                          content: reportingProfile.profileData
                            ?.irsTinMatchResult ? (
                            <TinBadge
                              tinValidationStatus={
                                reportingProfile.profileData?.irsTinMatchResult
                              }
                            />
                          ) : (
                            COSMIC_VALUE_PLACEHOLDER
                          ),
                          key: "us-tin-status",
                        },
                        {
                          label: "TIN Verification Date",
                          content: reportingProfile.profileData
                            ?.irsTinMatchResultDate
                            ? formatDateTime({
                                date: reportingProfile.profileData
                                  .irsTinMatchResultDate,
                              })
                            : COSMIC_VALUE_PLACEHOLDER,
                          key: "tin-verification-date",
                        },
                      ]
                    : []),
                ],
              };
            }
          )
        : [];

      const secondaryAccountOwnersRows = {
        key: "secondary-account-owners",
        rows: [
          {
            label: "Additional Account Owners",
            content: (
              <ValueList values={reportingProfile.secondaryAccountOwnerNames} />
            ),
            key: "additional-account-owners",
          },
        ],
      };

      return [
        reportingProfileRows,
        profileDataRows,
        ...(reportingProfile.profileType === "GLOBAL" ? [vatRows] : []),
        ...residencyAndTinRows,
        secondaryAccountOwnersRows,
      ];
    };

    return {
      usProfile: buildReportingProfileRows(usReportingProfile),
      globalProfile: buildReportingProfileRows(globalReportingProfile),
    };
  }, [
    formatDateTime,
    formatBoolean,
    formatUsdValue,
    globalReportingProfile,
    usReportingProfile,
    shouldUnmaskValue,
  ]);
};
