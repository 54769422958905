import {
  CenteredDiv,
  Link,
  useDashboardFeatureFlags,
  useOrganizationPrefixedNavigation,
} from "@taxbit-dashboard/commons";
import {
  Card,
  Flex,
  Divider,
  ProgressBar,
  BodySmall,
  ContentErrorEmptyState,
  HelpIconProps,
  NumericalRowGroup,
  EmptyState,
} from "@taxbit-private/cosmic";
import { useCosmicLocalizationContext } from "@taxbit-private/cosmic-localization";
import { Big } from "big.js";
import React from "react";

import { FormInformation } from "./toFormCountBreakdownInformation";

export enum FormCountInformationTrackingId {
  Tooltip = "form-counts-breakdown-tooltip",
  ManageButton = "form-counts-breakdown-manage-btn",
}

export enum FormCountInformationType {
  Generated = "Generated",
  Filed = "Filed",
}

const formCountInformationTitleMap = {
  [FormCountInformationType.Generated]: "Form Generation",
  [FormCountInformationType.Filed]: "Form Filing",
};

const formCountInformationEmptyStateSubtitleMap = {
  [FormCountInformationType.Generated]:
    "Form generation is not required for this form type.",
  [FormCountInformationType.Filed]:
    "Form filing is not required for this form type.",
};

export type FormCountInformationProps = {
  type: FormCountInformationType;
  tooltipContent: HelpIconProps["tooltipProps"]["content"];
  isError: boolean;
  formInformation?: FormInformation;
  isEmpty?: boolean;
};

const FormCountInformation: React.FC<FormCountInformationProps> = ({
  tooltipContent,
  type,
  isError,
  isEmpty,
  formInformation,
}) => {
  const { hasIrFormsPageAccess } = useDashboardFeatureFlags();
  const { buildOrganizationPrefixedPath } = useOrganizationPrefixedNavigation();
  const {
    original,
    correction,
    void: voidCount,
    total,
    unfulfilled,
    percentage,
    date,
  } = formInformation || {};

  const hasMoreToFulfill = unfulfilled && new Big(unfulfilled).gt(0);

  const countRows = [
    {
      label: `Not ${type}`,
      value: unfulfilled,
      variant: hasMoreToFulfill ? "bold-danger" : "bold",
      key: "unfulfilled",
      hasDivider: true,
      helpIconProps: {
        tooltipProps: {
          content: tooltipContent,
          placement: "bottom",
        },
        trackingId: FormCountInformationTrackingId.Tooltip,
      },
    },
    {
      label: `Total ${type}`,
      value: total,
      variant: "bold",
      key: "total",
    },
    {
      label: "Original",
      value: original,
      variant: "indented",
      key: "original",
    },
    {
      label: "Correction",
      value: correction,
      variant: "indented",
      key: "correction",
    },
    {
      label: "Void",
      value: voidCount,
      variant: "indented",
      key: "void",
      hasDivider: true,
    },
  ] as const;

  const { formatDateTime } = useCosmicLocalizationContext();

  const content = (
    <Flex padding="contentPadding" growItems={true}>
      <Flex direction="column" gap="m">
        <ProgressBar
          value={percentage ?? 0}
          variant={percentage === 100 ? "success" : "secondary"}
          shouldShowDescription={true}
        />
        <Divider />
        <NumericalRowGroup rows={countRows} />
        <BodySmall colorVariant="secondary">
          Last {type}: {formatDateTime({ date, format: "DateTimeShortened" })}
        </BodySmall>
      </Flex>
    </Flex>
  );

  return (
    <Card
      title={formCountInformationTitleMap[type]}
      utilityElement={
        hasIrFormsPageAccess && type === FormCountInformationType.Generated ? (
          <Link
            label="Manage"
            variant="button-secondary"
            trackingId={FormCountInformationTrackingId.ManageButton}
            to={buildOrganizationPrefixedPath("/ir/forms")}
            size="small"
          />
        ) : undefined
      }
    >
      {isError ? (
        <ContentErrorEmptyState
          entity={`${formCountInformationTitleMap[
            type
          ].toLocaleLowerCase()} data`}
        />
      ) : isEmpty ? (
        <CenteredDiv>
          <EmptyState
            title="Not Applicable"
            subtitleElement={formCountInformationEmptyStateSubtitleMap[type]}
            avatarProps={{
              iconName: "file-text",
              variant: "secondary",
            }}
          />
        </CenteredDiv>
      ) : (
        content
      )}
    </Card>
  );
};

export default FormCountInformation;
