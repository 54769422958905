import { MakeGenerics, useNavigate, useSearch } from "@tanstack/react-location";
import { useCallback, useMemo } from "react";

import isEmpty from "../../utils/isEmpty";

// This type def is just to add comments to the hook's return Record<string, unknown>.
export type UseFilterParams<TData extends Record<string, unknown>> = {
  /**
   * The validated and defaulted set of URL params derived from the current URL.
   * These params may not match the URL exactly, but represents the set of params
   * that will be used to fetch data.
   */
  urlParams: TData;
  /**
   * A setter for filter url params. Takes in an object with the key/value pairs of
   * the params that should be updated. No other params will be altered.
   */
  setUrlParams: (newParams: Partial<TData>) => void;
  /**
   * Whether there are currently any params in the URL. We will only lack params on a fresh
   * load of the page view, prior to interacting with any pagination or filter controls.
   */
  hasUrlParams: boolean;
};

type UseUrlFilterParamsConfig<TData extends Record<string, unknown>> = {
  /**
   * A helper method to validate and default the raw URL params for the page.
   * This is often just a thin wrapper around a zod schema's `parse` method,
   * but we have this as a prop to allow for more complex validation logic and
   * more easily unit test the validators.
   */
  validateParams: (rawParams: UrlFilterParams<TData>) => TData;
};

type UrlFilterParamsGenerics<T extends Record<string, unknown>> = MakeGenerics<{
  Search: {
    // All keys become strings or string arrays because
    // we use qs to deserialize url query params.
    [key in keyof T]?: string | string[] | Record<string, string>;
  };
}>;

export type UrlFilterParams<T extends Record<string, unknown>> =
  UrlFilterParamsGenerics<T>["Search"];

const useUrlFilterParams = <TData extends Record<string, unknown>>({
  validateParams,
}: UseUrlFilterParamsConfig<TData>): UseFilterParams<TData> => {
  const rawUrlParams = useSearch();
  const navigate = useNavigate();

  const hasUrlParams = useMemo(() => !isEmpty(rawUrlParams), [rawUrlParams]);

  const setUrlParams = useCallback(
    (newParams: Partial<TData>) => {
      navigate({
        search: (prevParams) => {
          const validatedPrevParams = validateParams(
            // Casting required because React Location is too confused by the generic.
            (prevParams as UrlFilterParams<TData>) ?? {}
          );
          return {
            ...validatedPrevParams,
            ...newParams,
          };
        },
      });
    },
    [navigate, validateParams]
  );

  const urlParams = useMemo(
    // Casting required because React Location is too confused by the generic.
    () => validateParams(rawUrlParams as UrlFilterParams<TData>),
    [rawUrlParams, validateParams]
  );

  return {
    urlParams,
    setUrlParams,
    hasUrlParams,
  };
};

export default useUrlFilterParams;
