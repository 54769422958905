import {
  Card,
  ContentEntityEmptyState,
  ContentErrorEmptyState,
  ContentSpinner,
  DenseRowGroup,
} from "@taxbit-private/cosmic";

import { useGetReportingProfile } from "./useGetReportingProfile";
import { useReportingProfileRows } from "./useReportingProfileRows";

export const US_REPORTING_PROFILE_CARD_SUBTITLE =
  "This data is consolidated for year-end tax reporting to the IRS under U.S. information reporting requirements.";

export const GLOBAL_REPORTING_PROFILE_CARD_SUBTITLE =
  "This data is consolidated for year-end tax reporting under international regimes like MRDP and CESOP.";

export enum ReportingProfileTrackingId {
  ReportingProfileSpinner = "payee-information-spinner",
}

const ReportingProfile: React.FC = () => {
  const {
    isLoading,
    usReportingProfile,
    globalReportingProfile,
    hasGlobalAccountsPageView,
    hasUsAccountsPageView,
    shouldUnmaskValue,
    isReportingProfilesNotFound,
    reportingProfilesLoaded,
  } = useGetReportingProfile();

  const { usProfile, globalProfile } = useReportingProfileRows({
    shouldUnmaskValue,
    usReportingProfile,
    globalReportingProfile,
  });

  const usProfileNotFound =
    isReportingProfilesNotFound || (reportingProfilesLoaded && !usProfile);

  const globalProfileNotFound =
    isReportingProfilesNotFound || (reportingProfilesLoaded && !globalProfile);

  return (
    <>
      {hasUsAccountsPageView && (
        <Card
          title="US Reporting"
          subtitle={US_REPORTING_PROFILE_CARD_SUBTITLE}
        >
          {isLoading ? (
            <ContentSpinner
              trackingId={ReportingProfileTrackingId.ReportingProfileSpinner}
            />
          ) : usProfileNotFound ? (
            <ContentEntityEmptyState entity="US reporting profile" />
          ) : usProfile ? (
            <DenseRowGroup sections={usProfile} />
          ) : (
            <ContentErrorEmptyState entity="US reporting profile" />
          )}
        </Card>
      )}
      {hasGlobalAccountsPageView && (
        <Card
          title="Global Reporting"
          subtitle={GLOBAL_REPORTING_PROFILE_CARD_SUBTITLE}
        >
          {isLoading ? (
            <ContentSpinner
              trackingId={ReportingProfileTrackingId.ReportingProfileSpinner}
            />
          ) : globalProfileNotFound ? (
            <ContentEntityEmptyState entity="Global reporting profile" />
          ) : globalProfile ? (
            <DenseRowGroup sections={globalProfile} />
          ) : (
            <ContentErrorEmptyState entity="Global reporting profile" />
          )}
        </Card>
      )}
    </>
  );
};

export default ReportingProfile;
