import { Row, isDefined, renderExcludedSpan } from "@taxbit-dashboard/commons";
import {
  AccountOwnerDetailsTaxDocumentationIssueType,
  AccountOwnerIssue,
} from "@taxbit-dashboard/rest";
import { Flex } from "@taxbit-private/cosmic";
import {
  useCosmicLocalizationContext,
  COSMIC_VALUE_PLACEHOLDER,
} from "@taxbit-private/cosmic-localization";
import { useMemo } from "react";

import { isAccountOwnerDetailsCuringIssueType } from "../../../../../api/account-owners/accountOwnersApiTypes";
import {
  TaxDocDetails,
  taxClassificationMap,
} from "../../../../../api/kyc-tax-documentation/kycTaxDocumentationApiTypes";
import { IssueDetails } from "../../kyc-tax-documentation/IssueDetails";
import KycBadge from "../../KycBadge";

const getAddressField = (field?: string, hasTrailingSpace: boolean = true) =>
  field ? `${field}${hasTrailingSpace ? " " : ""}` : "";

const useFormDetailsRows = ({
  formDetails,
}: {
  formDetails?: TaxDocDetails;
}) => {
  const { formatDateTime, formatBoolean } = useCosmicLocalizationContext();

  return useMemo(() => {
    if (!formDetails) {
      return undefined;
    }

    const topRows: Row[] = [
      {
        label: "Documentation Status",
        content: <KycBadge taxStatus={formDetails.documentStatus} />,
      },
      {
        label: "Name",
        content: renderExcludedSpan(formDetails.name),
      },
      {
        label: "Tax Classification",
        content: taxClassificationMap[formDetails.taxClassification] ?? "Other",
      },
      {
        label: "Submission Date",
        content: formatDateTime({
          date: formDetails.submissionDate,
        }),
      },
    ];

    if (formDetails.expirationDate) {
      topRows.push({
        label: "Expiration Date",
        content: formatDateTime({
          date: formDetails.expirationDate,
        }),
      });
    }

    if (isDefined(formDetails.subjectToBackupWithholding)) {
      topRows.push({
        label: "Subject to Backup Withholding",
        content: formatBoolean(formDetails.subjectToBackupWithholding),
      });
    }

    const getTargetString = (
      issueType: AccountOwnerDetailsTaxDocumentationIssueType
    ) => {
      if (isAccountOwnerDetailsCuringIssueType(issueType)) {
        return issueType ===
          AccountOwnerDetailsTaxDocumentationIssueType.UsIndicia
          ? "US"
          : issueType ===
              AccountOwnerDetailsTaxDocumentationIssueType.TreatyCountryMismatch
            ? formDetails.treatyCountry
            : formDetails.permanentAddress
              ? `${getAddressField(formDetails.permanentAddress?.firstLine)}${getAddressField(formDetails.permanentAddress?.secondLine)}${getAddressField(formDetails.permanentAddress?.city)}${getAddressField(formDetails.permanentAddress?.stateOrProvince)}${getAddressField(formDetails.permanentAddress?.postalCode)}${getAddressField(formDetails.permanentAddress?.country, false)}`
              : undefined;
      }

      return undefined;
    };

    const hasValidIssue = isDefined(formDetails.permanentAddress);

    const getIssueContent = (issues?: AccountOwnerIssue[]) =>
      isDefined(issues) && issues.length > 0 && hasValidIssue ? (
        <Flex direction="column" gap="s">
          {issues.map((issue) => {
            return (
              <IssueDetails
                issue={issue}
                taxDocumentType={formDetails.documentType}
                key={`issue-${issue.issueType}`}
                targetString={getTargetString(issue.issueType)}
              />
            );
          })}
        </Flex>
      ) : (
        COSMIC_VALUE_PLACEHOLDER
      );

    const middleRows: Row[] = [];
    middleRows.push({
      label: "Issues",
      content: getIssueContent(formDetails.issues),
    });

    const warningRows =
      formDetails.warnings && formDetails.warnings.length > 0
        ? [
            [
              {
                label: "Warnings",
                content: getIssueContent(formDetails.warnings),
              },
            ],
          ]
        : [];

    return [topRows, middleRows, ...warningRows];
  }, [formDetails, formatBoolean, formatDateTime]);
};

export default useFormDetailsRows;
