import { AccountOwnerDetailsTaxDocumentationIssueType } from "@taxbit-dashboard/rest";

export const accountOwnerIssueTypeToDisplayNameMap: Record<
  AccountOwnerDetailsTaxDocumentationIssueType,
  string
> = {
  [AccountOwnerDetailsTaxDocumentationIssueType.UsIndicia]: "US Indicia",
  [AccountOwnerDetailsTaxDocumentationIssueType.W8benePoBoxPermanentAddress]:
    "PO Box Permanent Address",
  [AccountOwnerDetailsTaxDocumentationIssueType.W8benPoBoxPermanentAddress]:
    "PO Box Permanent Address",
  [AccountOwnerDetailsTaxDocumentationIssueType.W8beneCareOf]:
    "Care Of Permanent Address",
  [AccountOwnerDetailsTaxDocumentationIssueType.W8benCareOf]:
    "Care Of Permanent Address",
  [AccountOwnerDetailsTaxDocumentationIssueType.UsPermanentAddress]:
    "US Permanent Address",
  [AccountOwnerDetailsTaxDocumentationIssueType.ExpiredForm]: "Expired Form",
  [AccountOwnerDetailsTaxDocumentationIssueType.TreatyCountryMismatch]:
    "Treaty Country Mismatch",
};
