import { useMutation } from "@tanstack/react-query";
import {
  DashboardQueryKey,
  UserPermission,
  unwrapPublicApiWrappedQuery,
  useTaxBitRest,
  useUserPermission,
} from "@taxbit-dashboard/commons";
import { AccountId } from "@taxbit-dashboard/rest";

import useAccountIdDependentQuery from "../useAccountIdDependentQuery";

export const useGetAccount = (unmaskTaxId?: boolean) => {
  const restSdk = useTaxBitRest();
  const query = useAccountIdDependentQuery({
    queryKey: [DashboardQueryKey.Accounts, !!unmaskTaxId],
    makeQueryFn: (accountId: AccountId) => () =>
      restSdk.account.get({ accountId, unmaskTaxId }),
    pathBuilder: (accountId?: AccountId) =>
      restSdk.account.buildPath(accountId),
  });
  return unwrapPublicApiWrappedQuery(query);
};

export const useGetAccountV2 = (unmaskTaxId?: boolean) => {
  const restSdk = useTaxBitRest();
  const canUnmaskTaxId = useUserPermission(UserPermission.ReadTins);

  const query = useAccountIdDependentQuery({
    queryKey: [DashboardQueryKey.AccountsV2, !!canUnmaskTaxId],
    makeQueryFn: (accountId: AccountId) => () =>
      restSdk.accountV2.get({ accountId, unmaskTaxId }),
    pathBuilder: (accountId?: AccountId) =>
      restSdk.accountV2.buildPath(accountId),
  });

  return unwrapPublicApiWrappedQuery(query);
};

export const useGetAccountReportingProfiles = (
  hasReportingProfile: boolean,
  unmaskTaxId?: boolean
) => {
  const restSdk = useTaxBitRest();
  const canUnmaskTaxId = useUserPermission(UserPermission.ReadTins);

  const query = useAccountIdDependentQuery({
    queryKey: [DashboardQueryKey.AccountReportingProfiles, !!canUnmaskTaxId],
    makeQueryFn: (accountId: AccountId) => () =>
      restSdk.accountReportingProfiles.get({ accountId, unmaskTaxId }),
    pathBuilder: (accountId?: AccountId) =>
      restSdk.accountReportingProfiles.buildPath(accountId),
    enabled: hasReportingProfile,
  });

  return unwrapPublicApiWrappedQuery(query);
};
export const useGetAccountByExternalId = () => {
  const restSdk = useTaxBitRest();

  /**
   * While it's typically best practice to use a `useQuery` hook for `get`
   * calls, this particular hook is used in combination with a form. It is
   * significantly cleaner to call a mutation on form submit, so we are opting
   * for improved developer experience over RQ best practice in this case.
   */
  return useMutation({
    mutationFn: (externalId: string) =>
      restSdk.accountV2.externalId.get({ externalId }),
  });
};
