import {
  Link,
  renderStringOrPlaceholder,
  useOrganizationPrefixedNavigation,
  useSetCurrentPageAsBackHref,
  useDashboardStore,
  BaseGrid as Grid,
} from "@taxbit-dashboard/commons";
import { Transfer, TransferType } from "@taxbit-dashboard/rest";
import { Box, Snippet, Divider } from "@taxbit-private/cosmic";
import { useCosmicLocalizationContext } from "@taxbit-private/cosmic-localization";

import useFormatAsset from "../../../utils/useFormatAsset";
import getTransferIntentionLabel from "../shared/getTransferIntentionLabel";
import TransferStatusBadge from "../shared/TransferStatusBadge";

type TransferInformationProps = {
  transfer: Transfer;
};

export enum TransferInformationTrackingId {
  ToAccountLink = "transfer-information-to-account-link",
}

const TransferInformation: React.FC<TransferInformationProps> = ({
  transfer,
}) => {
  const setCurrentPageAsBackHref = useSetCurrentPageAsBackHref();
  const resetPageContext = useDashboardStore((store) => store.resetPageContext);
  const { buildOrganizationPrefixedPath } = useOrganizationPrefixedNavigation();

  const { formatDateTime } = useCosmicLocalizationContext();
  const formatAsset = useFormatAsset();

  const {
    type,
    datetime,
    assetAmount,
    status,
    intention,
    accountId,
    transactionId,
    transactionHash,
    toAddress,
    fromAddress,
  } = transfer;

  const transferLabel =
    type === TransferType.TransferIn ? "Transfer In" : "Transfer Out";
  const assetLabel =
    type === TransferType.TransferIn ? "Asset In" : "Asset Out";

  return (
    <Box>
      <Grid alignment="center" padding="l" rowGap="l">
        <Snippet label="Type" content={transferLabel} />
        <Snippet
          label="Date"
          content={formatDateTime({ date: datetime, format: "DateTime" })}
        />
        <Snippet label={assetLabel} content={formatAsset({ assetAmount })} />
        <Snippet
          label="Status"
          content={<TransferStatusBadge status={status} />}
        />
        <Snippet
          label="Transfer Type"
          content={getTransferIntentionLabel(intention)}
        />
      </Grid>
      <Divider />
      <Grid alignment="center" padding="l" rowGap="l">
        <Snippet
          label="Account"
          content={
            <Link
              to={buildOrganizationPrefixedPath(`/accounts/${accountId}`)}
              onClick={() => {
                // Start off page context for all nested views on a clean state.
                resetPageContext(["transactions", "transaction-details"]);
                setCurrentPageAsBackHref("account-details");
              }}
              variant="anchor-primary-inline"
              label="View Details"
              trackingId={TransferInformationTrackingId.ToAccountLink}
            />
          }
        />
        <Snippet label="Transaction ID" content={transactionId} />
        <Snippet
          label="Transaction Hash"
          content={renderStringOrPlaceholder(transactionHash)}
        />
        <Snippet
          label="From Address"
          content={renderStringOrPlaceholder(fromAddress)}
        />
        <Snippet
          label="To Address"
          content={renderStringOrPlaceholder(toAddress)}
        />
      </Grid>
    </Box>
  );
};

export default TransferInformation;
