import {
  UserPermission,
  useDashboardFeatureFlags,
  useOrganizationPrefixedNavigation,
  useUserPermission,
} from "@taxbit-dashboard/commons";
import { CalloutButton, CalloutButtonPanel } from "@taxbit-private/cosmic";
import { useMemo } from "react";

enum CalloutButtonTrackingId {
  UploadData = "upload-data-callout-button",
  ReviewEligibleAccounts = "review-eligible-accounts-callout-button",
  DownloadReports = "download-reports-callout-button",
}

const CalloutButtons = () => {
  const { prefixedNavigate } = useOrganizationPrefixedNavigation();

  const { hasIngestionModuleAccess, hasEligibilityPageAccess } =
    useDashboardFeatureFlags();
  const hasUploadFilesPermission = useUserPermission(
    UserPermission.UploadFiles
  );
  const hasReadTaxReportsPermission = useUserPermission(
    UserPermission.ReadTaxReports
  );

  const shouldShowIngestCallout = useMemo(
    () => hasUploadFilesPermission && hasIngestionModuleAccess,
    [hasUploadFilesPermission, hasIngestionModuleAccess]
  );
  const ingestCallout = useMemo(
    () => (
      <CalloutButton
        description="Ingest a new file with account, transaction, or aggregate form data."
        iconName="plus"
        onClick={() => prefixedNavigate({ to: "/manage-data/ingest" })}
        title="Upload data"
        trackingId={CalloutButtonTrackingId.UploadData}
      />
    ),
    [prefixedNavigate]
  );

  const shouldShowEligibilityCallout = useMemo(
    () => hasEligibilityPageAccess,
    [hasEligibilityPageAccess]
  );
  const eligibilityCallout = useMemo(
    () => (
      <CalloutButton
        description="Check on reporting and identify any accounts with incomplete data."
        iconName="user"
        onClick={() => prefixedNavigate({ to: "/ir/eligibility" })}
        title="Review eligible accounts"
        trackingId={CalloutButtonTrackingId.ReviewEligibleAccounts}
      />
    ),
    [prefixedNavigate]
  );

  const shouldShowReportsCallout = useMemo(
    () => hasReadTaxReportsPermission,
    [hasReadTaxReportsPermission]
  );
  const reportsCallout = useMemo(
    () => (
      <CalloutButton
        description="Download key reports to view and analyze your data."
        iconName="check-circle"
        onClick={() => prefixedNavigate({ to: "/reports" })}
        title="Download reports"
        trackingId={CalloutButtonTrackingId.DownloadReports}
      />
    ),
    [prefixedNavigate]
  );

  if (
    !shouldShowIngestCallout &&
    !shouldShowEligibilityCallout &&
    !shouldShowReportsCallout
  ) {
    return undefined;
  }

  return (
    <CalloutButtonPanel>
      {shouldShowIngestCallout && ingestCallout}
      {shouldShowEligibilityCallout && eligibilityCallout}
      {shouldShowReportsCallout && reportsCallout}
    </CalloutButtonPanel>
  );
};

export default CalloutButtons;
