import { formatTaxId, isDefined } from "@taxbit-dashboard/commons";
import {
  payerDispositionMethodLabelMap,
  AccountOwner,
} from "@taxbit-dashboard/rest";
import { ActionSnippet } from "@taxbit-private/cosmic";
import {
  COSMIC_VALUE_PLACEHOLDER,
  useCosmicLocalizationContext,
} from "@taxbit-private/cosmic-localization";
import { useMemo } from "react";

import { AccountRowData } from "./useGetAccountDetails";
import {
  RETIREMENT_TOOLTIP_COPY,
  accountTypeMap,
  tinTypeMap,
} from "../../../../api/account/accountApiTypes";
import { taxClassificationMap } from "../../../../api/kyc-tax-documentation/kycTaxDocumentationApiTypes";
import { getIsoCountryName } from "../../../../utils/getIsoCountryName";
import maskData from "../../../../utils/maskData";
import maskTaxId from "../../../../utils/maskTaxId";
import useFormatUsdValue from "../../../../utils/useFormatUsdValue";
import AccountAddress from "../AccountAddress";
import InvalidCountry from "../InvalidCountry";
import KycBadge from "../KycBadge";
import TinBadge from "../TinBadge";
import VatBadge from "../VatBadge";

export enum AccountDetailsRowTrackingId {
  TaxbitAccountOwnerId = "account-details-taxbit-account-owner-id",
  AccountOwnerId = "account-details-account-owner-id",
  Tin = "account-details-tin",
  TaxbitAccountId = "account-details-taxbit-account-id",
  FilerId = "account-details-filer-id",
  AccountId = "account-details-account-id",
  AccountType = "account-details-account-type",
  VatId = "account-details-vat-id",
  BusinessRegistrationNumber = "account-details-business-registration-number",
  FinancialAccountId = "account-details-financial-account-id",
  RetirementTooltipCopy = "account-details-retirement-tooltip-copy",
}

export const useAccountDetailsRows = ({
  account,
  accountOwner,
  shouldUnmaskValue,
}: {
  shouldUnmaskValue: boolean;
  account?: AccountRowData;
  accountOwner?: AccountOwner;
}) => {
  const { formatDateTime, formatBoolean } = useCosmicLocalizationContext();
  const formatUsdValue = useFormatUsdValue();

  return useMemo(() => {
    if (!account || !accountOwner) {
      return undefined;
    }

    const taxCountry = getIsoCountryName(accountOwner?.pii.taxCountryCode);
    const birthCountry = getIsoCountryName(accountOwner?.pii.birthCountry);
    const vatCountry = getIsoCountryName(accountOwner?.pii.vatCountry);

    const businessRegistrationCountry = getIsoCountryName(
      accountOwner?.pii.businessRegistrationCountry
    );

    const accountOwnerRows = {
      key: "account-owner",
      rows: [
        {
          label: "Account Owner ID",
          content: (
            <ActionSnippet
              contents={accountOwner.externalId}
              shouldCopy={true}
              trackingId={AccountDetailsRowTrackingId.AccountOwnerId}
            />
          ),
          key: "account-owner-id",
        },
        {
          label: "Date Modified",
          content: formatDateTime({
            date: accountOwner.dateModified,
          }),
          key: "date-modified",
        },
        {
          label: "Date Created",
          content: formatDateTime({
            date: accountOwner.dateCreated,
          }),
          key: "date-created",
        },
        {
          label: "Account Owner Type",
          content: accountOwner.accountOwnerType
            ? taxClassificationMap[accountOwner.accountOwnerType]
            : COSMIC_VALUE_PLACEHOLDER,
          key: "account-owner-type",
        },
        {
          label: "Prefers Physical Mail",
          content: isDefined(accountOwner.prefersPhysicalMail)
            ? formatBoolean(accountOwner.prefersPhysicalMail)
            : COSMIC_VALUE_PLACEHOLDER,
          key: "prefers-physical-mail",
        },
        {
          label: "Is Tax Exempt",
          content: isDefined(accountOwner.isTaxExempt)
            ? formatBoolean(accountOwner.isTaxExempt)
            : COSMIC_VALUE_PLACEHOLDER,
          key: "is-tax-exempt",
        },
        {
          label: "Has Fatca Filing Requirement",
          content: isDefined(accountOwner.hasFatcaFilingRequirement)
            ? formatBoolean(accountOwner.hasFatcaFilingRequirement)
            : COSMIC_VALUE_PLACEHOLDER,
          key: "has-fatca-filing-requirement",
        },
        {
          label: "Subject to Backup Withholding",
          content: isDefined(accountOwner.isSubjectToBackupWithholding)
            ? formatBoolean(accountOwner.isSubjectToBackupWithholding)
            : COSMIC_VALUE_PLACEHOLDER,
          key: "subject-to-backup-withholding",
        },
        {
          label: "Tax Documentation Status",
          content: accountOwner.validation.taxDocumentStatus
            ?.validationResult ? (
            <KycBadge
              taxStatus={
                accountOwner.validation.taxDocumentStatus?.validationResult
              }
            />
          ) : (
            COSMIC_VALUE_PLACEHOLDER
          ),
          key: "tax-documentation-status",
        },
      ],
    };

    const accountOwnerPiiRows = {
      key: "account-owner-pii",
      rows: [
        {
          label: "Name",
          content: accountOwner.pii.name,
          key: "name",
        },
        {
          label: "Email",
          content: accountOwner.pii.email,
          key: "email",
        },
        {
          label: "Date of Birth",
          content: accountOwner.pii.birthDate
            ? formatDateTime({
                date: accountOwner.pii.birthDate,
              })
            : COSMIC_VALUE_PLACEHOLDER,
          key: "birth-date",
        },
        {
          label: "Tax Country",
          content: taxCountry === "" ? <InvalidCountry /> : taxCountry,
          key: "tax-country",
        },
        {
          label: "Address",
          content: accountOwner.pii.address ? (
            <AccountAddress address={accountOwner.pii.address} />
          ) : (
            COSMIC_VALUE_PLACEHOLDER
          ),
          key: "address",
        },
        {
          label: "Birth City",
          content: accountOwner.pii.birthCity,
          key: "birth-city",
        },
        {
          label: "Birth Country",
          content: birthCountry === "" ? <InvalidCountry /> : birthCountry,
          key: "birth-country",
        },
        {
          label: "Business Registration Number",
          content: (
            <ActionSnippet
              contents={
                accountOwner.pii.businessRegistrationNumber ??
                accountOwner.pii.businessRegistrationNumberMasked
              }
              shouldMask={shouldUnmaskValue}
              maskContents={(value) =>
                maskData(value) ?? COSMIC_VALUE_PLACEHOLDER
              }
              trackingId={
                AccountDetailsRowTrackingId.BusinessRegistrationNumber
              }
            />
          ),
          key: "business-registration-number",
        },
        {
          label: "Business Registration Country",
          content:
            businessRegistrationCountry === "" ? (
              <InvalidCountry />
            ) : (
              businessRegistrationCountry
            ),
          key: "business-registration-country",
        },
        {
          label: "Financial Account ID",
          content: (
            <ActionSnippet
              contents={
                accountOwner.pii.financialAccountId ??
                accountOwner.pii.financialAccountIdMasked
              }
              shouldMask={shouldUnmaskValue}
              maskContents={(value) =>
                maskData(value) ?? COSMIC_VALUE_PLACEHOLDER
              }
              trackingId={AccountDetailsRowTrackingId.FinancialAccountId}
            />
          ),
          key: "financial-account-id",
        },
        {
          label: "Financial Account Holder Name",
          content: accountOwner.pii.financialAccountHolderName,
          key: "financial-account-holder-name",
        },
      ],
    };

    const tinValidationRows = {
      key: "tin-validation",
      rows: [
        {
          label: "TIN",
          content: (
            <ActionSnippet
              contents={
                accountOwner.pii.tin
                  ? formatTaxId(accountOwner.pii.tin, accountOwner.pii.tinType)
                  : accountOwner.pii.maskedTin
              }
              shouldMask={shouldUnmaskValue}
              maskContents={(value) =>
                maskTaxId(value, accountOwner.pii.tinType) ??
                COSMIC_VALUE_PLACEHOLDER
              }
              trackingId={AccountDetailsRowTrackingId.Tin}
            />
          ),
          key: "tin",
        },
        {
          label: "TIN Type",
          content: accountOwner.pii.tinType
            ? tinTypeMap[accountOwner.pii.tinType]
            : COSMIC_VALUE_PLACEHOLDER,
          key: "tin-type",
        },
        {
          label: "US TIN Status",
          content: accountOwner.validation.irsTinMatch?.validationResult ? (
            <TinBadge
              tinValidationStatus={
                accountOwner.validation.irsTinMatch.validationResult
              }
            />
          ) : (
            COSMIC_VALUE_PLACEHOLDER
          ),
          key: "us-tin-status",
        },
      ],
    };

    const vatValidationRows = {
      key: "vat-validation",
      rows: [
        {
          label: "VAT Number",
          content: (
            <ActionSnippet
              contents={accountOwner.pii.vatId ?? accountOwner.pii.vatIdMasked}
              shouldMask={shouldUnmaskValue}
              maskContents={(value) =>
                maskData(value) ?? COSMIC_VALUE_PLACEHOLDER
              }
              trackingId={AccountDetailsRowTrackingId.VatId}
            />
          ),
          key: "vat-number",
        },
        {
          label: "VAT Country",
          content: vatCountry === "" ? <InvalidCountry /> : vatCountry,
          key: "vat-country",
        },
        {
          label: "VAT Number Status",
          content: accountOwner.validation.vat?.validationResult ? (
            <VatBadge
              vatValidationStatus={accountOwner.validation.vat.validationResult}
            />
          ) : (
            COSMIC_VALUE_PLACEHOLDER
          ),
          key: "vat-number-status",
        },
      ],
    };

    const accountRows = {
      key: "account",
      rows: [
        {
          label: "Account ID",
          content: (
            <ActionSnippet
              contents={account.externalId}
              shouldCopy={true}
              trackingId={AccountDetailsRowTrackingId.AccountId}
            />
          ),
          key: "account-id",
        },
        {
          label: "Date Modified",
          content: formatDateTime({
            date: account.dateModified,
          }),
          key: "date-modified",
        },
        {
          label: "Date Created",
          content: formatDateTime({
            date: account.dateCreated,
          }),
          key: "date-created",
        },
        {
          label: "Filer ID",
          content: (
            <ActionSnippet
              contents={account.payerId}
              shouldCopy={true}
              trackingId={AccountDetailsRowTrackingId.FilerId}
            />
          ),
          key: "filer-id",
        },
        {
          label: "Account Type",
          content: account.accountType
            ? accountTypeMap[account.accountType]
            : COSMIC_VALUE_PLACEHOLDER,
          key: "account-type",
        },
        {
          label: "Establishment Date",
          content: account.establishmentDate
            ? formatDateTime({
                date: account.establishmentDate,
              })
            : COSMIC_VALUE_PLACEHOLDER,
          key: "establishment-date",
          helpIconProps: {
            tooltipProps: {
              content: RETIREMENT_TOOLTIP_COPY,
            },
            trackingId: AccountDetailsRowTrackingId.RetirementTooltipCopy,
          },
        },
        {
          label: "Fair Market Value",
          content: account.mostRecentFairMarketValue
            ? formatUsdValue(account.mostRecentFairMarketValue)
            : COSMIC_VALUE_PLACEHOLDER,
          key: "fair-market-value",
          helpIconProps: {
            tooltipProps: {
              content: RETIREMENT_TOOLTIP_COPY,
            },
            trackingId: AccountDetailsRowTrackingId.RetirementTooltipCopy,
          },
        },
        {
          label: "Disposition Method",
          content: account.dispositionMethod
            ? payerDispositionMethodLabelMap[account.dispositionMethod]
            : COSMIC_VALUE_PLACEHOLDER,
          key: "disposition-method",
        },
      ],
    };

    return {
      accountOwnerData: [
        accountOwnerRows,
        accountOwnerPiiRows,
        tinValidationRows,
        vatValidationRows,
      ],
      accountData: [accountRows],
    };
  }, [
    account,
    formatBoolean,
    formatDateTime,
    shouldUnmaskValue,
    formatUsdValue,
    accountOwner,
  ]);
};
