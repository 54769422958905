import {
  AccountOwner,
  AccountServiceTinType,
  AccountType,
  KycTaxDocumentType,
  TaxDocumentType,
  TaxDocumentationStatus,
} from "@taxbit-dashboard/rest";

export const RETIREMENT_TOOLTIP_COPY =
  "This information is only relevant for accounts subject to IRA reporting requirements.";

export type AccountOwnerInformation = { accountOwner: AccountOwner } & {
  taxClassification?: string;
  documentType?: KycTaxDocumentType;
  unmaskedTaxId?: string;
  taxDocumentType?: TaxDocumentType;
  kycTaxDocumentationSummary?: TaxDocumentationStatus;
  payerName?: string;
  fairMarketValue?: string;
};

export const accountTypeMap: Record<AccountType, string> = {
  US_IRA_TRADITIONAL: "Traditional",
  US_IRA_ROTH: "Roth",
  US_IRA_SIMPLE: "SIMPLE",
  US_IRA_SEP: "SEP",
};

export const tinTypeMap: Record<AccountServiceTinType, string> = {
  US_SSN: "SSN",
  US_EIN: "EIN",
  US_ATIN: "ATIN",
  US_ITIN: "ITIN",
  OTHER: "OTHER",
};
