import { GridGroup, useDashboardFeatureFlags } from "@taxbit-dashboard/commons";
import {
  Button,
  Card,
  ContentErrorEmptyState,
  ContentSpinner,
} from "@taxbit-private/cosmic";
import { useState } from "react";

import useFormDetailsRows from "./useFormDetailsRows";
import useGetFormDetails from "./useGetFormDetails";
import DocumentationDetailsModal from "../TaxDocumentationDetailsModal";

export enum FormDetailsTrackingId {
  FormDetailsSpinner = "form-details-spinner",
  ViewDetailsBtn = "form-details-view-details-btn",
}

const FormDetails: React.FC = () => {
  const { isLoading, formDetails, wForm, shouldUnmaskValue } =
    useGetFormDetails();

  const { showAccountDetailsReportingProfile } = useDashboardFeatureFlags();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const formDetailsRows = useFormDetailsRows({
    formDetails,
  });

  const cardTitle = `${formDetails ? formDetails.documentType : "Form"}`;

  return (
    <>
      <Card
        title={cardTitle}
        utilityElement={
          showAccountDetailsReportingProfile && (
            <Button
              variant="button-secondary"
              label="View details"
              trackingId={FormDetailsTrackingId.ViewDetailsBtn}
              isDisabled={!wForm}
              onClick={() => {
                setIsModalOpen(true);
              }}
            />
          )
        }
      >
        {isLoading ? (
          <ContentSpinner
            trackingId={FormDetailsTrackingId.FormDetailsSpinner}
          />
        ) : formDetailsRows ? (
          <GridGroup data={formDetailsRows} />
        ) : (
          <ContentErrorEmptyState entity="form" />
        )}
      </Card>
      {wForm && (
        <DocumentationDetailsModal
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          data={wForm}
          shouldUnmaskValue={shouldUnmaskValue}
        />
      )}
    </>
  );
};

export default FormDetails;
