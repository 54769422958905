import { UrlFilterParams } from "@taxbit-dashboard/commons";
import { useCallback } from "react";

import {
  OverviewUrlParams,
  useOverviewUrlParamsSchema,
} from "../../../api/overview/overviewApiTypes";

const useValidateOverviewUrlParams = () => {
  const overviewUrlParamsSchema = useOverviewUrlParamsSchema();

  return useCallback(
    (params: UrlFilterParams<OverviewUrlParams>) =>
      overviewUrlParamsSchema.parse(params),
    [overviewUrlParamsSchema]
  );
};

export default useValidateOverviewUrlParams;
