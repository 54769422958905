import { useDashboardFeatureFlags } from "@taxbit-dashboard/commons";
import { DashboardFormType } from "@taxbit-dashboard/rest";
import { FourDigitYear, fourDigitYearSchema } from "@taxbit-private/datetime";
import { useState, useMemo, useCallback } from "react";

import sortDashboardForms from "./sortDashboardForms";
import { calculateFormTypeToYearsMap } from "../../../utils/calculateFormTypeToYearsMap";

type SharedFormDropdownDataOptions = {
  /**
   * If `true`, filters dropdown data to only forms (and their respective years) that
   * are supported by ingesting data through file upload or API.
   */
  shouldUseFormItemsForms?: boolean;
};

/**
 * @deprecated This hook provides parsed data from the shared `irFormTypesByYear` feature flag. All
 * new form flagging should use individual feature flags and `useFormFeatureFlagData` instead.
 */
const useSharedFormDropdownData = ({
  shouldUseFormItemsForms = false,
}: SharedFormDropdownDataOptions = {}) => {
  const { irFormTypesByYear } = useDashboardFeatureFlags();
  const [formTypeToYearsMap] = useState(
    calculateFormTypeToYearsMap({
      irFormTypesByYear,
      shouldUseFormItemsForms,
    })
  );

  const years = useMemo(
    () =>
      [...new Set(Object.keys(irFormTypesByYear))]
        .map((f) => fourDigitYearSchema.parse(f))
        .sort((a, b) => b - a),
    [irFormTypesByYear]
  );

  const forms = useMemo(() => {
    return sortDashboardForms(
      Object.keys(formTypeToYearsMap) as DashboardFormType[]
    );
  }, [formTypeToYearsMap]);

  const hasYearForForm = useCallback(
    (form: DashboardFormType, year: FourDigitYear) =>
      !!formTypeToYearsMap[form]?.includes(year),
    [formTypeToYearsMap]
  );

  return {
    forms,
    formTypeToYearsMap,
    irFormTypesByYear,
    years,
    hasYearForForm,
  };
};

export default useSharedFormDropdownData;
