import {
  renderExcludedSpan,
  renderExcludedSpanDefaultToCosmicPlaceholder,
} from "@taxbit-dashboard/commons";
import { useCosmicLocalizationContext } from "@taxbit-private/cosmic-localization";
import { byIso } from "country-code-lookup";
import { useMemo } from "react";

import { VatInformation } from "./useGetVatInformation";
import MaskedData from "../../MaskedData";
import VatBadge from "../../VatBadge";

const useVatInformationRows = ({
  vatInformation,
}: {
  vatInformation?: VatInformation;
}) => {
  const { formatDateTime } = useCosmicLocalizationContext();

  return useMemo(() => {
    if (!vatInformation) {
      return undefined;
    }

    const countryName =
      vatInformation.vatinCountry &&
      byIso(vatInformation.vatinCountry)?.country;

    return [
      [
        {
          label: "VAT Number",
          content:
            vatInformation.vatStatus === "NOT_REQUIRED" ? (
              renderExcludedSpan("Not Issued")
            ) : (
              <MaskedData
                data={vatInformation.vatin}
                unmaskedData={vatInformation.unmaskedVatin}
                dataLabel="VAT"
              />
            ),
        },
        {
          label: "Country",
          content: renderExcludedSpanDefaultToCosmicPlaceholder(countryName),
        },
        ...(vatInformation.vatStatus === "NON_EU"
          ? []
          : [
              {
                label: "VAT Number Status",
                content: (
                  <VatBadge vatValidationStatus={vatInformation.vatStatus} />
                ),
              },
              {
                label: "Last Verified with VIES",
                content: formatDateTime({
                  date: vatInformation.vatValidationDate,
                }),
              },
            ]),
      ],
    ];
  }, [formatDateTime, vatInformation]);
};

export default useVatInformationRows;
