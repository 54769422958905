import {
  irFormTypeToDisplayNameMap,
  useDashboardFeatureFlags,
} from "@taxbit-dashboard/commons";
import { FormCountByType } from "@taxbit-dashboard/rest";
import {
  Card,
  ContentEntityEmptyState,
  ContentErrorEmptyState,
  ContentSpinner,
  Divider,
  Flex,
} from "@taxbit-private/cosmic";
import {
  BarChart,
  CosmicChartsTooltipContent,
  hasChartData,
} from "@taxbit-private/cosmic-charts";
import { useCosmicLocalizationContext } from "@taxbit-private/cosmic-localization";
import { useCallback, useMemo } from "react";

import { useOverviewDataContext } from "../context/useOverviewData";
import FixedWidthNumericalRowGroup from "../shared/FixedWidthNumericalRowGroup";
import FormsYearPicker from "../shared/FormsYearPicker";

const FormDataByFormTypeChart = () => {
  const {
    formItemsQuery: {
      isError,
      isFetching,
      data: { filteredData, filteredTotal } = {},
    },
  } = useOverviewDataContext();
  const { hasAccountFormItemsAccess } = useDashboardFeatureFlags();

  const { formatWholeQuantity } = useCosmicLocalizationContext();

  const numericalRows = useMemo(() => {
    if (filteredData) {
      const headerRow = {
        key: "header",
        label: "Form Type",
        value: "Total",
        variant: "bold",
        hasDivider: true,
      } as const;

      const formRows = filteredData.map(
        ({ documentType, totalFormItemsCreated }) => ({
          key: documentType,
          label: irFormTypeToDisplayNameMap[documentType],
          value: totalFormItemsCreated,
        })
      );

      const totalRow = {
        key: "total",
        label: "Total",
        value: filteredTotal,
        variant: "bold",
      } as const;

      return [headerRow, ...formRows, totalRow];
    } else {
      return [];
    }
  }, [filteredData, filteredTotal]);

  const getBarTooltipContent = useCallback(
    ({ totalFormItemsCreated, documentType }: FormCountByType) => (
      <CosmicChartsTooltipContent
        items={[
          {
            label: irFormTypeToDisplayNameMap[documentType],
            value: formatWholeQuantity(totalFormItemsCreated),
          },
        ]}
      />
    ),
    [formatWholeQuantity]
  );

  if (!hasAccountFormItemsAccess) {
    return undefined;
  }

  return (
    <Card
      title="Form Data Records by Form Type"
      utilityElement={<FormsYearPicker paramKey="forms" />}
    >
      {isFetching ? (
        <ContentSpinner />
      ) : isError ? (
        <ContentErrorEmptyState />
      ) : hasChartData(filteredData) ? (
        <Flex padding="contentPadding" alignItems="center">
          <FixedWidthNumericalRowGroup rows={numericalRows} />
          <Flex padding="none l" alignSelf="stretch">
            <Divider direction="vertical" />
          </Flex>
          <BarChart
            bars={filteredData}
            getBarValue={({ totalFormItemsCreated }) => totalFormItemsCreated}
            getBarLabel={({ documentType }) =>
              irFormTypeToDisplayNameMap[documentType]
            }
            getBarTooltipContent={getBarTooltipContent}
          />
        </Flex>
      ) : (
        <ContentEntityEmptyState entity="form data" />
      )}
    </Card>
  );
};

export default FormDataByFormTypeChart;
