import {
  caProvinceCodes,
  usStateCodes,
  countryCodes,
} from "@taxbit-private/irw-address";
import {
  exemptFatcaCodes,
  exemptPayeeCodes,
  usTaxIdTypes,
  w8BenETaxClassificationEnum,
  w9TaxClassificationEnum,
} from "@taxbit-private/irw-tax-documentation";
import { uuidSchema } from "@taxbit-private/uuids";
import { z } from "zod";

export enum KycTaxDocumentType {
  W8Ben = "W-8BEN",
  W9 = "W-9",
  W8BenE = "W-8BEN-E",
  Dps = "DPS",
}

export enum KycTaxDocumentStatus {
  Valid = "VALID",
  Invalid = "INVALID",
  Undocumented = "UNDOCUMENTED",
}

export enum KycTinVerificationStatus {
  Pending = "PENDING",
  InvalidData = "INVALID_DATA",
  TinMismatch = "TIN_MISMATCH",
  TinNotIssued = "TIN_NOT_ISSUED",
  TinTypeMismatch = "TIN_TYPE_MISMATCH",
  TinMismatchForeignIndicia = "TIN_MISMATCH_FOREIGN_INDICIA",
  ForeignIndicia = "FOREIGN_INDICIA",
  Valid = "VALID",
  CountryCodeMismatch = "COUNTRY_CODE_MISMATCH",
}

export enum KycTaxDocumentationIssueType {
  ExpiredForm = "EXPIRED_FORM",
  UsMailingAddress = "US_MAILING_ADDRESS",
  UsPermanentAddress = "US_PERMANENT_ADDRESS",
  CareOf = "CARE_OF_PERMANENT_ADDRESS",
  PoBoxPermanentAddress = "PO_BOX_PERMANENT_ADDRESS",
}

export const kycTaxDocumentationIssueTypeSchema = z.nativeEnum(
  KycTaxDocumentationIssueType
);

export enum KycTaxDocumentationIssueStatus {
  Open = "OPEN",
  Resolved = "RESOLVED",
}

export enum KycCuringStatus {
  NotApplicable = "NOT_APPLICABLE",
  Open = "OPEN",
  Resolved = "RESOLVED",
}

export enum KycResubmissionStatus {
  NotApplicable = "NOT_APPLICABLE",
  Open = "OPEN",
  Resolved = "RESOLVED",
}

export enum KycTaxDocumentationSubmissionStatus {
  Submitted = "SUBMITTED",
  NotSubmitted = "NOT_SUBMITTED",
}

export enum KycTaxDocumentationDac7Status {
  Complete = "COMPLETE",
  Incomplete = "INCOMPLETE",
}

export enum KycTaxdocumentationVatStatus {
  Pending = "PENDING",
  Valid = "VALID",
  Invalid = "INVALID",
  InsufficientData = "INSUFFICIENT_DATA",
  NotRequired = "NOT_REQUIRED",
  NonEu = "NON_EU",
}

export enum EuCountryCodes {
  Austria = "AT",
  Belgium = "BE",
  Bulgaria = "BG",
  Croatia = "HR",
  RepublicOfCyprus = "CY",
  CzechRepublic = "CZ",
  Denmark = "DK",
  Estonia = "EE",
  Finland = "FI",
  France = "FR",
  Germany = "DE",
  Greece = "GR",
  Hungary = "HU",
  Ireland = "IE",
  Italy = "IT",
  Latvia = "LV",
  Lithuania = "LT",
  Luxembourg = "LU",
  Malta = "MT",
  Netherlands = "NL",
  Poland = "PL",
  Portugal = "PT",
  Romania = "RO",
  Slovakia = "SK",
  Slovenia = "SI",
  Spain = "ES",
  Sweden = "SE",
}

export const unrefinedAddressSchema = z
  .object({
    firstLine: z.string().optional(),
    secondLine: z.string().optional(),
    city: z.string().optional(),
    stateOrProvince: z
      .union([z.enum(caProvinceCodes), z.enum(usStateCodes), z.string()])
      .optional(),
    postalCode: z.string().optional(),
    country: z.enum(countryCodes).optional(),
  })
  .describe("unrefinedAddressSchema");

export const unrefinedW9TaxDocumentationSchema = z.object({
  name: z.string(),
  dbaName: z.string().optional(),
  otherTaxClassification: z.string().optional(),
  exemptPayeeCode: z.enum(exemptPayeeCodes).optional(),
  exemptFatcaCode: z.enum(exemptFatcaCodes).optional(),
  taxClassification: z.enum(w9TaxClassificationEnum),
  address: unrefinedAddressSchema,
  tin: z.string(),
  tinType: z.enum(usTaxIdTypes),
  documentType: z.literal(KycTaxDocumentType.W9),
  hasCertified: z.boolean(),
  isNotSubjectBackupWithholding: z.boolean(),
  signature: z.string(),
  signatureTimestamp: z.string(),
});

export const unrefinedW8BenTaxDocumentationSchema = z.object({
  name: z.string(),
  country: z.string(),
  taxClassification: z.literal("INDIVIDUAL"),
  permanentAddress: unrefinedAddressSchema,
  mailingAddress: unrefinedAddressSchema.optional(),
  tin: z.string().optional(),
  ftin: z.string().optional(),
  dateOfBirth: z.string(),
  referenceNumbers: z.string().optional(),
  ftinNotLegallyRequired: z.boolean(),
  documentType: z.literal(KycTaxDocumentType.W8Ben),
  hasCertified: z.boolean(),
  signature: z.string(),
  signatureTimestamp: z.string(),
});

export const unrefinedW8BenETaxDocumentationSchema = z.object({
  name: z.string(),
  country: z.string(),
  taxClassification: z.enum(w8BenETaxClassificationEnum),
  permanentAddress: unrefinedAddressSchema,
  mailingAddress: unrefinedAddressSchema.optional(),
  tin: z.string().optional(),
  ftin: z.string().optional(),
  referenceNumbers: z.string().optional(),
  ftinNotLegallyRequired: z.boolean(),
  documentType: z.literal(KycTaxDocumentType.W8BenE),
  hasCertified: z.boolean(),
  signature: z.string(),
  signatureTimestamp: z.string(),
});

const taxResidenceSchema = z.object({
  country: z.string(),
  tin: z.string().optional(),
  tinNotRequired: z.boolean().optional(),
});

export const dpsSchema = z.object({
  name: z.string(),
  address: unrefinedAddressSchema.optional(),
  taxResidences: taxResidenceSchema.array().optional(),
  vatin: z.string().optional(),
  vatinCountry: z.string().optional(),
  vatinNotRequired: z.boolean().optional(),
  financialAccountIdentifier: z.string().optional(),
  financialAccountName: z.string().optional(),
  isConfirmedCorrectComplete: z.boolean(),
  documentType: z.literal(KycTaxDocumentType.Dps),
  dateOfBirth: z.string().optional(),
  cityOfBirth: z.string().optional(),
  countryOfBirth: z.string().optional(),
  businessRegistrationNumber: z.string().optional(),
  businessRegistrationCountry: z.string().optional(),
  isIndividual: z.boolean(),
});

export type KycTaxDocumentationAddress = z.infer<typeof unrefinedAddressSchema>;

const w9TaxDocumentationWithUuidSchema =
  unrefinedW9TaxDocumentationSchema.extend({
    id: uuidSchema,
    createdAt: z.string(),
  });

export const TYPE_OF_INCOME = [
  "ROYALTIES_OTHER",
  "SERVICES",
  "BUSINESS_PROFITS",
] as const;

export const LIMITATION_ON_BENEFITS = [
  "GOVERNMENT",
  "TAX_EXEMPT_PENSION",
  "OTHER_TAX_EXEMPT_ORGANIZATION",
  "PUBLICLY_TRADED_CORPORATION",
  "SUBSIDIARY",
  "COMPANY_MEETS_EROSION_TEST",
  "COMPANY_MEETS_DERIVATIVE_TEST",
  "COMPANY_MEETS_BUSINESS_TEST",
  "FAVORABLE_DETERMINATION",
  "OTHER_ARTICLE_PARAGRAPH",
] as const;

const typeOfIncomeSchema = z.enum(TYPE_OF_INCOME);

export type TypeOfIncome = z.infer<typeof typeOfIncomeSchema>;

const limitationOnBenefitsSchema = z.enum(LIMITATION_ON_BENEFITS);

export type LimitationOnBenefits = z.infer<typeof limitationOnBenefitsSchema>;

const w8BenTaxDocumentationWithUuidSchema =
  unrefinedW8BenTaxDocumentationSchema.extend({
    id: uuidSchema,
    createdAt: z.string(),
    treatyClaimIsEligible: z.boolean().optional(),
    treatyClaimCountry: z.string().optional(),
    treatyClaimICertifyResident: z.literal(true).optional(),
    treatyClaimICertifyRequirements: z.literal(true).optional(),
    treatyClaimTypeOfIncome: typeOfIncomeSchema.optional(),
    treatyClaimRateOfWithholding: z.string().optional(),
    treatyClaimArticleParagraph: z.string().optional(),
    treatyClaimHasAdditionalConditions: z.boolean().optional(),
  });

const w8BenETaxDocumentationWithUuidSchema =
  unrefinedW8BenETaxDocumentationSchema.extend({
    id: uuidSchema,
    createdAt: z.string(),
    treatyClaimIsEligible: z.boolean().optional(),
    treatyClaimCountry: z.string().optional(),
    treatyClaimICertifyResident: z.literal(true).optional(),
    treatyClaimICertifyRequirements: z.literal(true).optional(),
    treatyClaimLimitationOnBenefits: limitationOnBenefitsSchema.optional(),
    treatyClaimLimitationOnBenefitsOtherArticleParagraph: z.string().optional(),
    treatyClaimTypeOfIncome: typeOfIncomeSchema.optional(),
    treatyClaimRateOfWithholding: z.string().optional(),
    treatyClaimArticleParagraph: z.string().optional(),
    treatyClaimHasAdditionalConditions: z.boolean().optional(),
  });

const dpsWithUuidSchema = dpsSchema.extend({
  id: uuidSchema,
  createdAt: z.string(),
});

export const taxDocumentationWithUuidSchema = z.discriminatedUnion(
  "documentType",
  [
    w9TaxDocumentationWithUuidSchema,
    w8BenTaxDocumentationWithUuidSchema,
    w8BenETaxDocumentationWithUuidSchema,
    dpsWithUuidSchema,
  ]
);

export type W9TaxDocumentation = z.infer<
  typeof w9TaxDocumentationWithUuidSchema
>;

export type W8BenTaxDocumentation = z.infer<
  typeof w8BenTaxDocumentationWithUuidSchema
>;

export type W8BenETaxDocumentation = z.infer<
  typeof w8BenETaxDocumentationWithUuidSchema
>;

export type KycTaxDocumentation = z.infer<
  typeof taxDocumentationWithUuidSchema
>;
