import { z } from "zod";

export const FORM_GENERATION_FORM_ID = "form-generation-form";

export const formGenerationModalFormSchema = z.object({
  shouldSkipCompareData: z.boolean().optional(),
  population: z.enum(["all", "filtered"]).optional(),
});
export type FormGenerationModalForm = z.infer<
  typeof formGenerationModalFormSchema
>;

export const formGenerationModalFormDefaultValues: FormGenerationModalForm = {
  shouldSkipCompareData: false,
  population: "filtered",
};
