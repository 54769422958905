import { fourDigitYearSchema } from "@taxbit-private/datetime";
import { uuidSchema } from "@taxbit-private/uuids";
import { z } from "zod";

import { dashboardFormTypeSchema } from "./shared/dashboardFormType";
import getPublicApiSuccessSchema from "../getPublicApiSuccessSchema";

const formCountSchema = z.object({
  original: z.string(),
  correction: z.string(),
  void: z.string().optional(),
});

export const formMetadataSchema = z.object({
  organizationId: uuidSchema,
  documentType: dashboardFormTypeSchema,
  documentDate: z.number().transform((year) => fourDigitYearSchema.parse(year)),
  filed: formCountSchema
    .extend({
      lastEfiledDate: z.string().optional(), // ISO date string
    })
    .optional(),
  // Delivered means the form has been generated and released to the clients
  // Customers will see only Delivered or Filed documents
  // However, from customer perspective, it's more clear to use Generated term because they don't know about the internal process
  delivered: formCountSchema.extend({
    lastDeliveredDate: z.string().optional(), // ISO date string
  }),
  // Generated means the form has been generated but not released to the clients
  // Customers will never see Generated documents, it's used internally
  generated: formCountSchema.extend({
    lastGeneratedDate: z.string().optional(), // ISO date string
  }),
});
export type FormMetadata = z.infer<typeof formMetadataSchema>;

export const getFormMetadataSchema = getPublicApiSuccessSchema(
  formMetadataSchema.array()
);
