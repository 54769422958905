import { ActionSnippet, Anchor } from "@taxbit-private/cosmic";
import { COSMIC_VALUE_PLACEHOLDER } from "@taxbit-private/cosmic-localization";
import { useMemo } from "react";

import { useGetOrganizationDevSettings } from "../../../api/devSettingsApi";

const HELP_CENTER_LINK =
  "https://taxbitsupport.zendesk.com/hc/en-us/categories/25452765315085-Enterprise-Tax";

export enum DeliveryDeveloperSettingsTrackingId {
  FileNameFormatHelpIcon = "file-name-format-help-icon",
  FileFormatHelpIcon = "file-format-help-icon",
  EncryptionPublicKeyMaskedData = "encryption-public-key-masked-data",
  HelpPageButton = "help-page-button",
  DeliveryConfigurationSettingsHelpIcon = "delivery-configuration-settings-help-icon",
}

const useDeliveryDeveloperSettingsRows = () => {
  const { data: devSettings } = useGetOrganizationDevSettings();
  return useMemo(() => {
    const deliveryConfigurationSettingsSections = [
      {
        key: "s3Settings",
        title: "S3 Settings",
        rows: [
          {
            label: "Delivery To S3",
            key: "deliveryToS3",
            content: devSettings?.deliveryToS3 ? "On" : "Off",
          },
          {
            label: "Folder Path",
            key: "folderPath",
            content: devSettings?.s3FolderPath,
          },
          {
            label: "File Name Format",
            key: "fileNameFormat",
            content: devSettings?.s3FileNameFormat,
            helpIconProps: {
              tooltipProps: {
                content: (
                  <div>
                    Go to our{" "}
                    <Anchor
                      trackingId={
                        DeliveryDeveloperSettingsTrackingId.HelpPageButton
                      }
                      label="Help Page"
                      href={HELP_CENTER_LINK}
                      variant="anchor-primary-inline"
                    />{" "}
                    for more information on file name formats.
                  </div>
                ),
              },
              trackingId:
                DeliveryDeveloperSettingsTrackingId.FileNameFormatHelpIcon,
            },
          },
        ],
      },
      {
        key: "encryptionSettings",
        title: "Encryption Settings",
        rows: [
          {
            label: "Encryption Status",
            key: "encryptionStatus",
            content: devSettings?.deliveryToS3
              ? devSettings?.s3DropzoneBucketEncryptionStatus
                ? "On"
                : "Off"
              : COSMIC_VALUE_PLACEHOLDER,
          },
          {
            label: "File Format",
            key: "fileFormat",
            content: devSettings?.s3FileFormat,
            helpIconProps: {
              tooltipProps: {
                content: (
                  <div>
                    Go to our{" "}
                    <Anchor
                      trackingId={
                        DeliveryDeveloperSettingsTrackingId.HelpPageButton
                      }
                      label="Help Page"
                      href={HELP_CENTER_LINK}
                      variant="anchor-primary-inline"
                    />{" "}
                    for more information on file name formats.
                  </div>
                ),
              },
              trackingId:
                DeliveryDeveloperSettingsTrackingId.FileFormatHelpIcon,
            },
          },
          {
            label: "Encryption Public Key",
            key: "encryptionPublicKey",
            content: (
              <ActionSnippet
                contents={devSettings?.s3DropzoneBucketEncryptionKey}
                trackingId={
                  DeliveryDeveloperSettingsTrackingId.EncryptionPublicKeyMaskedData
                }
                shouldMask={true}
                shouldCopy={true}
              />
            ),
          },
        ],
      },
    ];

    return deliveryConfigurationSettingsSections;
  }, [devSettings]);
};

export default useDeliveryDeveloperSettingsRows;
