import { isDefined, useDashboardFeatureFlags } from "@taxbit-dashboard/commons";
import { EuCountryCodes, KycTaxDocumentType } from "@taxbit-dashboard/rest";
import { useMemo } from "react";

import { Dac7Details } from "../../../../../api/kyc-tax-documentation/kycTaxDocumentationApiTypes";
import { useAccountOwnerTaxDocumentContext } from "../../../../../hooks/useGetAccountOwnerTaxDocumentData";

const useGetDac7InterviewDetails = () => {
  const {
    isLoading,
    taxDocumentations,
    taxDocumentationStatus,
    shouldUnmaskValue,
  } = useAccountOwnerTaxDocumentContext();

  const { shouldUseNewDpsStatus } = useDashboardFeatureFlags();
  const dps = taxDocumentations?.find(
    (doc) => doc.documentType === KycTaxDocumentType.Dps
  );

  const dac7Details: Dac7Details | undefined = useMemo(() => {
    if (!dps || dps.documentType !== KycTaxDocumentType.Dps) {
      return undefined;
    }

    const isEuResident = !!dps.taxResidences?.some(({ country }) =>
      (Object.values(EuCountryCodes) as string[]).includes(country)
    );

    if (
      shouldUseNewDpsStatus &&
      taxDocumentationStatus?.dpsQuestionnaire?.dataCollectionStatus
    ) {
      return {
        interviewStatus:
          taxDocumentationStatus.dpsQuestionnaire.dataCollectionStatus,
        name: dps.name,
        isIndividual: isDefined(dps.dateOfBirth),
        isEuResident,
        submissionDate: dps.createdAt,
        expirationDate: taxDocumentationStatus.dpsQuestionnaire?.expirationDate,
      };
    }

    if (
      !shouldUseNewDpsStatus &&
      taxDocumentationStatus?.dac7Interview?.dataCollectionStatus
    ) {
      return {
        interviewStatus:
          taxDocumentationStatus.dac7Interview.dataCollectionStatus,
        name: dps.name,
        isIndividual: isDefined(dps.dateOfBirth),
        isEuResident,
        submissionDate: dps.createdAt,
        expirationDate: taxDocumentationStatus.dac7Interview?.expirationDate,
      };
    }

    return undefined;
  }, [taxDocumentationStatus, shouldUseNewDpsStatus, dps]);

  return {
    isLoading,
    dac7Details,
    dps,
    shouldUnmaskValue,
  };
};

export default useGetDac7InterviewDetails;
