import { isTestEnv } from "@taxbit-dashboard/commons";
import { CosmicNumberInput } from "@taxbit-private/cosmic-localization";
import { z } from "zod";

import accountExternalIdsFilterToAccountExternalIds from "../../../utils/accountExternalIdsFilterToAccountExternalIds";

export const getAccountExternalIdsFilterMaxIdsLength = () =>
  // We limit the number of account IDs to 3 in test environment to avoid performance issues
  // Typing in 10k+ account IDs in the filter will be slow
  isTestEnv() ? 3 : 10_000;

export const getAccountExternalIdsFilterMaxIdsCharLength = () =>
  // We limit the number of account IDs to 3 in test environment to avoid performance issues
  // Typing in 500k chars in the filter will be slow
  // Per ticket, limiting total chars to 500,000
  // https://taxbit.atlassian.net/browse/TAX-41578
  isTestEnv() ? 3 : 500_000;

export const accountExternalIdsFilterSchema = z
  .string()
  .transform((val) => val.trim());

export const refineAccountExternalIdsFilterSchema = ({
  accountExternalIdsFilter,
  formatValue,
}: {
  accountExternalIdsFilter: string;
  formatValue: (value: CosmicNumberInput) => string;
}): z.IssueData[] => {
  const errors: z.IssueData[] = [];
  const accountExternalIdsArray = accountExternalIdsFilterToAccountExternalIds(
    accountExternalIdsFilter
  );
  const maxAccountExternalIdsLength = getAccountExternalIdsFilterMaxIdsLength();
  const maxAccountExternalIdsCharLength =
    getAccountExternalIdsFilterMaxIdsCharLength();

  if (accountExternalIdsArray.length > maxAccountExternalIdsLength) {
    errors.push({
      code: z.ZodIssueCode.custom,
      message: `Count of Account IDs cannot exceed ${formatValue(maxAccountExternalIdsLength)}. Please shorten your input and try again.`,
      path: ["accountExternalIdsFilter"],
    });
  }

  const totalCharLenght = accountExternalIdsArray.reduce(
    (acc, id) => acc + id.length,
    0
  );

  if (totalCharLenght > maxAccountExternalIdsCharLength) {
    errors.push({
      code: z.ZodIssueCode.custom,
      message: `Characters cannot exceed the ${formatValue(maxAccountExternalIdsCharLength)} character limit. Please shorten your input and try again.`,
      path: ["accountExternalIdsFilter"],
    });
  }

  return errors;
};
