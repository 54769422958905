import {
  DashboardAppRoutePath,
  useOrganizationPrefixedNavigation,
} from "@taxbit-dashboard/commons";
import { Flex, H3, Tabs, Divider, Button } from "@taxbit-private/cosmic";
import styled from "styled-components";

import NotificationsListContents from "../NotificationsListContents";
import useNotificationsContext, {
  NotificationsContext,
} from "../useNotificationsContext";

export enum NotificationsCenterContentsTrackingId {
  Spinner = "notifications-list-spinner",
  MarkAll = "notifications-mark-all-button",
  ViewAll = "notifications-view-all-button",
}

const NotificationsCenterContents: React.FC = () => {
  const {
    shouldShowUnreadNotifications,
    isMarkingAllAsRead,
    markAllNotificationsAsRead,
    tabConfigs,
    handleClose,
  } = useNotificationsContext(NotificationsContext.Center);

  const { prefixedNavigate } = useOrganizationPrefixedNavigation();

  return (
    <Flex direction="column">
      <Flex
        padding="l l none l"
        alignItems="center"
        justifyContent="space-between"
      >
        <H3 as="div">Notifications</H3>
        <Button
          variant="anchor-primary"
          label="Mark all as read"
          iconName="check"
          trackingId={NotificationsCenterContentsTrackingId.MarkAll}
          onClick={() => {
            markAllNotificationsAsRead();
            handleClose();
          }}
          isDisabled={!shouldShowUnreadNotifications || isMarkingAllAsRead}
        />
      </Flex>
      <Flex padding="none l">
        <Tabs spacing="external" items={tabConfigs} />
      </Flex>
      <Divider />
      <ItemsWrapper>
        <NotificationsListContents context={NotificationsContext.Center} />
      </ItemsWrapper>
      <Divider />
      <Flex alignItems="center" justifyContent="center" padding="l">
        <Button
          trackingId={NotificationsCenterContentsTrackingId.ViewAll}
          label="View all notifications"
          variant="anchor-primary"
          onClick={() => {
            prefixedNavigate({ to: DashboardAppRoutePath.Notifications });
            handleClose();
          }}
        />
      </Flex>
    </Flex>
  );
};

export default NotificationsCenterContents;

const ItemsWrapper = styled.div(
  ({ theme }) => `
  width: ${theme.measure.xxl};
  overflow-y: auto;
`
);
