import {
  KycCuringStatus,
  KycTaxDocumentationIssueStatus,
  KycTaxDocumentStatus,
  KycResubmissionStatus,
  TaxDocumentStatusValidationResult,
} from "@taxbit-dashboard/rest";
import { Badge } from "@taxbit-private/cosmic";

type KycBadgeProps = {
  taxStatus?:
    | KycTaxDocumentationIssueStatus
    | KycCuringStatus
    | KycTaxDocumentStatus
    | KycResubmissionStatus
    | TaxDocumentStatusValidationResult;
};

const KycBadge: React.FC<KycBadgeProps> = ({ taxStatus }) => {
  switch (taxStatus) {
    case KycTaxDocumentStatus.Valid: {
      return <Badge label="Valid" variant="success" />;
    }
    case KycTaxDocumentStatus.Invalid: {
      return <Badge label="Invalid" variant="danger" />;
    }
    case KycTaxDocumentStatus.Undocumented: {
      return <Badge label="Undocumented" variant="secondary" />;
    }
    case KycTaxDocumentationIssueStatus.Open: {
      return <Badge label="Open" variant="danger" />;
    }
    case KycTaxDocumentationIssueStatus.Resolved: {
      return <Badge label="Resolved" variant="success" />;
    }
    default: {
      return <Badge label="Not Applicable" variant="secondary" />;
    }
  }
};

export default KycBadge;
