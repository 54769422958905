import { z } from "zod";

export enum DashboardFormType {
  Irs1099B = "IRS_1099_B",
  Irs1099Misc = "IRS_1099_MISC",
  Irs1099K = "IRS_1099_K",
  Irs1099R = "IRS_1099_R",
  Irs5498 = "IRS_5498",
  RmdStatement = "RMD_STATEMENT",
  Irs1099Nec = "IRS_1099_NEC",
  Irs1099Int = "IRS_1099_INT",
  Irs1099Div = "IRS_1099_DIV",
  Irs1042S = "IRS_1042_S",
  TransactionSummary = "TRANSACTION_SUMMARY",
  TransactionSummaryPdf = "TRANSACTION_SUMMARY_PDF",
  GainLossSummary = "GAIN_LOSS_SUMMARY",
  UkGainLossSummary = "UK_GAIN_LOSS_SUMMARY",
  GainLossPdf = "GAIN_LOSS_PDF",
  Cesop = "CESOP",
  Dac7 = "DAC7",
}

export const dashboardFormTypeSchema = z.nativeEnum(DashboardFormType);
