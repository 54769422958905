import { FourDigitYear } from "@taxbit-private/datetime";
import { Uuid } from "@taxbit-private/uuids";

import getUnexpectedResponseError from "./getUnexpectedResponseError";
import makeRequest, { HttpMethod, doFetch } from "./makeRequest";
import pathBuilders from "./taxBitPathBuilders";
import {
  CreateAccountOwnerCuringArchiveRequest,
  accountOwnerCuringArchiveResponseSchema,
  createAccountOwnerCuringArchiveRequestSchema,
} from "./types/api/accountOwnerCuringArchivesRestApiTypes";
import {
  CreateAccountOwnerCuringRequest,
  accountOwnerCuringResponseSchema,
} from "./types/api/accountOwnerCuringsRestApiTypes";
import { accountOwnerDetailsResponseSchema } from "./types/api/accountOwnerDetailsRestApiTypes";
import { getAccountReportingProfilesResponseSchema } from "./types/api/accountReportingProfilesRestApiTypes";
import {
  getAccountResponseSchema,
  UserId,
} from "./types/api/accountRestApiTypes";
import {
  getAccountsPostExportResponseSchema,
  GetExportAccountsCsvParams,
  getExportAccountsCsvResponseSchema,
  GetInitiateAccountsCsvParams,
} from "./types/api/accountsExportsApiTypes";
import {
  GetAccountsParams,
  getAccountsResponseSchema,
} from "./types/api/accountsRestApiTypes";
import { getAccountV2ResponseSchema } from "./types/api/accountV2RestApiTypes";
import {
  AssetId,
  AssetType,
  getAssetSchema,
  getInventorySummaryResponseSchema,
} from "./types/api/assetApiTypes";
import {
  getOrganizationMembersResponseSchema,
  inviteUserToOrganizationResponseSchema,
  getRolesResponseSchema,
  removeUserSchema,
  RemoveUser,
  getCurrentCompanyUserResponseSchema,
  getCompanyUsersResponseSchema,
  updateOrganizationMemberRolesSchema,
  UpdateOrganizationMemberRoles,
  getCurrentCompanyUserOrganizationsResponseSchema,
  getInvitationsResponseSchema,
  InviteUser,
  inviteUserSchema,
  UpdateCurrentCompanyUserName,
  updateCurrentCompanyUserSchema,
  updateCurrentCompanyUserResponseSchema,
  ResetMfaForCompanyUser,
} from "./types/api/ciamApiTypes";
import { getDownloadsResponseSchema } from "./types/api/downloadsApiTypes";
import {
  CreateEligibilityBatchOverrides,
  CreateEligibilityExportBodyDto,
  CreateEligibilityQaPackageRequest,
  EligibilityFindAccountsRequest,
  EligibilityRecalcRequest,
  GetEligibilityDataParams,
  createEligibilityBatchOverridesResponseSchema,
  createEligibilityBatchOverridesSchema,
  createEligibilityExportBodyDtoSchema,
  createEligibilityExportResponseSchema,
  createEligibilityQaPackageRequestSchema,
  eligibilityFindAccountsRequestSchema,
  eligibilityRecalcRequestSchema,
  getEligibilityAlertsResponseSchema,
  getEligibilityCountsResponseSchema,
  getEligibilityDataResponseSchema,
  getEligibilityExportResponseSchema,
  getEligibilitySyncTimestampsResponseSchema,
} from "./types/api/eligibility/eligibilityRestApiTypes";
import {
  CreateMultipartUploadRequest,
  UpdateFileRequest,
  UploadFileRequestParams,
  getFilesResponseSchema,
  createMultipartUploadRequestSchema,
  createMultipartUploadResponseSchema,
  updateFileRequestSchema,
  updateFileResponseSchema,
  abortMultipartUploadResponseSchema,
  EndMultipartUploadRequest,
  endMultipartUploadRequestSchema,
  endMultipartUploadResponseSchema,
  ConfirmIngestionRequest,
  confirmIngestionRequestSchema,
  confirmIngestionResponseSchema,
  fileErrorReportResponseSchema,
  GetFilesParams,
  deleteFileResponseSchema,
  getFileUrlResponseSchema,
} from "./types/api/filesRestApiTypes";
import {
  GetFormItemsParams,
  getFormItemsResponseSchema,
} from "./types/api/form-items/formItemsRestApiTypes";
import { getFormMetadataSchema } from "./types/api/formMetadataApiTypes";
import {
  getGainsSummaryResponseSchema,
  getGainsSchema,
} from "./types/api/gainsApiTypes";
import {
  searchHydratedAccountsResponse,
  SearchHydratedAccountsParams,
  searchHydratedAccountsParamSchema,
  ExportHydratedAccountsParams,
  exportHydratedAccountsParamsSchema,
} from "./types/api/hydratedAccountsRestApiTypes";
import {
  CreateIrFormsExportRequest,
  DownloadIrFormsRequest,
  GenerateTaxFormsRequest,
  GetIrFormsParams,
  RescindIrFormsRequest,
  SearchIrFormsParams,
  createIrFormsExportRequestSchema,
  createIrFormsExportResponseSchema,
  downloadIrFormsRequestSchema,
  generateTaxFormsRequestSchema,
  getFormsAggregatesResponseSchema,
  getIrFormsExportResponseSchema,
  getIrFormsResponseSchema,
  rescindIrFormsRequestSchema,
  rescindIrFormsResponseSchema,
  searchIrFormsParamsSchema,
} from "./types/api/ir-forms/irFormsRestApiTypes";
import { IrFormTypeApiParams } from "./types/api/irRestApiTypes";
import {
  getKycDocumentSchema,
  getKycTaxDocumentationSummarySchema,
  KycDocumentGenerationParams,
  kycDocumentGenerationParamsSchema,
  KycDocumentId,
  getKycTaxDocumentationStatusSchema,
} from "./types/api/kycTaxDocumentationApiTypes";
import {
  GetNotificationsParams,
  getNotificationResponseSchema,
  getNotificationsResponseSchema,
  markNotificationAsReadRequestSchema,
} from "./types/api/notificationsRestApiTypes";
import { getOrganizationDevSettingsResponseSchema } from "./types/api/organizationDevSettingsRestApiTypes";
import {
  GetTransactionsOverviewUrlParams,
  getTransactionsOverviewResponseSchema,
  GetFormItemsOverviewUrlParams,
  getFormItemsOverviewResponseSchema,
  getEligibilityOverviewResponseSchema,
  GetEligibilityOverviewUrlParams,
} from "./types/api/overviewRestApiTypes";
import {
  CreatePayerMultipartForm,
  UpdatePayerMultipartForm,
  getPayerResponseSchema,
  getPayersResponseSchema,
  updatePayerSchema,
} from "./types/api/payersRestApiTypes";
import { DashboardFormType } from "./types/api/shared/dashboardFormType";
import {
  AccountId,
  AccountOwnerId,
  createAccountOwnerCuringRequestSchema,
  PayerId,
} from "./types/api/sharedApiTypes";
import {
  getTaxDocumentationSchema,
  getTaxDocumentationStatusSchema,
} from "./types/api/taxDocumentationApiTypes";
import {
  getReleasedDocumentResponseSchema,
  ReleasedDocumentId,
  getReleasedDocumentsResponseSchema,
} from "./types/api/taxFormsApiTypes";
import {
  GenerateTaxReport,
  generateTaxReportSchema,
  generateTaxReportResponseSchema,
  GetTaxReportsParams,
  getTaxReportsResponseSchema,
  getTaxReportStatusResponseSchema,
  getTaxReportUrlResponseSchema,
  TaxReportType,
} from "./types/api/taxReportsRestApiTypes";
import {
  getTransactionSchema,
  getTransactionsSchema,
  TransactionId,
  ExternalTransactionId,
} from "./types/api/transactionApiTypes";
import {
  getTransferSchema,
  getTransfersSchema,
  getTransfersMetadataSchema,
  TransfersApiUrlParams,
  TransfersApiFiltersUrlParams,
  getTransferOutLotsSchema,
  getTransferInLotsSchema,
} from "./types/api/transfersApiTypes";
import {
  getUmcReportsResponseSchema,
  getUmcReportUrlSchema,
  UmcApiReportType,
} from "./types/api/umcReportsApiTypes";
import AuthConfig, { authConfigSchema } from "./types/AuthConfig";
import createSearchParams from "./utils/createSearchParams";

class TaxBit {
  readonly #options: AuthConfig;

  accounts = {
    buildPath: pathBuilders.buildAccountsPath,
    get: (params: GetAccountsParams) => {
      return makeRequest({
        ...this.#options,
        method: HttpMethod.Get,
        urlPath: pathBuilders.buildAccountsPath(),
        urlSearchParams: createSearchParams(params),
        responseDataSchema: getAccountsResponseSchema,
      });
    },
  };

  account = {
    buildPath: pathBuilders.buildAccountPath,
    get: ({
      accountId,
      unmaskTaxId,
    }: {
      accountId: AccountId;
      unmaskTaxId?: boolean;
    }) => {
      return makeRequest({
        ...this.#options,
        method: HttpMethod.Get,
        urlPath: pathBuilders.buildAccountPath(accountId),
        urlSearchParams: createSearchParams({
          "transform[unmask_tax_id]": unmaskTaxId,
        }),
        responseDataSchema: getAccountResponseSchema,
      });
    },
  };

  accountV2 = {
    buildPath: pathBuilders.buildAccountV2Path,
    get: ({
      accountId,
      unmaskTaxId,
    }: {
      accountId: AccountId;
      unmaskTaxId?: boolean;
    }) => {
      return makeRequest({
        ...this.#options,
        method: HttpMethod.Get,
        urlPath: pathBuilders.buildAccountV2Path(accountId),
        urlSearchParams: createSearchParams({
          "transform[unmask_tax_id]": unmaskTaxId,
        }),
        responseDataSchema: getAccountV2ResponseSchema,
      });
    },

    externalId: {
      buildPath: pathBuilders.buildAccountV2ByExternalIdPath,
      get: ({ externalId }: { externalId: string }) => {
        return makeRequest({
          ...this.#options,
          method: HttpMethod.Get,
          urlPath: pathBuilders.buildAccountV2ByExternalIdPath(externalId),
          responseDataSchema: getAccountV2ResponseSchema,
        });
      },
    },
  };

  accountReportingProfiles = {
    buildPath: pathBuilders.buildAccountReportingProfilesPath,
    get: ({
      accountId,
      unmaskTaxId,
    }: {
      accountId: AccountId;
      unmaskTaxId?: boolean;
    }) => {
      return makeRequest({
        ...this.#options,
        method: HttpMethod.Get,
        urlPath: pathBuilders.buildAccountReportingProfilesPath(accountId),
        urlSearchParams: createSearchParams({
          "transform[unmask_tax_id]": unmaskTaxId,
        }),
        responseDataSchema: getAccountReportingProfilesResponseSchema,
      });
    },
  };

  hydratedAccounts = {
    search: {
      buildPath: pathBuilders.buildSearchHydratedAccountsPath,
      post: (params: SearchHydratedAccountsParams) => {
        return makeRequest({
          ...this.#options,
          method: HttpMethod.Post,
          requestData: params,
          requestDataSchema: searchHydratedAccountsParamSchema,
          urlPath: pathBuilders.buildSearchHydratedAccountsPath(),
          responseDataSchema: searchHydratedAccountsResponse,
        });
      },
    },
    exports: {
      buildPath: pathBuilders.buildExportHydratedAccountsPath,
      post: (params: ExportHydratedAccountsParams) => {
        return makeRequest({
          ...this.#options,
          method: HttpMethod.Post,
          requestData: params,
          requestDataSchema: exportHydratedAccountsParamsSchema,
          urlPath: pathBuilders.buildExportHydratedAccountsPath(),
          responseDataSchema: getAccountsPostExportResponseSchema,
        });
      },
    },
  };

  accountOwnerCurings = {
    buildPath: pathBuilders.buildAccountOwnerCuringsPath,
    post: ({
      requestData,
    }: {
      requestData: CreateAccountOwnerCuringRequest;
    }) => {
      return makeRequest({
        ...this.#options,
        method: HttpMethod.Post,
        urlPath: pathBuilders.buildAccountOwnerCuringsPath(),
        requestData,
        requestDataSchema: createAccountOwnerCuringRequestSchema,
        responseDataSchema: accountOwnerCuringResponseSchema,
      });
    },
  };

  accountOwnerCuringArchives = {
    buildPath: pathBuilders.buildAccountOwnerCuringArchivesPath,
    post: ({
      requestData,
    }: {
      requestData: CreateAccountOwnerCuringArchiveRequest;
    }) => {
      return makeRequest({
        ...this.#options,
        method: HttpMethod.Post,
        urlPath: pathBuilders.buildAccountOwnerCuringArchivesPath(),
        requestData,
        requestDataSchema: createAccountOwnerCuringArchiveRequestSchema,
        responseDataSchema: accountOwnerCuringArchiveResponseSchema,
      });
    },
  };

  accountOwnerDetails = {
    buildPath: pathBuilders.buildAccountOwnerDetailsPath,
    get: ({
      accountOwnerId,
      unmaskTaxId,
    }: {
      accountOwnerId: AccountOwnerId;
      unmaskTaxId?: boolean;
    }) => {
      return makeRequest({
        ...this.#options,
        method: HttpMethod.Get,
        urlPath: pathBuilders.buildAccountOwnerDetailsPath(accountOwnerId),
        urlSearchParams: createSearchParams({
          "transform[unmask_tax_id]": unmaskTaxId,
        }),
        responseDataSchema: accountOwnerDetailsResponseSchema,
      });
    },
  };

  accountsExport = {
    buildPath: pathBuilders.buildAccountsExportPath,
    post: (params: GetInitiateAccountsCsvParams) => {
      return makeRequest({
        ...this.#options,
        method: HttpMethod.Post,
        urlPath: pathBuilders.buildAccountsExportPath(),
        urlSearchParams: createSearchParams(params),
        responseDataSchema: getAccountsPostExportResponseSchema,
      });
    },
    get: (params: GetExportAccountsCsvParams) => {
      return makeRequest({
        ...this.#options,
        method: HttpMethod.Get,
        urlPath: pathBuilders.buildAccountsExportPath(params.exportId),
        responseDataSchema: getExportAccountsCsvResponseSchema,
      });
    },
  };

  payers = {
    buildPath: pathBuilders.buildPayersPath,
    get: () => {
      return makeRequest({
        ...this.#options,
        method: HttpMethod.Get,
        urlPath: pathBuilders.buildPayersPath(),
        responseDataSchema: getPayersResponseSchema,
      });
    },
    post: (data: CreatePayerMultipartForm) => {
      return makeRequest({
        ...this.#options,
        method: HttpMethod.Post,
        urlPath: pathBuilders.buildPayersPath(),
        requestData: data,
        // requestDataSchema is not not used here due to multipart form data
        responseDataSchema: getPayerResponseSchema,
      });
    },
  };

  payer = {
    buildPath: pathBuilders.buildPayerPath,
    get: ({ payerId }: { payerId: PayerId }) => {
      return makeRequest({
        ...this.#options,
        method: HttpMethod.Get,
        urlPath: pathBuilders.buildPayerPath(payerId),
        responseDataSchema: getPayerResponseSchema,
      });
    },
    patch: ({
      payerId,
      data,
    }: {
      payerId: PayerId;
      data: UpdatePayerMultipartForm;
    }) => {
      return makeRequest({
        ...this.#options,
        method: HttpMethod.Patch,
        urlPath: pathBuilders.buildPayerPath(payerId),
        // schema is not not used here due to multipart form data
        requestData: data,
        requestDataSchema: updatePayerSchema,
        responseDataSchema: getPayerResponseSchema,
      });
    },
    delete: (payerId: PayerId) => {
      return makeRequest({
        ...this.#options,
        method: HttpMethod.Delete,
        urlPath: pathBuilders.buildPayerPath(payerId),
      });
    },
  };

  companies = {
    users: {
      buildPath: pathBuilders.buildCompanyUsersPath,
      get: ({ companyId }: { companyId: string }) => {
        return makeRequest({
          ...this.#options,
          method: HttpMethod.Get,
          urlPath: pathBuilders.buildCompanyUsersPath(companyId),
          responseDataSchema: getCompanyUsersResponseSchema,
        });
      },
    },
  };

  companyUsers = {
    buildPath: pathBuilders.buildMfaResetForCompanyUserPath,
    post: ({ companyUserId }: ResetMfaForCompanyUser) => {
      return makeRequest({
        ...this.#options,
        method: HttpMethod.Post,
        urlPath: pathBuilders.buildMfaResetForCompanyUserPath(companyUserId),
      });
    },
  };

  currentCompanyUser = {
    buildPath: pathBuilders.buildCurrentCompanyUserPath,
    get: () => {
      return makeRequest({
        ...this.#options,
        method: HttpMethod.Get,
        urlPath: pathBuilders.buildCurrentCompanyUserPath(),
        responseDataSchema: getCurrentCompanyUserResponseSchema,
      });
    },
    name: {
      buildPath: pathBuilders.buildCurrentCompanyUserNamePath,
      patch: (data: UpdateCurrentCompanyUserName) =>
        makeRequest({
          ...this.#options,
          method: HttpMethod.Patch,
          urlPath: pathBuilders.buildCurrentCompanyUserNamePath(),
          requestData: data,
          requestDataSchema: updateCurrentCompanyUserSchema,
          responseDataSchema: updateCurrentCompanyUserResponseSchema,
        }),
    },
    passwordChange: {
      buildPath: pathBuilders.buildCurrentCompanyUserPasswordChangePath,
      post: () =>
        makeRequest({
          ...this.#options,
          method: HttpMethod.Post,
          urlPath: pathBuilders.buildCurrentCompanyUserPasswordChangePath(),
        }),
    },
    downloads: {
      buildPath: pathBuilders.buildCurrentCompanyUserDownloadsPath,
      get: () =>
        makeRequest({
          ...this.#options,
          method: HttpMethod.Get,
          urlPath: pathBuilders.buildCurrentCompanyUserDownloadsPath(),
          responseDataSchema: getDownloadsResponseSchema,
        }),
    },
  };

  currentCompanyUserOrganizations = {
    buildPath: pathBuilders.buildCurrentCompanyUserOrganizationsPath,
    get: () => {
      return makeRequest({
        ...this.#options,
        method: HttpMethod.Get,
        urlPath: pathBuilders.buildCurrentCompanyUserOrganizationsPath(),
        responseDataSchema: getCurrentCompanyUserOrganizationsResponseSchema,
      });
    },
  };

  roles = {
    buildPath: pathBuilders.buildRolesPath,
    get: () => {
      return makeRequest({
        ...this.#options,
        method: HttpMethod.Get,
        urlPath: pathBuilders.buildRolesPath(),
        responseDataSchema: getRolesResponseSchema,
      });
    },
  };

  organizations = {
    members: {
      buildPath: pathBuilders.buildOrganizationMembersPath,
      get: ({
        organizationId,
        limit,
        page,
      }: {
        organizationId: string;
        limit?: number;
        page?: number;
      }) => {
        return makeRequest({
          ...this.#options,
          method: HttpMethod.Get,
          urlPath: pathBuilders.buildOrganizationMembersPath(organizationId),
          urlSearchParams: createSearchParams({
            "page[limit]": limit,
            // Auth0 API uses 0-based page numbers so we need to subtract 1
            "page[page]": (page ?? 1) - 1,
          }),
          responseDataSchema: getOrganizationMembersResponseSchema,
        });
      },
      patch: (organizationId: string, data: RemoveUser) => {
        return makeRequest({
          ...this.#options,
          method: HttpMethod.Patch,
          urlPath: pathBuilders.buildOrganizationMembersPath(organizationId),
          requestData: data,
          requestDataSchema: removeUserSchema,
        });
      },
      roles: {
        buildPath: pathBuilders.buildOrganizationMemberRolesPath,
        patch: (
          organizationId: string,
          memberId: string,
          data: UpdateOrganizationMemberRoles
        ) => {
          return makeRequest({
            ...this.#options,
            method: HttpMethod.Patch,
            urlPath: pathBuilders.buildOrganizationMemberRolesPath(
              organizationId,
              memberId
            ),
            requestData: data,
            requestDataSchema: updateOrganizationMemberRolesSchema,
          });
        },
      },
    },
    invitations: {
      buildPath: pathBuilders.buildInvitationsPath,
      get: (organizationId: string) => {
        return makeRequest({
          ...this.#options,
          method: HttpMethod.Get,
          urlPath: pathBuilders.buildInvitationsPath(organizationId),
          responseDataSchema: getInvitationsResponseSchema,
        });
      },
      post: (organizationId: string, data: InviteUser) => {
        return makeRequest({
          ...this.#options,
          method: HttpMethod.Post,
          urlPath: pathBuilders.buildInvitationsPath(organizationId),
          requestData: data,
          responseDataSchema: inviteUserToOrganizationResponseSchema,
          requestDataSchema: inviteUserSchema,
        });
      },
    },
    devSettings: {
      buildPath: pathBuilders.buildOrganizationDevSettingsPath,
      get: (organizationId: string) => {
        return makeRequest({
          ...this.#options,
          method: HttpMethod.Get,
          urlPath:
            pathBuilders.buildOrganizationDevSettingsPath(organizationId),
          responseDataSchema: getOrganizationDevSettingsResponseSchema,
        });
      },
    },
  };

  taxReports = {
    all: {
      buildPath: pathBuilders.buildTaxReportsPath,
      get: (params: GetTaxReportsParams) => {
        return makeRequest({
          ...this.#options,
          method: HttpMethod.Get,
          urlPath: pathBuilders.buildTaxReportsPath(),
          urlSearchParams: createSearchParams(params),
          responseDataSchema: getTaxReportsResponseSchema,
        });
      },
      url: {
        buildPath: pathBuilders.buildTaxReportsUrlPath,
        get: ({ reportType }: { reportType: TaxReportType }) => {
          return makeRequest({
            ...this.#options,
            method: HttpMethod.Get,
            urlPath: pathBuilders.buildTaxReportsUrlPath(reportType),
            responseDataSchema: getTaxReportUrlResponseSchema,
          });
        },
      },
      status: {
        buildPath: pathBuilders.buildTaxReportStatusPath,
        get: () => {
          return makeRequest({
            ...this.#options,
            method: HttpMethod.Get,
            urlPath: pathBuilders.buildTaxReportStatusPath(),
            responseDataSchema: getTaxReportStatusResponseSchema,
          });
        },
      },
    },
    generate: {
      buildPath: pathBuilders.buildGenerateTaxReportPath,
      post: ({ requestData }: { requestData: GenerateTaxReport }) => {
        return makeRequest({
          ...this.#options,
          method: HttpMethod.Post,
          urlPath: pathBuilders.buildGenerateTaxReportPath(),
          requestData,
          requestDataSchema: generateTaxReportSchema,
          responseDataSchema: generateTaxReportResponseSchema,
        });
      },
    },
  };

  umcReports = {
    buildPath: pathBuilders.buildUmcReportsPath,
    get: ({ type }: { type: UmcApiReportType }) => {
      return makeRequest({
        ...this.#options,
        method: HttpMethod.Get,
        urlSearchParams: new URLSearchParams({
          "filters[type][$eq]": type,
        }),
        urlPath: pathBuilders.buildUmcReportsPath(),
        responseDataSchema: getUmcReportsResponseSchema,
      });
    },
    url: {
      buildPath: pathBuilders.buildUmcReportsUrlPath,
      get: ({ key }: { key: string }) => {
        return makeRequest({
          ...this.#options,
          method: HttpMethod.Get,
          urlPath: pathBuilders.buildUmcReportsUrlPath(),
          urlSearchParams: new URLSearchParams({
            "filters[key][$eq]": key,
          }),
          responseDataSchema: getUmcReportUrlSchema,
        });
      },
    },
  };

  taxForms = {
    buildPath: pathBuilders.buildTaxFormsPath,
    get: ({ accountId }: { accountId: AccountId }) => {
      return makeRequest({
        ...this.#options,
        method: HttpMethod.Get,
        urlPath: pathBuilders.buildTaxFormsPath(accountId),
        responseDataSchema: getReleasedDocumentsResponseSchema,
      });
    },
    url: {
      buildPath: pathBuilders.buildTaxFormsUrlPath,
      get: ({
        accountId,
        documentId,
      }: {
        accountId: AccountId;
        documentId: ReleasedDocumentId;
      }) => {
        return makeRequest({
          ...this.#options,
          method: HttpMethod.Get,
          urlPath: pathBuilders.buildTaxFormsUrlPath(accountId, documentId),
          responseDataSchema: getReleasedDocumentResponseSchema,
        });
      },
    },
  };

  taxDocumentation = {
    buildPath: pathBuilders.buildTaxDocumentationPath,
    get: ({
      userId,
      unmaskTaxId,
    }: {
      userId: UserId;
      unmaskTaxId?: boolean;
    }) => {
      return makeRequest({
        ...this.#options,
        method: HttpMethod.Get,
        urlPath: pathBuilders.buildTaxDocumentationPath(userId),
        urlSearchParams: createSearchParams({
          "transform[unmask_tax_id]": unmaskTaxId,
        }),
        responseDataSchema: getTaxDocumentationSchema,
      });
    },
    status: {
      buildPath: pathBuilders.buildTaxDocumentationStatusPath,
      get: ({ userId }: { userId: UserId }) => {
        return makeRequest({
          ...this.#options,
          method: HttpMethod.Get,
          urlPath: pathBuilders.buildTaxDocumentationStatusPath(userId),
          responseDataSchema: getTaxDocumentationStatusSchema,
        });
      },
    },
  };

  kycTaxDocumentation = {
    buildPath: pathBuilders.buildKycTaxDocumentationPath,
    document: {
      buildPath: pathBuilders.buildKycDocumentPath,
      post: ({
        userId,
        requestParams,
      }: {
        userId: UserId | AccountOwnerId;
        requestParams: KycDocumentGenerationParams;
      }) => {
        return makeRequest({
          ...this.#options,
          method: HttpMethod.Post,
          urlPath: pathBuilders.buildKycPostDocumentPath(userId),
          responseDataSchema: getKycDocumentSchema,
          requestData: requestParams,
          requestDataSchema: kycDocumentGenerationParamsSchema,
        });
      },
      get: ({
        userId,
        documentId,
      }: {
        userId: UserId | AccountOwnerId;
        documentId: KycDocumentId;
      }) => {
        return makeRequest({
          ...this.#options,
          method: HttpMethod.Get,
          urlPath: pathBuilders.buildKycDocumentPath(userId, documentId),
          responseDataSchema: getKycDocumentSchema,
        });
      },
    },
    summary: {
      buildPath: pathBuilders.buildKycTaxDocumentationSummaryPath,
      get: ({ userId }: { userId: UserId | AccountOwnerId }) => {
        return makeRequest({
          ...this.#options,
          method: HttpMethod.Get,
          urlPath: pathBuilders.buildKycTaxDocumentationSummaryPath(userId),
          responseDataSchema: getKycTaxDocumentationSummarySchema,
        });
      },
    },
    status: {
      buildPath: pathBuilders.buildKycTaxDocumentationStatusPath,
      get: ({ userId }: { userId: UserId | AccountOwnerId }) => {
        return makeRequest({
          ...this.#options,
          method: HttpMethod.Get,
          urlPath: pathBuilders.buildKycTaxDocumentationStatusPath(userId),
          responseDataSchema: getKycTaxDocumentationStatusSchema,
        });
      },
    },
  };

  inventory = {
    summary: {
      buildPath: pathBuilders.buildInventorySummaryPath,
      get: ({
        accountId,
        assetTypes,
        shouldExcludeMissingCostBasis,
      }: {
        accountId: AccountId;
        assetTypes?: AssetType[];
        shouldExcludeMissingCostBasis?: boolean;
      }) => {
        return makeRequest({
          ...this.#options,
          method: HttpMethod.Get,
          urlPath: pathBuilders.buildInventorySummaryPath(accountId),
          urlSearchParams: createSearchParams({
            "filters[asset_types][$eq]": assetTypes,
            "filters[exclude_missing_cost_basis][$eq]":
              shouldExcludeMissingCostBasis,
          }),
          responseDataSchema: getInventorySummaryResponseSchema,
        });
      },
    },
  };

  transactions = {
    buildPath: pathBuilders.buildTransactionsPath,
    get: ({
      accountId,
      limit,
      next,
    }: {
      accountId: AccountId;
      limit?: number;
      next?: string;
    }) => {
      return makeRequest({
        ...this.#options,
        method: HttpMethod.Get,
        urlPath: pathBuilders.buildTransactionsPath(accountId),
        urlSearchParams: createSearchParams({
          "page[limit]": limit,
          "page[next]": next,
        }),
        responseDataSchema: getTransactionsSchema,
      });
    },
  };

  transaction = {
    buildPath: pathBuilders.buildTransactionPath,
    get: ({
      accountId,
      transactionId,
    }: {
      accountId: AccountId;
      transactionId: TransactionId;
    }) => {
      return makeRequest({
        ...this.#options,
        method: HttpMethod.Get,
        urlPath: pathBuilders.buildTransactionPath(accountId, transactionId),
        responseDataSchema: getTransactionSchema,
      });
    },
  };

  gains = {
    buildPath: pathBuilders.buildGainsPath,
    get: ({
      accountId,
      transactionId,
      next,
      limit,
      offset,
    }: {
      accountId: AccountId;
      transactionId: TransactionId;
      next?: string;
      limit?: number;
      offset?: number;
    }) => {
      return makeRequest({
        ...this.#options,
        method: HttpMethod.Get,
        urlPath: pathBuilders.buildGainsPath(accountId),
        responseDataSchema: getGainsSchema,
        urlSearchParams: createSearchParams({
          "filters[transaction_id][$eq]": transactionId,
          "page[next]": next,
          "page[limit]": limit,
          "page[offset]": offset,
        }),
      });
    },
    summary: {
      buildPath: pathBuilders.buildGainsSummaryPath,
      get: ({
        accountId,
        transactionId,
      }: {
        accountId: AccountId;
        transactionId: TransactionId;
      }) => {
        return makeRequest({
          ...this.#options,
          method: HttpMethod.Get,
          urlPath: pathBuilders.buildGainsSummaryPath(accountId),
          urlSearchParams: createSearchParams({
            "filters[transaction_id][$eq]": transactionId,
          }),
          responseDataSchema: getGainsSummaryResponseSchema,
        });
      },
    },
  };

  transfersIn = {
    buildPath: pathBuilders.buildTransfersInPath,
    get: (params: TransfersApiUrlParams) => {
      return makeRequest({
        ...this.#options,
        method: HttpMethod.Get,
        urlPath: pathBuilders.buildTransfersInPath(),
        urlSearchParams: createSearchParams(params),
        responseDataSchema: getTransfersSchema,
      });
    },
    metadata: {
      buildPath: pathBuilders.buildTransfersInMetadataPath,
      get: (params: TransfersApiFiltersUrlParams) => {
        return makeRequest({
          ...this.#options,
          method: HttpMethod.Get,
          urlPath: pathBuilders.buildTransfersInMetadataPath(),
          urlSearchParams: createSearchParams(params),
          responseDataSchema: getTransfersMetadataSchema,
        });
      },
    },
  };

  transfersOut = {
    buildPath: pathBuilders.buildTransfersOutPath,
    get: (params: TransfersApiUrlParams) => {
      return makeRequest({
        ...this.#options,
        method: HttpMethod.Get,
        urlPath: pathBuilders.buildTransfersOutPath(),
        urlSearchParams: createSearchParams(params),
        responseDataSchema: getTransfersSchema,
      });
    },
    metadata: {
      buildPath: pathBuilders.buildTransfersOutMetadataPath,
      get: (params: TransfersApiFiltersUrlParams) => {
        return makeRequest({
          ...this.#options,
          method: HttpMethod.Get,
          urlPath: pathBuilders.buildTransfersOutMetadataPath(),
          urlSearchParams: createSearchParams(params),
          responseDataSchema: getTransfersMetadataSchema,
        });
      },
    },
  };

  transferIn = {
    buildPath: pathBuilders.buildTransferInPath,
    get: ({
      transactionId,
      accountId,
    }: {
      transactionId: TransactionId;
      accountId: AccountId;
    }) => {
      return makeRequest({
        ...this.#options,
        method: HttpMethod.Get,
        urlPath: pathBuilders.buildTransferInPath(transactionId, accountId),
        responseDataSchema: getTransferSchema,
      });
    },
  };

  transferInLots = {
    buildPath: pathBuilders.buildTransferInLotsPath,
    get: (externalTransactionId: ExternalTransactionId) => {
      return makeRequest({
        ...this.#options,
        method: HttpMethod.Get,
        urlPath: pathBuilders.buildTransferInLotsPath(externalTransactionId),
        responseDataSchema: getTransferInLotsSchema,
      });
    },
  };

  transferOut = {
    buildPath: pathBuilders.buildTransferOutPath,
    get: ({
      transactionId,
      accountId,
    }: {
      transactionId: TransactionId;
      accountId: AccountId;
    }) => {
      return makeRequest({
        ...this.#options,
        method: HttpMethod.Get,
        urlPath: pathBuilders.buildTransferOutPath(transactionId, accountId),
        responseDataSchema: getTransferSchema,
      });
    },
  };

  transferOutLots = {
    buildPath: pathBuilders.buildTransferOutLotsPath,
    get: ({
      accountId,
      transactionId,
      next,
      limit,
    }: {
      accountId: AccountId;
      transactionId: TransactionId;
      next?: string;
      limit?: number;
      offset?: number;
    }) => {
      return makeRequest({
        ...this.#options,
        method: HttpMethod.Get,
        urlPath: pathBuilders.buildTransferOutLotsPath(
          transactionId,
          accountId
        ),
        responseDataSchema: getTransferOutLotsSchema,
        urlSearchParams: createSearchParams({
          "page[next]": next,
          "page[limit]": limit,
        }),
      });
    },
  };

  asset = {
    buildPath: pathBuilders.buildAssetPath,
    get: ({ assetId }: { assetId: AssetId }) => {
      return makeRequest({
        ...this.#options,
        method: HttpMethod.Get,
        urlPath: pathBuilders.buildAssetPath(assetId),
        responseDataSchema: getAssetSchema,
      });
    },
  };

  formMetadata = {
    buildPath: pathBuilders.buildFormsMetadataPath,
    get: (params: IrFormTypeApiParams) => {
      return makeRequest({
        ...this.#options,
        method: HttpMethod.Get,
        urlPath: pathBuilders.buildFormsMetadataPath(),
        responseDataSchema: getFormMetadataSchema,
        urlSearchParams: createSearchParams(params),
      });
    },
  };

  files = {
    buildPath: pathBuilders.buildFilesPath,
    get: (params: GetFilesParams) => {
      return makeRequest({
        ...this.#options,
        method: HttpMethod.Get,
        urlPath: pathBuilders.buildFilesPath(),
        responseDataSchema: getFilesResponseSchema,
        urlSearchParams: createSearchParams(params),
      });
    },
    file: {
      buildPath: pathBuilders.buildFilePath,
      get: ({ fileId }: { fileId: string }) => {
        return makeRequest({
          ...this.#options,
          method: HttpMethod.Get,
          urlPath: pathBuilders.buildFilePath(fileId),
          responseDataSchema: getFileUrlResponseSchema,
        });
      },
      put: ({
        fileId,
        requestData,
      }: {
        fileId: string;
        requestData: UpdateFileRequest;
      }) => {
        return makeRequest({
          ...this.#options,
          method: HttpMethod.Put,
          urlPath: pathBuilders.buildFilePath(fileId),
          requestData,
          requestDataSchema: updateFileRequestSchema,
          responseDataSchema: updateFileResponseSchema,
        });
      },
      delete: ({ fileId }: { fileId: string }) => {
        return makeRequest({
          ...this.#options,
          method: HttpMethod.Delete,
          urlPath: pathBuilders.buildFilePath(fileId),
          responseDataSchema: deleteFileResponseSchema,
        });
      },
      confirmIngestion: {
        buildPath: pathBuilders.buildFileConfirmIngestionPath,
        put: ({
          fileId,
          requestData,
        }: {
          fileId: string;
          requestData: ConfirmIngestionRequest;
        }) => {
          return makeRequest({
            ...this.#options,
            method: HttpMethod.Put,
            urlPath: pathBuilders.buildFileConfirmIngestionPath(fileId),
            requestData,
            requestDataSchema: confirmIngestionRequestSchema,
            responseDataSchema: confirmIngestionResponseSchema,
          });
        },
      },
      errorReport: {
        buildPath: pathBuilders.buildFileErrorReportPath,
        get: ({ fileId }: { fileId: string }) => {
          return makeRequest({
            ...this.#options,
            method: HttpMethod.Get,
            urlPath: pathBuilders.buildFileErrorReportPath(fileId),
            responseDataSchema: fileErrorReportResponseSchema,
          });
        },
      },
    },
    multipartUploads: {
      buildPath: pathBuilders.buildFilesMultipartUploadsPath,
      post: ({
        requestData,
      }: {
        requestData: CreateMultipartUploadRequest;
      }) => {
        return makeRequest({
          ...this.#options,
          method: HttpMethod.Post,
          urlPath: pathBuilders.buildFilesMultipartUploadsPath(),
          requestData,
          requestDataSchema: createMultipartUploadRequestSchema,
          responseDataSchema: createMultipartUploadResponseSchema,
        });
      },
      /**
       * The file upload request directly called a pre-signed URL provided by
       * AWS. We manually make a request and parse out a header from the response,
       * as we don't need the standard handling for calls within the TaxBit system.
       */
      put: async ({ presignedUrl, fileChunk }: UploadFileRequestParams) => {
        const response = await doFetch({
          url: presignedUrl,
          method: HttpMethod.Put,
          body: fileChunk,
        });

        // Throw the standard TaxBit response error if this request fails.
        if (!response.ok) {
          throw await getUnexpectedResponseError(response);
        }

        // This header is enclosed in extra quotes, so we remove them.
        // https://stackoverflow.com/questions/66533278/why-is-etag-required-to-be-enclosed-in-double-quotes
        const eTag = response.headers.get("ETag")?.replaceAll('"', "");

        return { eTag };
      },
      upload: {
        buildPath: pathBuilders.buildFilesMultipartUploadPath,
        delete: ({ uploadId }: { uploadId: string }) => {
          return makeRequest({
            ...this.#options,
            method: HttpMethod.Delete,
            urlPath: pathBuilders.buildFilesMultipartUploadPath(uploadId),
            responseDataSchema: abortMultipartUploadResponseSchema,
          });
        },
        end: {
          buildPath: pathBuilders.buildFilesMultipartUploadEndPath,
          post: ({
            uploadId,
            requestData,
          }: {
            uploadId: string;
            requestData: EndMultipartUploadRequest;
          }) => {
            return makeRequest({
              ...this.#options,
              method: HttpMethod.Post,
              urlPath: pathBuilders.buildFilesMultipartUploadEndPath(uploadId),
              requestData,
              requestDataSchema: endMultipartUploadRequestSchema,
              responseDataSchema: endMultipartUploadResponseSchema,
            });
          },
        },
      },
    },
  };

  formItems = {
    buildPath: pathBuilders.buildFormItemsPath,
    get: ({
      params,
      accountId,
    }: {
      params: GetFormItemsParams;
      accountId: AccountId;
    }) => {
      return makeRequest({
        ...this.#options,
        method: HttpMethod.Get,
        urlPath: pathBuilders.buildFormItemsPath(accountId),
        urlSearchParams: createSearchParams(params),
        responseDataSchema: getFormItemsResponseSchema,
      });
    },
  };

  eligibility = {
    alerts: {
      buildPath: pathBuilders.buildEligibilityAlertsPath,
      get: (documentDate: FourDigitYear, documentType: DashboardFormType) => {
        return makeRequest({
          ...this.#options,
          method: HttpMethod.Get,
          urlPath: pathBuilders.buildEligibilityAlertsPath(),
          responseDataSchema: getEligibilityAlertsResponseSchema,
          urlSearchParams: createSearchParams({
            "filters[document_date]": documentDate,
            "filters[document_type]": documentType,
          }),
        });
      },
    },
    counts: {
      buildPath: pathBuilders.buildEligibilityCountsPath,
      get: (params: IrFormTypeApiParams) => {
        return makeRequest({
          ...this.#options,
          method: HttpMethod.Get,
          urlPath: pathBuilders.buildEligibilityCountsPath(),
          responseDataSchema: getEligibilityCountsResponseSchema,
          urlSearchParams: createSearchParams(params),
        });
      },
    },
    syncTimestamps: {
      buildPath: pathBuilders.buildEligibilitySyncTimestampsPath,
      get: (documentType: DashboardFormType) => {
        return makeRequest({
          ...this.#options,
          method: HttpMethod.Get,
          urlPath: pathBuilders.buildEligibilitySyncTimestampsPath(),
          responseDataSchema: getEligibilitySyncTimestampsResponseSchema,
          urlSearchParams: createSearchParams({
            "filters[document_type]": documentType,
          }),
        });
      },
    },
    override: {
      accounts: {
        buildPath: pathBuilders.buildEligibilityBatchOverridesPath,
        put: (data: CreateEligibilityBatchOverrides) => {
          return makeRequest({
            ...this.#options,
            method: HttpMethod.Put,
            urlPath: pathBuilders.buildEligibilityBatchOverridesPath(),
            responseDataSchema: createEligibilityBatchOverridesResponseSchema,
            requestData: data,
            requestDataSchema: createEligibilityBatchOverridesSchema,
          });
        },
      },
    },
    accounts: {
      find: {
        buildPath: pathBuilders.buildEligibilityFindAccountsDataPath,
        post: (requestData: EligibilityFindAccountsRequest) => {
          return makeRequest({
            ...this.#options,
            method: HttpMethod.Post,
            urlPath: pathBuilders.buildEligibilityFindAccountsDataPath(),
            responseDataSchema: getEligibilityDataResponseSchema,
            requestDataSchema: eligibilityFindAccountsRequestSchema,
            requestData,
          });
        },
      },
    },
    exports: {
      buildPath: pathBuilders.buildEligibilityExportsPath,
      post: (
        params: Omit<GetEligibilityDataParams, "page[limit]" | "page[offset]">,
        requestData: CreateEligibilityExportBodyDto
      ) => {
        return makeRequest({
          ...this.#options,
          method: HttpMethod.Post,
          urlPath: pathBuilders.buildEligibilityExportsPath(),
          requestData,
          requestDataSchema: createEligibilityExportBodyDtoSchema,
          responseDataSchema: createEligibilityExportResponseSchema,
          urlSearchParams: createSearchParams(params),
        });
      },
    },
    export: {
      buildPath: pathBuilders.buildEligibilityExportPath,
      get: ({ exportId }: { exportId: Uuid }) => {
        return makeRequest({
          ...this.#options,
          method: HttpMethod.Get,
          urlPath: pathBuilders.buildEligibilityExportPath(exportId),
          responseDataSchema: getEligibilityExportResponseSchema,
        });
      },
    },
    qaPackage: {
      buildPath: pathBuilders.buildEligibilityQaPackagePath,
      post: (requestData: CreateEligibilityQaPackageRequest) => {
        return makeRequest({
          ...this.#options,
          method: HttpMethod.Post,
          urlPath: pathBuilders.buildEligibilityQaPackagePath(),
          requestDataSchema: createEligibilityQaPackageRequestSchema,
          requestData,
        });
      },
    },
    recalculate: {
      buildPath: pathBuilders.buildEligibilityRecalcPath,
      post: (requestData: EligibilityRecalcRequest) => {
        return makeRequest({
          ...this.#options,
          method: HttpMethod.Post,
          urlPath: pathBuilders.buildEligibilityRecalcPath(),
          requestDataSchema: eligibilityRecalcRequestSchema,
          requestData,
        });
      },
    },
  };

  irForms = {
    search: {
      buildPath: pathBuilders.buildSearchIrFormsPath,
      post: (requestData: SearchIrFormsParams) => {
        return makeRequest({
          ...this.#options,
          method: HttpMethod.Post,
          urlPath: pathBuilders.buildSearchIrFormsPath(),
          responseDataSchema: getIrFormsResponseSchema,
          requestData,
          requestDataSchema: searchIrFormsParamsSchema,
        });
      },
    },
    aggregates: {
      buildPath: pathBuilders.buildIrFormsAggregatesPath,
      get: (params: IrFormTypeApiParams) => {
        return makeRequest({
          ...this.#options,
          method: HttpMethod.Get,
          urlPath: pathBuilders.buildIrFormsAggregatesPath(),
          responseDataSchema: getFormsAggregatesResponseSchema,
          urlSearchParams: createSearchParams(params),
        });
      },
    },
    batch: {
      buildPath: pathBuilders.buildIrFormsBatchPath,
      post: (requestData: GenerateTaxFormsRequest) => {
        return makeRequest({
          ...this.#options,
          method: HttpMethod.Post,
          urlPath: pathBuilders.buildIrFormsBatchPath(),
          requestDataSchema: generateTaxFormsRequestSchema,
          requestData,
        });
      },
    },
    exports: {
      buildPath: pathBuilders.buildIrFormsExportsPath,
      post: (
        params: Omit<GetIrFormsParams, "page[limit]" | "page[offset]">,
        requestData?: CreateIrFormsExportRequest
      ) => {
        return makeRequest({
          ...this.#options,
          method: HttpMethod.Post,
          urlPath: pathBuilders.buildIrFormsExportsPath(),
          responseDataSchema: createIrFormsExportResponseSchema,
          requestDataSchema: createIrFormsExportRequestSchema,
          requestData,
          urlSearchParams: createSearchParams(params),
        });
      },
    },
    export: {
      buildPath: pathBuilders.buildIrFormsExportPath,
      get: ({ exportId }: { exportId: Uuid }) => {
        return makeRequest({
          ...this.#options,
          method: HttpMethod.Get,
          urlPath: pathBuilders.buildIrFormsExportPath(exportId),
          responseDataSchema: getIrFormsExportResponseSchema,
        });
      },
    },
    rescind: {
      buildPath: pathBuilders.buildIrFormsRescindPath,
      patch: (rescindIrFormsRequest: RescindIrFormsRequest) => {
        return makeRequest({
          ...this.#options,
          method: HttpMethod.Patch,
          urlPath: pathBuilders.buildIrFormsRescindPath(),
          responseDataSchema: rescindIrFormsResponseSchema,
          requestDataSchema: rescindIrFormsRequestSchema,
          requestData: rescindIrFormsRequest,
        });
      },
    },
    downloads: {
      buildPath: pathBuilders.buildIrFormsDownloadsPath,
      post: (downloadIrFormsRequest: DownloadIrFormsRequest) => {
        return makeRequest({
          ...this.#options,
          method: HttpMethod.Post,
          urlPath: pathBuilders.buildIrFormsDownloadsPath(),
          requestDataSchema: downloadIrFormsRequestSchema,
          requestData: downloadIrFormsRequest,
        });
      },
    },
  };

  notifications = {
    buildPath: pathBuilders.buildNotificationsPath,
    get: (params: GetNotificationsParams) => {
      return makeRequest({
        ...this.#options,
        method: HttpMethod.Get,
        urlPath: pathBuilders.buildNotificationsPath(),
        urlSearchParams: createSearchParams(params),
        responseDataSchema: getNotificationsResponseSchema,
      });
    },
    put: () => {
      return makeRequest({
        ...this.#options,
        method: HttpMethod.Put,
        urlPath: pathBuilders.buildNotificationsPath(),
      });
    },
    notification: {
      buildPath: pathBuilders.buildNotificationPath,
      get: (notificationId: Uuid) => {
        return makeRequest({
          ...this.#options,
          method: HttpMethod.Get,
          urlPath: pathBuilders.buildNotificationPath(notificationId),
          responseDataSchema: getNotificationResponseSchema,
        });
      },
    },
    read: {
      buildPath: pathBuilders.buildNotificationsReadPath,
      post: (notificationId: Uuid) => {
        return makeRequest({
          ...this.#options,
          method: HttpMethod.Post,
          urlPath: pathBuilders.buildNotificationsReadPath(),
          requestDataSchema: markNotificationAsReadRequestSchema,
          requestData: [notificationId],
        });
      },
    },
  };

  overview = {
    eligibility: {
      buildPath: pathBuilders.buildOverviewEligibilityPath,
      get: (params: GetEligibilityOverviewUrlParams) =>
        makeRequest({
          ...this.#options,
          method: HttpMethod.Get,
          urlPath: pathBuilders.buildOverviewEligibilityPath(),
          urlSearchParams: createSearchParams(params),
          responseDataSchema: getEligibilityOverviewResponseSchema,
        }),
    },
    formItems: {
      buildPath: pathBuilders.buildOverviewFormItemsPath,
      get: (params: GetFormItemsOverviewUrlParams) =>
        makeRequest({
          ...this.#options,
          method: HttpMethod.Get,
          urlPath: pathBuilders.buildOverviewFormItemsPath(),
          urlSearchParams: createSearchParams(params),
          responseDataSchema: getFormItemsOverviewResponseSchema,
        }),
    },
    transactions: {
      buildPath: pathBuilders.buildOverviewTransactionsPath,
      get: (params: GetTransactionsOverviewUrlParams) =>
        makeRequest({
          ...this.#options,
          method: HttpMethod.Get,
          urlPath: pathBuilders.buildOverviewTransactionsPath(),
          urlSearchParams: createSearchParams(params),
          responseDataSchema: getTransactionsOverviewResponseSchema,
        }),
    },
  };

  constructor(options: AuthConfig) {
    this.#options = authConfigSchema.parse(options);
  }
}

export default TaxBit;
