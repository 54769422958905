import styled, { DefaultTheme } from "styled-components";

type GridProps = {
  alignment?: "left" | "center";
  padding?: keyof DefaultTheme["spacing"];
  rowGap?: keyof DefaultTheme["spacing"];
  columnGap?: keyof DefaultTheme["spacing"];
};

const Grid = styled.div<GridProps>(
  ({ alignment = "left", padding, rowGap = "s", theme }) => `
    display: grid;
    grid-template-columns: ${alignment === "center" ? "repeat(2, 1fr)" : "0.8fr 2fr"};
    row-gap: ${theme.spacing[rowGap]};
    column-gap: ${theme.spacing.l};
    ${padding ? `padding: ${theme.spacing[padding]};` : ""}
    word-wrap: break-word;
  `
);

export default Grid;
