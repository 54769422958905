import { zodResolver } from "@hookform/resolvers/zod";
import { UserPermission, useUserPermission } from "@taxbit-dashboard/commons";
import { Body, Flex, Modal } from "@taxbit-private/cosmic";
import { RhfCheckbox } from "@taxbit-private/cosmic-react-hook-form";
import { useCallback } from "react";
import { FormProvider, useForm } from "react-hook-form";

import { DownloadEligibilityTableTrackingId } from "./useEligibilityTableExport";
import {
  EXPORT_ELIGIBILITY_TABLE_FORM_ID,
  ExportEligibilityTableModalForm,
  FORM_DATA_EXPORT_DISABLED_FORM_TYPES,
  exportEligibilityTableModalFormDefaultValues,
  exportEligibilityTableModalFormSchema,
} from "../context/exportEligibilityTableModalFormTypes";
import { useEligibilityDataContext } from "../context/useEligibilityData";

type ExportEligibilityTableModalProps = {
  onClick: (formData?: ExportEligibilityTableModalForm) => void;
  onClose: () => void;
  isDisabled: boolean;
  isLoading: boolean;
  isOpen: boolean;
};

const ExportEligibilityTableModal = ({
  onClick,
  onClose,
  isDisabled,
  isLoading,
  isOpen,
}: ExportEligibilityTableModalProps) => {
  const hasReadTinsPermission = useUserPermission(UserPermission.ReadTins);

  const { currentForm } = useEligibilityDataContext();

  const formMethods = useForm<ExportEligibilityTableModalForm>({
    resolver: zodResolver(exportEligibilityTableModalFormSchema),
    defaultValues: exportEligibilityTableModalFormDefaultValues,
  });

  const handleSubmit = useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      void formMethods.handleSubmit(
        (formData: ExportEligibilityTableModalForm) => {
          onClick(formData);
          onClose();
        }
      )(e);
    },
    [formMethods, onClick, onClose]
  );

  return (
    <Modal
      title="Account Eligibility Report"
      isOpen={isOpen}
      onClose={onClose}
      closeButtonTrackingId={DownloadEligibilityTableTrackingId.CloseButton}
      secondaryButtonProps={{
        label: "Cancel",
        trackingId: DownloadEligibilityTableTrackingId.CancelButton,
        onClick: onClose,
      }}
      primaryButtonProps={{
        form: EXPORT_ELIGIBILITY_TABLE_FORM_ID,
        type: "submit",
        label: "Export",
        trackingId: DownloadEligibilityTableTrackingId.SubmitButton,
        isDisabled,
        isLoading,
        loadingText: "Exporting",
        variant: "button-primary",
      }}
    >
      <FormProvider {...formMethods}>
        <form id={EXPORT_ELIGIBILITY_TABLE_FORM_ID} onSubmit={handleSubmit}>
          <Flex direction="column" gap="m">
            <Body>
              Select any additional reports you would like to download for each
              relevant Account within the population, in addition to the Account
              Eligibility Report.
            </Body>
            {hasReadTinsPermission && (
              <RhfCheckbox
                name="shouldIncludeDataValidationReport"
                label="Data Validation Report"
                trackingId={
                  DownloadEligibilityTableTrackingId.DataValidationReportCheckbox
                }
                description="Detailed errors and warnings on data that may impact reporting."
              />
            )}
            {!FORM_DATA_EXPORT_DISABLED_FORM_TYPES.includes(currentForm) && (
              <RhfCheckbox
                name="shouldIncludeFormDataReport"
                label="Form Data Export"
                trackingId={
                  DownloadEligibilityTableTrackingId.FormDataReportCheckbox
                }
                description="Form Data details that would currently be used to populate the tax form selected."
              />
            )}
            {hasReadTinsPermission && (
              <RhfCheckbox
                name="shouldIncludeAccountDataReport"
                label="Account Owner / Account Export"
                trackingId={
                  DownloadEligibilityTableTrackingId.AccountDataReportCheckbox
                }
                description="Account Owner / Account details that would currently be used to populate the tax form selected, including name, TIN, address, etc."
              />
            )}
          </Flex>
        </form>
      </FormProvider>
    </Modal>
  );
};

export default ExportEligibilityTableModal;
