import { Dropdown } from "@taxbit-private/cosmic";
import { FourDigitYear } from "@taxbit-private/datetime";
import { useCallback } from "react";

import useSharedFormDropdownData from "../../information-reporting/shared/useSharedFormDropdownData";
import { useOverviewDataContext } from "../context/useOverviewData";

export enum FormsYearPickerTrackingId {
  DropdownToggle = "form-data-year-picker",
}

type Props = {
  paramKey: "eligibility" | "forms";
};

const FormsYearPicker: React.FC<Props> = ({ paramKey }) => {
  const { setParamsForKey, urlParams } = useOverviewDataContext();

  const { years } = useSharedFormDropdownData();

  const onChange = useCallback(
    (value: FourDigitYear) => {
      setParamsForKey(paramKey, { year: value });
    },
    [setParamsForKey, paramKey]
  );

  return (
    <Dropdown
      value={urlParams[paramKey].year}
      onChange={onChange}
      options={years}
      getOptionKey={(year) => year.toString()}
      getOptionLabel={(year) => year.toString()}
      trackingId={FormsYearPickerTrackingId.DropdownToggle}
      size="small"
      label="Form Year"
      isLabelHidden={true}
    />
  );
};

export default FormsYearPicker;
