import {
  cosmicPaginationLimitSchema,
  pageNumberSchema,
  withOptionalCatch,
  searchValueStringSchema,
  getOffsetFromPage,
} from "@taxbit-dashboard/commons";
import {
  DashboardFormType,
  EligibilityStatus,
  GetEligibilitySearchKey,
  eligibilityStatusSchema,
  getEligibilitySearchKeySchema,
  EligibilityFormStatus,
  dashboardEligibilityFormStatusSchema,
  DashboardEligibilityFormStatus,
  AccountValidationsEligibilityStatus,
  accountValidationsEligibilityStatusSchema,
  CreateEligibilityExportBodyDto,
  EligibilityReportType,
  EligibilityFindAccountsRequest,
} from "@taxbit-dashboard/rest";
import { z } from "zod";

import { ExportEligibilityTableModalForm } from "../../../pages/information-reporting/eligible-users/context/exportEligibilityTableModalFormTypes";
import accountExternalIdsFilterToAccountExternalIds from "../../../utils/accountExternalIdsFilterToAccountExternalIds";
import {
  AccountExternalIdsFilter,
  IrFormTypeDateParams,
  mapDashboardFormTypeToApiFormType,
} from "../irApiTypes";

export type EligibilityDataParams = EligibilityTableParams &
  IrFormTypeDateParams & { limit: number };

export const UK_DOCUMENT_TYPES = new Set([DashboardFormType.UkGainLossSummary]);
export const NOT_REQUIRED_COMPLETE_DATA_DOCUMENT_TYPES = new Set([
  DashboardFormType.GainLossPdf,
  DashboardFormType.GainLossSummary,
  DashboardFormType.UkGainLossSummary,
]);

export const getEligibilityStatusLabelMap = (
  documentType: DashboardFormType
): Record<EligibilityStatus, string> => {
  const countryCodeStatus = (() => {
    if (UK_DOCUMENT_TYPES.has(documentType)) {
      return "Non-GB";
    }

    // for CESOP/DAC7 demo purposes only
    // should be removed when the feature is implemented
    if (
      [DashboardFormType.Cesop, DashboardFormType.Dac7].includes(documentType)
    ) {
      return "Transactions Under Threshold";
    }

    return "Non-US";
  })();

  return {
    [EligibilityStatus.Added]: "Eligible - Added",
    [EligibilityStatus.Eligible]: "Eligible",
    [EligibilityStatus.Removed]: "Removed From Scope",
    [EligibilityStatus.Exempt]:
      documentType === DashboardFormType.Dac7 ? "Non-EU" : "Exempt",
    [EligibilityStatus.NonValidTaxCountry]: countryCodeStatus,
    [EligibilityStatus.NotReportable]: [
      DashboardFormType.Cesop,
      DashboardFormType.Dac7,
    ].includes(documentType)
      ? "No Reportable Transactions"
      : "Not Reportable",
    [EligibilityStatus.Pending]: "Pending",
  };
};

export const toDashboardEligibilityFormStatus = (
  formStatus?: EligibilityFormStatus
): DashboardEligibilityFormStatus => {
  switch (formStatus) {
    case EligibilityFormStatus.Generated:
    case EligibilityFormStatus.Delivered: {
      return DashboardEligibilityFormStatus.Generated;
    }
    case EligibilityFormStatus.Filed: {
      return DashboardEligibilityFormStatus.Filed;
    }
    default: {
      return DashboardEligibilityFormStatus.NotGenerated;
    }
  }
};

export const dashboardEligibilityFormStatusLabelMap: Record<
  DashboardEligibilityFormStatus,
  string
> = {
  [DashboardEligibilityFormStatus.NotGenerated]: "Not Generated",
  [DashboardEligibilityFormStatus.Generated]: "Generated",
  [DashboardEligibilityFormStatus.Filed]: "Filed",
};

export const dashboardEligibilityFormStatusVariantMap: Record<
  DashboardEligibilityFormStatus,
  "success" | "secondary" | "danger"
> = {
  [DashboardEligibilityFormStatus.NotGenerated]: "danger",
  [DashboardEligibilityFormStatus.Generated]: "success",
  [DashboardEligibilityFormStatus.Filed]: "success",
};

export const accountValidationsEligibilityStatusLabelMap: Record<
  AccountValidationsEligibilityStatus,
  string
> = {
  [AccountValidationsEligibilityStatus.Complete]: "Complete - No Warnings",
  [AccountValidationsEligibilityStatus.CompleteWithWarnings]:
    "Complete - With Warnings",
  [AccountValidationsEligibilityStatus.Incomplete]: "Incomplete",
};

const searchKeyStringParams = Object.fromEntries(
  getEligibilitySearchKeySchema.options.map((key) => [
    key,
    searchValueStringSchema,
  ])
) as Record<GetEligibilitySearchKey, typeof searchValueStringSchema>;

export const eligibilitySearchKeyLabelMap: Record<
  GetEligibilitySearchKey,
  string
> = {
  external_id: "Account ID",
  name: "Name",
};

export const eligibilityEnumParamKeys = [
  "statuses",
  "accountValidationsEligibilityStatuses",
  "formStatuses",
] as const;

export const eligibilityTableParams = z.object({
  statuses: withOptionalCatch(z.array(eligibilityStatusSchema).optional()),
  accountValidationsEligibilityStatuses: withOptionalCatch(
    z.array(accountValidationsEligibilityStatusSchema).optional()
  ),
  formStatuses: withOptionalCatch(
    z.array(dashboardEligibilityFormStatusSchema).optional()
  ),
  page: pageNumberSchema,
  limit: cosmicPaginationLimitSchema,
  ...searchKeyStringParams,
});
export type EligibilityTableParams = z.infer<typeof eligibilityTableParams>;

export enum OverrideEligibilityAction {
  Add = "add",
  Remove = "remove",
}

export const getEligibilityExportRequest = ({
  accountExternalIdsFilter,
  shouldIncludeDataValidationReport,
  shouldIncludeFormDataReport,
  shouldIncludeAccountDataReport,
}: ExportEligibilityTableModalForm &
  AccountExternalIdsFilter): CreateEligibilityExportBodyDto => {
  const reportTypes: EligibilityReportType[] = [];

  if (shouldIncludeDataValidationReport) {
    reportTypes.push(EligibilityReportType.DataValidationReport);
  }
  if (shouldIncludeFormDataReport) {
    reportTypes.push(EligibilityReportType.FormDataReport);
  }
  if (shouldIncludeAccountDataReport) {
    reportTypes.push(EligibilityReportType.AccountDataReport);
  }

  const accountExternalIds = accountExternalIdsFilterToAccountExternalIds(
    accountExternalIdsFilter
  );

  return {
    reportTypes,
    externalIds: accountExternalIds.length > 0 ? accountExternalIds : undefined,
  };
};

export const toEligibilityFindAccountsRequest = ({
  limit,
  page,
  formDate,
  formType,
  formStatuses,
  accountExternalIdsFilter,
  accountValidationsEligibilityStatuses,
  statuses,
  name,
  external_id: externalId,
}: EligibilityDataParams &
  AccountExternalIdsFilter): EligibilityFindAccountsRequest => {
  const accountExternalIds = accountExternalIdsFilterToAccountExternalIds(
    accountExternalIdsFilter
  );

  return {
    filters: {
      accountValidationsEligibilityStatus:
        accountValidationsEligibilityStatuses,
      documentDate: formDate,
      documentType: mapDashboardFormTypeToApiFormType(formType),
      externalId,
      externalIds:
        accountExternalIds.length === 0 ? undefined : accountExternalIds,
      formStatus: formStatuses,
      name,
      status: statuses,
    },
    page: {
      limit,
      offset: getOffsetFromPage({ page, limit }),
    },
  };
};
