import { NumericalRowGroup } from "@taxbit-private/cosmic";
import styled from "styled-components";

/**
 * A `NumericalRowGroup` with a set width so that stacked cards with
 * row groups will align properly.
 */
const FixedWidthNumericalRowGroup = styled(NumericalRowGroup)(
  ({ theme }) => `
  min-width: ${theme.measure.m};
  width: ${theme.measure.m};
`
);

export default FixedWidthNumericalRowGroup;
