import { useLocation, useNavigate } from "@tanstack/react-location";
import {
  DashboardPrimaryTemplate,
  UserPermission,
  isDefined,
  useDashboardFeatureFlags,
  useNavigateBackWithFallback,
  useUserPermission,
} from "@taxbit-dashboard/commons";
import {
  KycTaxDocumentType,
  ReportingProfile as ReportingProfileType,
} from "@taxbit-dashboard/rest";
import {
  Box,
  ContentErrorEmptyState,
  ContentSpinner,
} from "@taxbit-private/cosmic";
import { COSMIC_VALUE_PLACEHOLDER } from "@taxbit-private/cosmic-localization";
import { useCallback, useMemo } from "react";

import { AccountDetails as AccountDetailsComponent } from "./account-details-tab/AccountDetails";
import Details from "./details-tab/Details";
import TaxDocumentation from "./documentation-tab/TaxDocumentation";
import FormData from "./form-data-tab/FormData";
import Inventory from "./inventory-tab/Inventory";
import ReportingProfile from "./reporting-profile-tab/ReportingProfile";
import TaxForms from "./tax-forms-tab/TaxForms";
import Transactions from "./transactions-tab/Transactions";
import { useAccountOwnerTaxDocumentContext } from "../../../hooks/useGetAccountOwnerTaxDocumentData";
import EntityNotFound from "../EntityNotFound";

export enum AccountDetailsTabTrackingId {
  ReportingProfileTab = "reporting-profile-tab",
  AccountDetailsTab = "account-details-tab",
  DetailsTab = "details-tab",
  TaxDocumentationTab = "tax-documentation-tab",
  TaxFormsTab = "tax-forms-tab",
  InventoryTab = "inventory-tab",
  FormDataTab = "form-data-tab",
  TransactioinsTab = "transactions-tab",
}

export enum AccountDetailsTrackingId {
  BackBtn = "account-details-back-button",
  LoadingSpinner = "account-details-loading-spinner",
}

const getNameByReportingProfileType = (
  profileType: ReportingProfileType["profileType"],
  reportingProfilesData?: ReportingProfileType[]
) =>
  reportingProfilesData?.find((profile) => profile.profileType === profileType)
    ?.profileData.name;

const AccountDetails: React.FC = () => {
  const {
    hasTaxDocumentationAccess,
    hasAccountInventoryAccess,
    hasAccountFormItemsAccess,
    showAccountDetailsReportingProfile,
    hasUsAccountsPageView,
    hasGlobalAccountsPageView,
  } = useDashboardFeatureFlags();

  const navigateBackWithFallback = useNavigateBackWithFallback();

  const navigate = useNavigate();

  const {
    current: { pathname },
  } = useLocation();

  const {
    account,
    accountOwner,
    isLoading,
    isError,
    isAccountNotFoundError,
    taxDocumentations,
    reportingProfiles,
  } = useAccountOwnerTaxDocumentContext();

  const canViewTaxForms = useUserPermission(
    UserPermission.ReadAccountDocuments
  );

  const canViewAssets = useUserPermission(UserPermission.ReadAccountAssets);

  const tabItems = [
    ...(showAccountDetailsReportingProfile
      ? [
          {
            label: "Reporting Profile",
            trackingId: AccountDetailsTabTrackingId.ReportingProfileTab,
          },
        ]
      : []),
    ...(showAccountDetailsReportingProfile
      ? [
          {
            label: "Details",
            trackingId: AccountDetailsTabTrackingId.AccountDetailsTab,
          },
        ]
      : [
          {
            label: "Details",
            trackingId: AccountDetailsTabTrackingId.DetailsTab,
          },
        ]),
    ...(hasTaxDocumentationAccess
      ? [
          {
            label: "Tax Documentation",
            trackingId: AccountDetailsTabTrackingId.TaxDocumentationTab,
          },
        ]
      : []),
    ...(canViewTaxForms
      ? [
          {
            label: "Tax Forms",
            trackingId: AccountDetailsTabTrackingId.TaxFormsTab,
          },
        ]
      : []),
    ...(canViewAssets && hasAccountInventoryAccess
      ? [
          {
            label: "Inventory",
            trackingId: AccountDetailsTabTrackingId.InventoryTab,
          },
        ]
      : []),
    ...(hasAccountFormItemsAccess
      ? [
          {
            label: "Form Data",
            trackingId: AccountDetailsTabTrackingId.FormDataTab,
          },
        ]
      : []),
    {
      label: "Transactions",
      trackingId: AccountDetailsTabTrackingId.TransactioinsTab,
    },
  ];

  const accountName = useCallback(() => {
    if (showAccountDetailsReportingProfile) {
      const usName = hasUsAccountsPageView
        ? getNameByReportingProfileType(
            "US",
            reportingProfiles?.reportingProfiles
          )
        : undefined;

      const globalName = hasGlobalAccountsPageView
        ? getNameByReportingProfileType(
            "GLOBAL",
            reportingProfiles?.reportingProfiles
          )
        : undefined;

      if (usName && globalName && usName !== globalName) {
        return `${usName} / ${globalName}`;
      }

      if (usName || globalName) {
        // we know that at least one of them is defined
        return (usName || globalName) as string;
      }
    }

    if (accountOwner?.pii.name) {
      return accountOwner.pii.name;
    }

    if (!showAccountDetailsReportingProfile) {
      const wForm = taxDocumentations?.find(
        (doc) =>
          doc.documentType === KycTaxDocumentType.W9 ||
          doc.documentType === KycTaxDocumentType.W8Ben ||
          doc.documentType === KycTaxDocumentType.W8BenE
      );

      if (isDefined(wForm)) {
        return wForm.name;
      }

      const dps = taxDocumentations?.find(
        (doc) => doc.documentType === KycTaxDocumentType.Dps
      );

      if (isDefined(dps) && dps.name) {
        return dps.name;
      }
    }

    return "Name Not Available";
  }, [
    accountOwner,
    taxDocumentations,
    showAccountDetailsReportingProfile,
    reportingProfiles,
    hasUsAccountsPageView,
    hasGlobalAccountsPageView,
  ])();

  const activeTab = useMemo(() => {
    const tabIds = Object.values(AccountDetailsTabTrackingId);
    const activeTabId = tabIds.find((tab) => pathname.includes(tab));

    const defaultTab = showAccountDetailsReportingProfile
      ? AccountDetailsTabTrackingId.ReportingProfileTab
      : AccountDetailsTabTrackingId.DetailsTab;

    return activeTabId ?? defaultTab;
  }, [pathname, showAccountDetailsReportingProfile]);

  return isLoading ? (
    <ContentSpinner trackingId={AccountDetailsTrackingId.LoadingSpinner} />
  ) : (
    <DashboardPrimaryTemplate
      title={accountName}
      onBack={() => navigateBackWithFallback("account-details", "/accounts")}
      backButtonTrackingId={AccountDetailsTrackingId.BackBtn}
      backButtonLabel="Back"
      subtitles={[account?.externalId || COSMIC_VALUE_PLACEHOLDER]}
      tabs={
        isAccountNotFoundError
          ? undefined
          : tabItems.map((tab) => ({
              ...tab,
              isActive: tab.trackingId === activeTab,
              onClick: () => navigate({ to: `../${tab.trackingId}` }),
            }))
      }
    >
      {isAccountNotFoundError ? (
        <EntityNotFound entity="Account" />
      ) : isError ? (
        <Box>
          <ContentErrorEmptyState entity="account" />
        </Box>
      ) : (
        <>
          {activeTab === AccountDetailsTabTrackingId.ReportingProfileTab && (
            <ReportingProfile />
          )}
          {activeTab === AccountDetailsTabTrackingId.AccountDetailsTab && (
            <AccountDetailsComponent />
          )}
          {activeTab === AccountDetailsTabTrackingId.DetailsTab && <Details />}
          {activeTab === AccountDetailsTabTrackingId.TaxDocumentationTab && (
            <TaxDocumentation />
          )}
          {activeTab === AccountDetailsTabTrackingId.TaxFormsTab && (
            <TaxForms />
          )}
          {activeTab === AccountDetailsTabTrackingId.InventoryTab && (
            <Inventory />
          )}
          {activeTab === AccountDetailsTabTrackingId.FormDataTab && (
            <FormData />
          )}
          {activeTab === AccountDetailsTabTrackingId.TransactioinsTab && (
            <Transactions />
          )}
        </>
      )}
    </DashboardPrimaryTemplate>
  );
};

export default AccountDetails;
