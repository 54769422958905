import { EligibilityByType, FormCountByType } from "@taxbit-dashboard/rest";
import { FourDigitYear } from "@taxbit-private/datetime";
import { useCallback } from "react";

import useSharedFormDropdownData from "../../pages/information-reporting/shared/useSharedFormDropdownData";

const isEligibilityResponse = (
  item: FormCountByType | EligibilityByType
): item is EligibilityByType => {
  return "totalEligibleAccounts" in item;
};

const useFilterResponseDataByFeatureFlag = () => {
  const { hasYearForForm: hasYearForFormItemsForm } = useSharedFormDropdownData(
    {
      shouldUseFormItemsForms: true,
    }
  );
  const { hasYearForForm } = useSharedFormDropdownData({
    shouldUseFormItemsForms: false,
  });

  return useCallback(
    <T extends FormCountByType | EligibilityByType>(
      data: T[],
      year: FourDigitYear
    ) => {
      const filteredData = data.filter((item) =>
        // We want to show all forms present in the FF for the eligibility overview,
        // but only form data eligible forms for the form data overview.
        isEligibilityResponse(item)
          ? hasYearForForm(item.documentType, year)
          : hasYearForFormItemsForm(item.documentType, year)
      );

      const total = filteredData.reduce((acc, item) => {
        const count = isEligibilityResponse(item)
          ? item.totalEligibleAccounts
          : item.totalFormItemsCreated;
        return acc + count;
      }, 0);

      return {
        filteredData,
        filteredTotal: total,
      };
    },
    [hasYearForForm, hasYearForFormItemsForm]
  );
};

export default useFilterResponseDataByFeatureFlag;
