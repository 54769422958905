import {
  navigateToUrl,
  useOrganizationPrefixedNavigation,
} from "@taxbit-dashboard/commons";
import { Notification, NotificationActionType } from "@taxbit-dashboard/rest";
import { Flex, Clickable } from "@taxbit-private/cosmic";

import { useFetchNotificationDownloadUrl } from "../../../api/notifications/notificationsApi";
import useNotificationsContext, {
  NotificationsContext,
} from "../useNotificationsContext";

type Props = Pick<Notification, "actions" | "notificationId"> & {
  context: NotificationsContext;
  markNotificationAsRead: (notificationId: string) => void;
};

enum NotificationsListItemCtasTrackingId {
  Primary = "primary-cta",
  Secondary = "secondary-cta",
}

const NotificationsListItemCtas: React.FC<Props> = ({
  actions,
  context,
  notificationId,
  markNotificationAsRead,
}) => {
  const { fetchNotificationDownloadUrl, isFetchingDownloadUrl } =
    useFetchNotificationDownloadUrl(notificationId);
  const { prefixedNavigate } = useOrganizationPrefixedNavigation();
  const { handleClose } = useNotificationsContext(context);

  if (!actions || actions.length === 0) {
    return undefined;
  }

  const [primaryAction, secondaryAction] = actions;

  const hasTwoCtas = !!secondaryAction;

  const getCtaClickableProps = (isSecondaryAction: boolean) => {
    const action = isSecondaryAction ? secondaryAction : primaryAction;

    const sharedProps = {
      variant: isSecondaryAction
        ? "button-tertiary"
        : hasTwoCtas
          ? "button-primary"
          : "anchor-primary",
      size: "small",
      label: action.actionText,
      trackingId: `${isSecondaryAction ? NotificationsListItemCtasTrackingId.Secondary : NotificationsListItemCtasTrackingId.Primary}-${notificationId}`,
    } as const;

    switch (action.type) {
      case NotificationActionType.Download: {
        return {
          ...sharedProps,
          onClick: () => {
            void fetchNotificationDownloadUrl();
            markNotificationAsRead(notificationId);
            handleClose?.();
          },
          loadingText: "Downloading...",
          isLoading: isFetchingDownloadUrl,
        };
      }
      case NotificationActionType.Href: {
        return {
          ...sharedProps,
          onClick: () => {
            prefixedNavigate({ to: action.actionUrl });
            markNotificationAsRead(notificationId);
            handleClose?.();
          },
        };
      }
      case NotificationActionType.Support: {
        return {
          ...sharedProps,
          onClick: () => {
            navigateToUrl(action.actionUrl);
            markNotificationAsRead(notificationId);
            handleClose?.();
          },
        };
      }
      default: {
        return sharedProps;
      }
    }
  };

  return (
    <Flex gap="s" padding="xs none none none">
      <Clickable {...getCtaClickableProps(false)} />
      {hasTwoCtas && <Clickable {...getCtaClickableProps(true)} />}
    </Flex>
  );
};

export default NotificationsListItemCtas;
