import {
  useDashboardStore,
  useOrganizationPrefixedNavigation,
  useSetCurrentPageAsBackHref,
} from "@taxbit-dashboard/commons";
import { DashboardFormType, EligibilityDataItem } from "@taxbit-dashboard/rest";
import { TableColumns } from "@taxbit-private/cosmic";
import { useCallback, useMemo } from "react";

import getBaseEligibilityTableColumns from "./baseEligibilityTableColumns";
import useForm1099DivEligibilityTableColumns from "./useForm1099DivEligibilityTableColumns";
import useForm1099IntEligibilityTableColumns from "./useForm1099IntEligibilityTableColumns";
import useForm1099KEligibilityTableColumns from "./useForm1099KEligibilityTableColumns";
import useForm1099MiscEligibilityTableColumns from "./useForm1099MiscEligibilityTableColumns";
import useForm1099NecEligibilityTableColumns from "./useForm1099NecEligibilityTableColumns";
import useForm1099REligibilityTableColumns from "./useForm1099REligibilityTableColumns";
import useForm5498EligibilityTableColumns from "./useForm5498EligibilityTableColumns";
import useFormCesopEligibilityTableColumns from "./useFormCesopEligibilityTableColumns";
import useFormRmdStatementEligibilityTableColumns from "./useFormRmdStatementEligibilityTableColumns";
import useFormSpecificEligibilityColumns from "./useFormSpecificEligibilityColumns";

const useEligibilityTableColumns = ({
  formType,
}: {
  formType: DashboardFormType;
}): TableColumns<EligibilityDataItem> => {
  const setCurrentPageAsBackHref = useSetCurrentPageAsBackHref();
  const resetPageContext = useDashboardStore((store) => store.resetPageContext);
  const { buildOrganizationPrefixedPath } = useOrganizationPrefixedNavigation();

  const onAccountLinkClick = useCallback(() => {
    // Start off page context for all nested views on a clean state.
    resetPageContext(["transactions", "transaction-details"]);
    setCurrentPageAsBackHref("account-details");
  }, [resetPageContext, setCurrentPageAsBackHref]);

  const getAccountLink = useCallback(
    (accountId: EligibilityDataItem["accountId"]) => {
      return buildOrganizationPrefixedPath(`/accounts/${accountId}`);
    },
    [buildOrganizationPrefixedPath]
  );

  const form1099KEligibilityTableColumns =
    useForm1099KEligibilityTableColumns();
  const form1099IntEligibilityTableColumns =
    useForm1099IntEligibilityTableColumns();
  const form1099NecEligibilityTableColumns =
    useForm1099NecEligibilityTableColumns();
  const form1099MiscEligibilityTableColumns =
    useForm1099MiscEligibilityTableColumns();
  const form1099DivEligibilityTableColumns =
    useForm1099DivEligibilityTableColumns();
  const formRmdStatementEligibilityTableColumns =
    useFormRmdStatementEligibilityTableColumns();
  const formCesopEligibilityTableColumns =
    useFormCesopEligibilityTableColumns();
  const form1099REligibilityTableColumns =
    useForm1099REligibilityTableColumns();
  const form5498EligibilityTableColumns = useForm5498EligibilityTableColumns();

  const { repTransactionsColumn } = useFormSpecificEligibilityColumns();

  return useMemo(() => {
    const baseColumns = getBaseEligibilityTableColumns({
      onAccountLinkClick,
      getAccountLink,
      formType,
    });

    switch (formType) {
      case DashboardFormType.Irs1099B:
      case DashboardFormType.GainLossSummary:
      case DashboardFormType.GainLossPdf: {
        return [...baseColumns, ...repTransactionsColumn];
      }
      case DashboardFormType.Irs1099K: {
        return [
          ...baseColumns,
          ...repTransactionsColumn,
          ...form1099KEligibilityTableColumns,
        ];
      }
      case DashboardFormType.Irs1099Nec: {
        return [
          ...baseColumns,
          ...repTransactionsColumn,
          ...form1099NecEligibilityTableColumns,
        ];
      }
      case DashboardFormType.Irs1099Int: {
        return [
          ...baseColumns,
          ...repTransactionsColumn,
          ...form1099IntEligibilityTableColumns,
        ];
      }
      case DashboardFormType.Irs1099Misc: {
        return [
          ...baseColumns,
          ...repTransactionsColumn,
          ...form1099MiscEligibilityTableColumns,
        ];
      }
      case DashboardFormType.Irs1099Div: {
        return [
          ...baseColumns,
          ...repTransactionsColumn,
          ...form1099DivEligibilityTableColumns,
        ];
      }
      case DashboardFormType.Irs1099R: {
        return [
          ...baseColumns,
          ...repTransactionsColumn,
          ...form1099REligibilityTableColumns,
        ];
      }
      case DashboardFormType.Irs5498: {
        return [
          ...baseColumns,
          ...repTransactionsColumn,
          ...form5498EligibilityTableColumns,
        ];
      }
      case DashboardFormType.RmdStatement: {
        return [...baseColumns, ...formRmdStatementEligibilityTableColumns];
      }
      case DashboardFormType.Cesop: {
        return [...baseColumns, ...formCesopEligibilityTableColumns];
      }
      case DashboardFormType.Dac7: {
        return [...baseColumns, ...repTransactionsColumn];
      }
      default: {
        return [...baseColumns, ...repTransactionsColumn];
      }
    }
  }, [
    form1099DivEligibilityTableColumns,
    form1099IntEligibilityTableColumns,
    form1099KEligibilityTableColumns,
    form1099MiscEligibilityTableColumns,
    form1099NecEligibilityTableColumns,
    form1099REligibilityTableColumns,
    form5498EligibilityTableColumns,
    formCesopEligibilityTableColumns,
    formRmdStatementEligibilityTableColumns,
    formType,
    getAccountLink,
    onAccountLinkClick,
    repTransactionsColumn,
  ]);
};

export default useEligibilityTableColumns;
