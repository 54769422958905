import { Schema, z } from "zod";

import JsonData from "./JsonData";

// This zod issue blocks us from creating generic return type for getPublicApiSuccessSchema
// https://github.com/colinhacks/zod/issues/995
// This type should be used in relation to the method
export type PublicApiSuccess<T extends JsonData> = {
  data: T;
  meta?: z.infer<typeof metaSchema>;
};

const metaSchema = z
  .object({
    // Standardized page metadata used across many APIs
    page: z
      .object({
        limit: z.number().optional(),
        offset: z.number().optional(),
        totalCount: z.number().optional(),
        next: z.string().optional(),
      })
      .optional(),
    // Used in forms and eligibility APIs
    totalAccountsNotFound: z.number().optional(),
    // Used in overview APIs
    total: z.number().optional(),
  })
  .optional();

/**
 * Wraps an API data schema in the expected shape for a successful response.
 * See the TaxBit public API specifications [here](https://taxbit.atlassian.net/wiki/spaces/TAXBIT/pages/1549631489/API+Specification+Guidelines) for reference.
 */
const getPublicApiSuccessSchema = <TDataSchema extends Schema>(
  dataSchema: TDataSchema
) =>
  z.object({
    data: dataSchema,
    meta: metaSchema,
  });

export default getPublicApiSuccessSchema;
