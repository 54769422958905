import { utcDateStringSchema } from "@taxbit-dashboard/commons";
import { fourDigitYearSchema } from "@taxbit-private/datetime";
import { useMemo } from "react";
import { z } from "zod";

import useSharedFormDropdownData from "../../pages/information-reporting/shared/useSharedFormDropdownData";
import getLastTaxYear from "../../utils/getLastTaxYear";

export const useOverviewUrlParamsSchema = () => {
  const { years } = useSharedFormDropdownData();

  const { year: lastTaxYear, dateStrings: defaultTaxYearDates } =
    getLastTaxYear();

  const defaultYear = years.includes(lastTaxYear) ? lastTaxYear : years[0];

  return useMemo(
    () =>
      z.object({
        transactions: z
          .object({
            startDate: utcDateStringSchema.catch(defaultTaxYearDates.startDate),
            endDate: utcDateStringSchema.catch(defaultTaxYearDates.endDate),
          })
          .catch(defaultTaxYearDates),
        forms: z
          .object({
            year: fourDigitYearSchema.catch(defaultYear),
          })
          .catch({
            year: defaultYear,
          }),
        eligibility: z
          .object({
            year: fourDigitYearSchema.catch(defaultYear),
          })
          .catch({
            year: defaultYear,
          }),
      }),
    [defaultYear, defaultTaxYearDates]
  );
};

export type OverviewUrlParams = z.infer<
  ReturnType<typeof useOverviewUrlParamsSchema>
>;
