import { DashboardPrimaryTemplate } from "@taxbit-dashboard/commons";
import { ContentErrorEmptyState, ContentSpinner } from "@taxbit-private/cosmic";

import DeliveryDeveloperSettingsCard from "./DeliveryDeveloperSettingsCard";
import GeneralDeveloperSettingsCard from "./GeneralDeveloperSettingsCard";
import useDeveloperSettings from "./useDeveloperSettings";

export enum DeveloperSettingsTrackingId {
  DeveloperSettingsSpinner = "developer-settings-spinner",
}

const DeveloperSettings = () => {
  const { isError, isLoading } = useDeveloperSettings();

  return (
    <DashboardPrimaryTemplate title="Developer Settings">
      {isLoading ? (
        <ContentSpinner
          trackingId={DeveloperSettingsTrackingId.DeveloperSettingsSpinner}
        />
      ) : isError ? (
        <ContentErrorEmptyState entity="developer settings" />
      ) : (
        <>
          <GeneralDeveloperSettingsCard />
          <DeliveryDeveloperSettingsCard />
        </>
      )}
    </DashboardPrimaryTemplate>
  );
};

export default DeveloperSettings;
