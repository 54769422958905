import { z } from "zod";

import getPublicApiSuccessSchema from "../getPublicApiSuccessSchema";

export const getOrganizationDevSettingsSchema = z.object({
  auth0ClientId: z.string(),
  auth0ClientSecret: z.string(),
  s3DropzoneBucket: z.string().optional(),
  s3DropzoneBucketAccessKey: z.string().optional(),
  s3DropzoneBucketSecretKey: z.string().optional(),
  deliveryToS3: z.boolean(),
  s3FolderPath: z.string().optional(),
  s3FileNameFormat: z.string().optional(),
  s3DropzoneBucketEncryptionStatus: z.boolean().optional(),
  s3DropzoneBucketEncryptionKey: z.string().optional(),
  s3FileFormat: z.string().optional(),
});

export type OrganizationDevSettings = z.infer<
  typeof getOrganizationDevSettingsSchema
>;

export const getOrganizationDevSettingsResponseSchema =
  getPublicApiSuccessSchema(getOrganizationDevSettingsSchema);

export type GetOrganizationDevSettingsResponse = z.infer<
  typeof getOrganizationDevSettingsResponseSchema
>;
