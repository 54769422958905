import { Flex, H2, H6Uppercase } from "@taxbit-private/cosmic";
import { useCosmicLocalizationContext } from "@taxbit-private/cosmic-localization";

import { useOverviewDataContext } from "../context/useOverviewData";

const TransactionsTotal = () => {
  const {
    transactionsQuery: { meta },
  } = useOverviewDataContext();
  const { formatWholeQuantity } = useCosmicLocalizationContext();

  return (
    <Flex direction="column">
      <H2 as="div">{formatWholeQuantity(meta?.total)}</H2>
      <H6Uppercase as="div" colorVariant="secondary">
        Total Transactions
      </H6Uppercase>
    </Flex>
  );
};

export default TransactionsTotal;
