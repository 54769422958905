import {
  DashboardNavigationItem,
  UserPermission,
  AuthorizedView,
} from "@taxbit-dashboard/commons";

import OverviewPage from "./Overview.page";

export const navigationItem: DashboardNavigationItem = {
  href: "/overview",
  iconName: "home",
  label: "Home",
  trackingId: "sidebar-navigation-overview-anchor",
  shouldShowRoute: ({ permissions, flags }) =>
    permissions[UserPermission.ReadAccounts] && flags.hasLandingPageAccess,
} as const;

export const route = {
  path: "overview",
  children: [
    {
      path: "/",
      element: (
        <AuthorizedView
          permissions={[UserPermission.ReadAccounts]}
          flags={["hasLandingPageAccess"]}
        >
          <OverviewPage />
        </AuthorizedView>
      ),
    },
  ],
};
