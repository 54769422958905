import { useUrlFilterParamsLegacy } from "@taxbit-dashboard/commons";
import { Transfer } from "@taxbit-dashboard/rest";
import { Anchor, TableColumns } from "@taxbit-private/cosmic";
import { useCosmicLocalizationContext } from "@taxbit-private/cosmic-localization";
import { useCallback, useMemo } from "react";

import useFormatAsset from "../../../../utils/useFormatAsset";
import getTransferIntentionLabel from "../../shared/getTransferIntentionLabel";
import TransferStatusBadge from "../../shared/TransferStatusBadge";
import useTransfersQuery from "../useTransfersQuery";
import validateTransfersUrlParams from "../validateTransfersUrlParams";

export const LEARN_MORE_URL =
  "https://taxbitsupport.zendesk.com/hc/en-us/articles/14065402988941";

export const HELP_ICON_TEXT =
  "This status represents if there is an identified match and if an action needs to be taken on the transfer.";

export enum TransfersTableTrackingId {
  HelpIcon = "transfers-table-status-help-icon",
  LearnMoreLink = "transfers-table-learn-more-link",
}

const useTransfersTableData = () => {
  const {
    urlParams,
    setUrlParams,
    hasUrlParams,
    setUrlParamsAndResetPagination: setFilterParams,
  } = useUrlFilterParamsLegacy({
    validateParams: validateTransfersUrlParams,
  });

  const queryData = useTransfersQuery({ urlParams });
  const shouldDisableControls = queryData.isLoading;

  const filterDrawerFilterCount = useMemo(() => {
    let count = 0;

    if (urlParams.startDate || urlParams.endDate) {
      count += 1;
    }

    if (urlParams.transferStatuses) {
      count += urlParams.transferStatuses.length;
    }

    return count;
  }, [urlParams]);

  const clearAllFilters = useCallback(
    () =>
      setFilterParams((draft) => {
        draft.transferStatuses = [];

        draft.startDate = undefined;
        draft.endDate = undefined;
      }),
    [setFilterParams]
  );

  const { formatDateTime } = useCosmicLocalizationContext();
  const formatAsset = useFormatAsset();

  const columns = useMemo(
    (): TableColumns<Transfer> => [
      {
        key: "datetime",
        label: "Date/Time",
        renderCell: (date) => formatDateTime({ date, format: "DateTime" }),
      },
      {
        key: "assetAmount",
        label: "Asset",
        textAlign: "right",
        renderCell: (value) => formatAsset({ assetAmount: value }),
        minWidth: 150,
      },
      {
        key: "status",
        label: "Status",
        renderCell: (value) => <TransferStatusBadge status={value} />,
        helpIconProps: {
          trackingId: TransfersTableTrackingId.HelpIcon,
          tooltipProps: {
            content: (
              <>
                {HELP_ICON_TEXT}{" "}
                <Anchor
                  trackingId={TransfersTableTrackingId.LearnMoreLink}
                  label="Learn More"
                  variant="anchor-primary-inline"
                  href={LEARN_MORE_URL}
                />
              </>
            ),
            appendTo: "parent",
            isInteractive: true,
            placement: "bottom",
          },
        },
      },
      {
        key: "intention",
        label: "Transfer Type",
        renderCell: (value) => getTransferIntentionLabel(value),
      },
    ],
    [formatAsset, formatDateTime]
  );

  return {
    clearAllFilters,
    filterDrawerFilterCount,
    urlParams,
    setPaginationParams: setUrlParams,
    setFilterParams,
    hasUrlParams,
    shouldDisableControls,
    columns,
    ...queryData,
  };
};

export default useTransfersTableData;
