import { OverviewUrlParams } from "./overviewApiTypes";

const getOverviewApiUrlParams = ({
  transactions,
  forms,
  eligibility,
}: OverviewUrlParams) => {
  return {
    transactions: {
      "filters[datetime][$gte]": transactions.startDate,
      "filters[datetime][$lte]": transactions.endDate,
    },
    forms: {
      "filters[document_date]": forms.year,
    },
    eligibility: {
      "filters[document_date]": eligibility.year,
    },
  };
};

export default getOverviewApiUrlParams;
