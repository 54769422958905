import { Modal } from "@taxbit-private/cosmic";

export enum AccountNotFoundModalTrackingId {
  Close = "account-not-found-modal-close-button",
  Primary = "account-not-found-modal-primary-button",
}

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

const AccountNotFoundModal: React.FC<Props> = ({ onClose, isOpen }) => {
  return (
    <Modal
      title="Account Not Found"
      onClose={onClose}
      isOpen={isOpen}
      closeButtonTrackingId={AccountNotFoundModalTrackingId.Close}
      primaryButtonProps={{
        label: "Close",
        trackingId: AccountNotFoundModalTrackingId.Primary,
        onClick: onClose,
      }}
    >
      Please enter an existing account ID.
    </Modal>
  );
};

export default AccountNotFoundModal;
