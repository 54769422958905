import {
  Link,
  useDashboardStore,
  useOrganizationPrefixedNavigation,
} from "@taxbit-dashboard/commons";
import {
  Button,
  Card,
  ContentErrorEmptyState,
  ContentSpinner,
  Flex,
  NumericalRowGroup,
} from "@taxbit-private/cosmic";
import { PieChart, Legend } from "@taxbit-private/cosmic-charts";
import styled from "styled-components";

import useEligibilityBreakdown from "./useEligibilityBreakdown";
import useRecalculateEligibility from "./useRecalculateEligibility";
import { useIrSummaryDataContext } from "../context/IrSummaryContext";

export enum FormEligibilityCardTrackingIds {
  Spinner = "irSummaryEligibilityCardSpinner",
  ManageButton = "irSummaryEligibilityManageButton",
  RecalculateButton = "irSummaryEligibilityRecalculateButton",
}

const FormEligibilityCard: React.FC = () => {
  const {
    hasRecalculateEligibilityAccess,
    isEligibilityCountsError,
    isEligibilityCountsLoading,
    shouldDisableControls,
  } = useIrSummaryDataContext();
  const resetPageContext = useDashboardStore((store) => store.resetPageContext);
  const { buildOrganizationPrefixedPath } = useOrganizationPrefixedNavigation();
  const { eligibilityRows, pieSections, shouldShowPieChart } =
    useEligibilityBreakdown();
  const { onRecalculateEligibility } = useRecalculateEligibility();

  return (
    <CardWithFlexValue
      flex={shouldShowPieChart ? 2 : 1}
      title="Form Eligibility"
      utilityElement={
        <Flex gap="s">
          <Link
            label="Manage"
            variant="button-secondary"
            trackingId={FormEligibilityCardTrackingIds.ManageButton}
            to={buildOrganizationPrefixedPath("/ir/eligibility")}
            onClick={() => resetPageContext(["eligibility-page"])}
            size="small"
          />
          {hasRecalculateEligibilityAccess && (
            <Button
              label="Recalculate Eligibility"
              variant="button-secondary"
              trackingId={FormEligibilityCardTrackingIds.RecalculateButton}
              isLoading={shouldDisableControls}
              onClick={onRecalculateEligibility}
              size="small"
            />
          )}
        </Flex>
      }
    >
      {isEligibilityCountsLoading ? (
        <ContentSpinner trackingId={FormEligibilityCardTrackingIds.Spinner} />
      ) : isEligibilityCountsError ? (
        <ContentErrorEmptyState entity="form eligibility" />
      ) : (
        <FlexWithItemsCentered
          growItems={true}
          padding="contentPadding"
          gap="l"
        >
          {shouldShowPieChart && (
            <Flex direction="column" gap="l" alignItems="center">
              <PieChart
                sections={pieSections}
                getSectionKey={({ key }) => key}
                getSectionValue={({ value }) => value}
                getSectionColor={({ color }) => color}
                getSectionTooltipContent={({ label }) => label}
                size="s"
              />
              <Legend
                sections={pieSections}
                direction="vertical"
                getSectionKey={({ key }) => key}
                getSectionLabel={({ label }) => label}
                getSectionColor={({ color }) => color}
              />
            </Flex>
          )}
          <NumericalRowGroup rows={eligibilityRows} />
        </FlexWithItemsCentered>
      )}
    </CardWithFlexValue>
  );
};

const CardWithFlexValue = styled(Card).withConfig<{
  flex: number;
}>({
  shouldForwardProp: (propName) => propName !== "flex",
})(
  ({ flex, theme }) => `
  flex: ${flex};
  // we need to account for the gap between form items count
  // for the flex to work correctly
  flex-basis: ${flex > 1 ? theme.spacing.l : "0"};
`
);

const FlexWithItemsCentered = styled(Flex)`
  align-items: center;
`;

export default FormEligibilityCard;
