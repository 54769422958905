import { DashboardFormType } from "@taxbit-dashboard/rest";
import { z } from "zod";

export const EXPORT_ELIGIBILITY_TABLE_FORM_ID = "export-eligibility-table-form";

export const FORM_DATA_EXPORT_DISABLED_FORM_TYPES = [
  DashboardFormType.Irs1099B,
  DashboardFormType.GainLossPdf,
  DashboardFormType.GainLossSummary,
];

export const exportEligibilityTableModalFormSchema = z.object({
  shouldIncludeDataValidationReport: z.boolean().optional(),
  shouldIncludeFormDataReport: z.boolean().optional(),
  shouldIncludeAccountDataReport: z.boolean().optional(),
});
export type ExportEligibilityTableModalForm = z.infer<
  typeof exportEligibilityTableModalFormSchema
>;

export const exportEligibilityTableModalFormDefaultValues = {
  shouldIncludeDataValidationReport: false,
  shouldIncludeFormDataReport: false,
  shouldIncludeAccountDataReport: false,
};
