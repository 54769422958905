import { z } from "zod";

import { taxDocumentationWithUuidSchema } from "./kycSharedTypes";
import {
  accountOwnerCuringSchema,
  accountOwnerIdSchema,
  accountOwnerPiiDtoSchema,
  accountOwnerSchema,
  accountV2Schema,
  companyUserIdSchema,
  irsTinMatchValidationSchema,
  taxDocumentStatusValidationSchema,
  vatValidationSchema,
} from "./sharedApiTypes";
import getPublicApiSuccessSchema from "../getPublicApiSuccessSchema";

export const accountOwnerValidationStatusValues = [
  "IN_PROGRESS",
  "PASSED",
  "FAILED",
  "ERROR",
  "COMPLETE",
] as const;

const accountOwnerValidationStatusSchema = z.enum(
  accountOwnerValidationStatusValues
);

export const accountOwnerGenericValidationSchema = z.object({
  accountOwnerId: accountOwnerIdSchema,
  validationDate: z.string(),
  targetDateModified: z.string(),
  validationType: z.string(),
  validationVersion: z.number(),
  validationStatus: accountOwnerValidationStatusSchema,
  validationResultCode: z.string().optional(),
  validationResults: z.any().optional(),
  validationErrors: z.string().array().optional(),
});

const accountOwnerValidationMapDtoSchema = z.object({
  irsTinMatch: irsTinMatchValidationSchema.optional(),
  vat: vatValidationSchema.optional(),
  taxDocumentStatus: taxDocumentStatusValidationSchema.optional(),
});

const accountOwnerDetailsAccountSchema = accountV2Schema
  .omit({
    accountOwner: true,
  })
  .extend({
    metadata: z.any().optional(),
  });

const accountOwnerDetailsAccountOwnerSchema = accountOwnerSchema.extend({
  pii: accountOwnerPiiDtoSchema,
  validations: z.array(accountOwnerGenericValidationSchema),
  validation: accountOwnerValidationMapDtoSchema,
  metadata: z.any().optional(),
  accounts: z.array(accountOwnerDetailsAccountSchema),
});

export enum AccountOwnerDetailsTaxDocumentationIssueType {
  W8benPoBoxPermanentAddress = "W8BEN_PO_BOX_PERMANENT_ADDRESS",
  W8benePoBoxPermanentAddress = "W8BENE_PO_BOX_PERMANENT_ADDRESS",
  UsPermanentAddress = "US_PERMANENT_ADDRESS",
  ExpiredForm = "EXPIRED_FORM",
  W8benCareOf = "W8BEN_CARE_OF_PERMANENT_ADDRESS",
  W8beneCareOf = "W8BENE_CARE_OF_PERMANENT_ADDRESS",
  UsIndicia = "US_INDICIA",
  TreatyCountryMismatch = "TREATY_COUNTRY_MISMATCH",
}

const accountOwnerCuringArchiveSchema = z.object({
  id: z.number(),
  companyUserId: companyUserIdSchema,
  accountOwnerCuringId: z.number(),
  note: z.string().optional(),
  createdAt: z.string(),
  updatedAt: z.string(),
});

const accountOwnerIssueSchema = z.object({
  issueType: z.nativeEnum(AccountOwnerDetailsTaxDocumentationIssueType),
  target: z.string(),
  groupNumber: z.number(),
  createdAt: z.string().datetime(),
  resolvedAt: z.string().datetime().optional(),
  accountOwnerCuring: accountOwnerCuringSchema
    .omit({
      accountOwnerId: true,
      organizationId: true,
    })
    .extend({
      archive: accountOwnerCuringArchiveSchema.optional(),
    })
    .optional(),
});

const accountOwnerDetailsSchema = z.object({
  accountOwner: accountOwnerDetailsAccountOwnerSchema,
  issues: z.array(accountOwnerIssueSchema),
  latestQuestionnaires: taxDocumentationWithUuidSchema.array(),
  latestTaxDocumentationGroupNumber: z.number(),
});

export const accountOwnerDetailsResponseSchema = getPublicApiSuccessSchema(
  accountOwnerDetailsSchema
);

export type AccountOwnerDetailsResponse = z.infer<
  typeof accountOwnerDetailsResponseSchema
>;

export type AccountOwner = z.infer<
  typeof accountOwnerDetailsAccountOwnerSchema
>;

export type AccountOwnerIssue = z.infer<typeof accountOwnerIssueSchema>;
