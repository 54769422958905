import {
  DashboardFeatureFlag,
  useDashboardFeatureFlags,
} from "@taxbit-dashboard/commons";
import { DashboardFormType } from "@taxbit-dashboard/rest";
import { FourDigitYear } from "@taxbit-private/datetime";
import { useCallback, useMemo } from "react";

import sortDashboardForms from "./sortDashboardForms";

export type YearsByFormFeatureFlag = Extract<
  DashboardFeatureFlag,
  | "ingestionModuleYearsByFormTemplate"
  | "formsPageYearsByFormType"
  | "generateFormsYearsByFormType"
>;

const useFormFeatureFlagData = ({
  featureFlag,
}: {
  featureFlag: YearsByFormFeatureFlag;
}) => {
  const yearsByFormType = useDashboardFeatureFlags()[featureFlag];

  const forms = useMemo(() => {
    return sortDashboardForms(
      Object.keys(yearsByFormType) as DashboardFormType[]
    );
  }, [yearsByFormType]);

  const years = useMemo(
    () =>
      [...new Set(Object.values(yearsByFormType).flat())].sort((a, b) => b - a),
    [yearsByFormType]
  );

  const hasForms = useMemo(() => forms.length > 0, [forms]);

  const getYearsForForm = useCallback(
    (form?: DashboardFormType) => {
      const yearsForForm = form ? yearsByFormType[form] ?? [] : [];
      return yearsForForm.sort((a, b) => b - a);
    },
    [yearsByFormType]
  );

  const hasYearForForm = useCallback(
    (form?: DashboardFormType, year?: FourDigitYear) => {
      if (form && yearsByFormType[form] && year) {
        return yearsByFormType[form]?.includes(year);
      } else {
        return false;
      }
    },
    [yearsByFormType]
  );

  return {
    forms,
    years,
    hasForms,
    getYearsForForm,
    hasYearForForm,
    yearsByFormType,
  };
};

export default useFormFeatureFlagData;
