import { Card, DenseRowGroup } from "@taxbit-private/cosmic";

import useGeneralDeveloperSettingsRows from "./useGeneralDeveloperSettingsRows";

const GeneralDeveloperSettingsCard = () => {
  const generalSettingsSections = useGeneralDeveloperSettingsRows();
  return (
    <Card title="General Settings">
      <DenseRowGroup sections={generalSettingsSections} />
    </Card>
  );
};

export default GeneralDeveloperSettingsCard;
