import { formatTaxId, isDefined } from "@taxbit-dashboard/commons";
import { KycTaxDocumentation } from "@taxbit-dashboard/rest";
import { ActionSnippet, DenseRowGroup, Modal } from "@taxbit-private/cosmic";
import {
  COSMIC_VALUE_PLACEHOLDER,
  useCosmicLocalizationContext,
} from "@taxbit-private/cosmic-localization";

import {
  taxClassificationMap,
  treatyClaimLimitationOnBenefitsMap,
  treatyClaimTypeOfIncomeMap,
} from "../../../../api/kyc-tax-documentation/kycTaxDocumentationApiTypes";
import { getIsoCountryName } from "../../../../utils/getIsoCountryName";
import { isWForm } from "../../../../utils/isWForm";
import maskTaxId from "../../../../utils/maskTaxId";
import AccountAddress from "../AccountAddress";
import InvalidCountry from "../InvalidCountry";

type TaxDocumentationDetailsModalProps = {
  shouldUnmaskValue: boolean;
  isOpen: boolean;
  onClose: () => void;
  data: KycTaxDocumentation;
};

export enum TaxDocumentationDetailsModalTrackingId {
  CloseBtn = "tax-documentation-details-modal-close-btn",
  PrimaryBtn = "tax-documentation-details-modal-primary-btn",
  DocumentId = "tax-documentation-details-modal-document-id",
  Tin = "tax-documentation-details-modal-tin",
  Ftin = "tax-documentation-details-modal-ftin",
  Vatin = "tax-documentation-details-modal-vatin",
  FinancialAccountId = "tax-documentation-details-modal-financial-account-id",
  BusinessRegistrationNumber = "tax-documentation-details-modal-business-registration-number",
  ResidencyTin = "tax-documentation-details-modal-residency-tin",
}

const TaxDocumentationDetailsModal = ({
  isOpen,
  onClose,
  data,
  shouldUnmaskValue,
}: TaxDocumentationDetailsModalProps) => {
  const { formatDateTime, formatBoolean } = useCosmicLocalizationContext();
  const tinType = data.documentType === "W-9" ? data.tinType : undefined;

  const w8Country =
    data.documentType === "W-8BEN" || data.documentType === "W-8BEN-E"
      ? getIsoCountryName(data.country)
      : undefined;

  const vatCountry =
    data.documentType === "DPS"
      ? getIsoCountryName(data.vatinCountry)
      : undefined;

  const birthCountry =
    data.documentType === "DPS"
      ? getIsoCountryName(data.countryOfBirth)
      : undefined;

  const businessRegistrationCountry =
    data.documentType === "DPS"
      ? getIsoCountryName(data.businessRegistrationCountry)
      : undefined;

  const treatyCountry =
    data.documentType === "W-8BEN" || data.documentType === "W-8BEN-E"
      ? getIsoCountryName(data.treatyClaimCountry)
      : undefined;

  const wFormCommonFields = isWForm(data)
    ? [
        {
          label: "Tax Classification",
          content: taxClassificationMap[data.taxClassification],
          key: "tax-classification",
        },
        {
          label: "TIN",
          content: (
            <ActionSnippet
              contents={formatTaxId(data.tin, tinType)}
              shouldMask={shouldUnmaskValue}
              maskContents={(value) =>
                maskTaxId(value, tinType) ?? COSMIC_VALUE_PLACEHOLDER
              }
              trackingId={TaxDocumentationDetailsModalTrackingId.Tin}
            />
          ),
          key: TaxDocumentationDetailsModalTrackingId.Tin,
        },
        {
          label: "Has Certified",
          content: formatBoolean(data.hasCertified),
          key: "has-certified",
        },
        {
          label: "Signature",
          content: data.signature,
          key: "signature",
        },
        {
          label: "Signature Date",
          content: formatDateTime({
            date: data.signatureTimestamp,
          }),
          key: "signature-date",
        },
      ]
    : [];

  const w9Fields =
    data.documentType === "W-9"
      ? [
          {
            label: "Doing Business As",
            content: data.dbaName,
            key: "dba-name",
          },
          {
            label: "Other Tax Classification",
            content: data.otherTaxClassification,
            key: "other-tax-classification",
          },
          {
            label: "Exempt Payee Code",
            content: data.exemptPayeeCode,
            key: "exempt-payee-code",
          },
          {
            label: "Exempt FATCA Code",
            content: data.exemptFatcaCode,
            key: "exempt-fatca-code",
          },
          {
            label: "Address",
            content: <AccountAddress address={data.address} />,
            key: "address",
          },
          {
            label: "TIN Type",
            content: data.tinType,
            key: "tin-type",
          },
          {
            label: "Subject to Backup Withholding",
            content: formatBoolean(!data.isNotSubjectBackupWithholding),
            key: "subject-to-backup-withholding",
          },
        ]
      : [];

  const w8CommonFields =
    data.documentType === "W-8BEN" || data.documentType === "W-8BEN-E"
      ? [
          {
            label: "Reference Numbers",
            content: data.referenceNumbers,
            key: "reference-numbers",
          },
          {
            label: "Country",
            content: w8Country === "" ? <InvalidCountry /> : w8Country,
            key: "country",
          },
          {
            label: "Permanent Address",
            content: <AccountAddress address={data.permanentAddress} />,
            key: "permanent-address",
          },
          {
            label: "Mailing Address",
            content: data.mailingAddress ? (
              <AccountAddress address={data.mailingAddress} />
            ) : (
              COSMIC_VALUE_PLACEHOLDER
            ),
            key: "mailing-address",
          },
          {
            label: "Foreign TIN",
            content: (
              <ActionSnippet
                contents={formatTaxId(data.ftin)}
                shouldMask={shouldUnmaskValue}
                maskContents={(value) =>
                  maskTaxId(value) ?? COSMIC_VALUE_PLACEHOLDER
                }
                trackingId={TaxDocumentationDetailsModalTrackingId.Ftin}
              />
            ),
            key: TaxDocumentationDetailsModalTrackingId.Ftin,
          },
          {
            label: "Foreign TIN Not Legally Required",
            content: formatBoolean(data.ftinNotLegallyRequired),
            key: "ftin-not-legally-required",
          },
          {
            label: "Treaty Claim Article Paragraph",
            content: data.treatyClaimArticleParagraph,
            key: "treaty-claim-article-paragraph",
          },
          {
            label: "Treaty Claim Country",
            content: treatyCountry === "" ? <InvalidCountry /> : treatyCountry,
            key: "treay-claim-country",
          },
          {
            label: "Treay Claim Has Additional Conditions",
            content: formatBoolean(data.treatyClaimHasAdditionalConditions),
            key: "treaty-claim-has-additional-conditions",
          },
          {
            label: "I Certify Treaty Claim Requirements",
            content: formatBoolean(data.treatyClaimICertifyRequirements),
            key: "treay-claim-i-certify-requirements",
          },
          {
            label: "I Certity Treaty Claim Resident",
            content: formatBoolean(data.treatyClaimICertifyResident),
            key: "treaty-claim-i-certify-resident",
          },
          {
            label: "Treay Claim Is Eligible",
            content: formatBoolean(data.treatyClaimIsEligible),
            key: "treay-claim-is-eligible",
          },
          {
            label: "Treay Claim Rate Of Withholding",
            content: data.treatyClaimRateOfWithholding,
            key: "treay-claim-rate-of-withholding",
          },
          {
            label: "Treay Claim Type Of Income",
            content: data.treatyClaimTypeOfIncome
              ? treatyClaimTypeOfIncomeMap[data.treatyClaimTypeOfIncome]
              : COSMIC_VALUE_PLACEHOLDER,
            key: "treay-claim-type-of-income",
          },
        ]
      : [];

  const w8BenEFields =
    data.documentType === "W-8BEN-E"
      ? [
          {
            label: "Treay Claim Limitation On Benefits",
            content: data.treatyClaimLimitationOnBenefits
              ? treatyClaimLimitationOnBenefitsMap[
                  data.treatyClaimLimitationOnBenefits
                ]
              : COSMIC_VALUE_PLACEHOLDER,
            key: "treay-claim-limitation-on-benefits",
          },
          {
            label: "Treay Claim Limitation On Benefits Other Article Paragraph",
            content: data.treatyClaimLimitationOnBenefitsOtherArticleParagraph,
            key: "treay-claim-limitation-on-benefits-other-article-paragraph",
          },
        ]
      : [];

  const dateOfBirthField =
    data.documentType === "W-8BEN" || data.documentType === "DPS"
      ? [
          {
            label: "Date of Birth",
            content: formatDateTime({
              date: data.dateOfBirth,
            }),
            key: "date-of-birth",
          },
        ]
      : [];

  const dpsFields =
    data.documentType === "DPS"
      ? [
          {
            label: "Address",
            content: data.address ? (
              <AccountAddress address={data.address} />
            ) : (
              COSMIC_VALUE_PLACEHOLDER
            ),
            key: "address",
          },
          {
            label: "VAT Number",
            content: (
              <ActionSnippet
                contents={formatTaxId(data.vatin)}
                shouldMask={shouldUnmaskValue}
                maskContents={(value) =>
                  maskTaxId(value) ?? COSMIC_VALUE_PLACEHOLDER
                }
                trackingId={TaxDocumentationDetailsModalTrackingId.Vatin}
              />
            ),
            key: TaxDocumentationDetailsModalTrackingId.Vatin,
          },
          {
            label: "VAT Country",
            content: vatCountry === "" ? <InvalidCountry /> : vatCountry,
            key: "vat-country",
          },
          {
            label: "VAT Number Not Required",
            content: formatBoolean(data.vatinNotRequired),
            key: "vat-number-not-required",
          },
          {
            label: "Financial Account ID",
            content: (
              <ActionSnippet
                contents={formatTaxId(data.financialAccountIdentifier)}
                shouldMask={shouldUnmaskValue}
                maskContents={(value) =>
                  maskTaxId(value) ?? COSMIC_VALUE_PLACEHOLDER
                }
                trackingId={
                  TaxDocumentationDetailsModalTrackingId.FinancialAccountId
                }
              />
            ),
            key: TaxDocumentationDetailsModalTrackingId.FinancialAccountId,
          },
          {
            label: "Financial Account Holder Name",
            content: data.financialAccountName,
            key: "financial-account-holder-name",
          },
          {
            label: "Is Confirmed Correct Complete",
            content: formatBoolean(data.isConfirmedCorrectComplete),
            key: "is-confirmed-correct-complete",
          },
          {
            label: "Birth City",
            content: data.cityOfBirth,
            key: "birth-city",
          },
          {
            label: "Birth Country",
            content: birthCountry === "" ? <InvalidCountry /> : birthCountry,
            key: "birth-country",
          },
          {
            label: "Business Registration Number",
            content: (
              <ActionSnippet
                contents={formatTaxId(data.businessRegistrationNumber)}
                shouldMask={shouldUnmaskValue}
                maskContents={(value) =>
                  maskTaxId(value) ?? COSMIC_VALUE_PLACEHOLDER
                }
                trackingId={
                  TaxDocumentationDetailsModalTrackingId.BusinessRegistrationNumber
                }
              />
            ),
            key: TaxDocumentationDetailsModalTrackingId.BusinessRegistrationNumber,
          },
          {
            label: "Business Registration Country",
            content:
              businessRegistrationCountry === "" ? (
                <InvalidCountry />
              ) : (
                businessRegistrationCountry
              ),
            key: "business-registration-country",
          },
          {
            label: "Is Individual",
            content: formatBoolean(data.isIndividual),
            key: "is-individual",
          },
        ]
      : [];

  const dpsResidenceFields =
    data.documentType === "DPS" &&
    isDefined(data.taxResidences) &&
    data.taxResidences.length > 0
      ? data.taxResidences.map(({ tin, tinNotRequired, country }) => {
          const residenceCountry = getIsoCountryName(country);
          return {
            key: `residency-${country}`,
            rows: [
              {
                label: "TIN",
                content: (
                  <ActionSnippet
                    contents={formatTaxId(tin)}
                    shouldMask={shouldUnmaskValue}
                    maskContents={(value) =>
                      maskTaxId(value) ?? COSMIC_VALUE_PLACEHOLDER
                    }
                    trackingId={`${TaxDocumentationDetailsModalTrackingId.ResidencyTin}-${country}`}
                  />
                ),
                key: `${TaxDocumentationDetailsModalTrackingId.ResidencyTin}-${country}`,
              },
              {
                label: "TIN Not Required",
                content: tinNotRequired
                  ? formatBoolean(tinNotRequired)
                  : COSMIC_VALUE_PLACEHOLDER,
                key: `tin-not-required-${country}`,
              },
              {
                label: "Country",
                content:
                  residenceCountry === "" ? (
                    <InvalidCountry />
                  ) : (
                    residenceCountry
                  ),
                key: `country-${country}`,
              },
            ],
          };
        })
      : [];

  return (
    <Modal
      title={`${data.documentType} Details`}
      isOpen={isOpen}
      onClose={onClose}
      size="wide"
      primaryButtonProps={{
        label: "Close",
        trackingId: TaxDocumentationDetailsModalTrackingId.PrimaryBtn,
        onClick: onClose,
      }}
      closeButtonTrackingId={TaxDocumentationDetailsModalTrackingId.CloseBtn}
    >
      <DenseRowGroup
        sections={[
          {
            key: "general-data",
            rows: [
              {
                label: "Document ID",
                content: (
                  <ActionSnippet
                    contents={data.id}
                    shouldCopy={true}
                    trackingId={
                      TaxDocumentationDetailsModalTrackingId.DocumentId
                    }
                  />
                ),
                key: TaxDocumentationDetailsModalTrackingId.DocumentId,
              },
              { label: "Name", content: data.name, key: "name" },
              {
                label: "Document Type",
                content: data.documentType,
                key: "documentType",
              },
              ...wFormCommonFields,
              ...w9Fields,
              ...w8CommonFields,
              ...w8BenEFields,
              ...dateOfBirthField,
              ...dpsFields,
            ],
          },
          ...dpsResidenceFields,
        ]}
      />
    </Modal>
  );
};

export default TaxDocumentationDetailsModal;
