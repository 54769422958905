import {
  CosmicDateRangePickerValue,
  UtcDateRangePicker,
} from "@taxbit-private/cosmic";
import { useCallback } from "react";

import { useOverviewDataContext } from "../context/useOverviewData";

export enum TransactionsChartDateRangePickerTrackingId {
  Picker = "transactions-chart-date-range-picker",
}

const TransactionsChartDateRangePicker = () => {
  const {
    setParamsForKey,
    urlParams: { transactions },
  } = useOverviewDataContext();

  const onChange = useCallback(
    ({ startDate, endDate }: CosmicDateRangePickerValue) => {
      setParamsForKey("transactions", { startDate, endDate });
    },
    [setParamsForKey]
  );

  return (
    <UtcDateRangePicker
      isLabelHidden={true}
      label="Select a date range"
      onChange={onChange}
      size="small"
      trackingId={TransactionsChartDateRangePickerTrackingId.Picker}
      value={transactions}
    />
  );
};

export default TransactionsChartDateRangePicker;
