import { byIso } from "country-code-lookup";

export const getIsoCountryName = (countryCode?: string) => {
  if (!countryCode) {
    return undefined;
  }

  try {
    return byIso(countryCode)?.country;
  } catch {
    return "";
  }
};
