import {
  Card,
  ContentErrorEmptyState,
  ContentSpinner,
  DenseRowGroup,
} from "@taxbit-private/cosmic";

import { useAccountDetailsRows } from "./useAccountDetailsRows";
import { useGetAccountDetails } from "./useGetAccountDetails";

export enum AccountDetailsTrackingId {
  AccountDetailsSpinner = "account-details-spinner",
}

export const AccountDetails: React.FC = () => {
  const { isAccountLoading, account, accountOwner, shouldUnmaskValue } =
    useGetAccountDetails();

  const accountDetailsRows = useAccountDetailsRows({
    account,
    accountOwner,
    shouldUnmaskValue,
  });

  return (
    <>
      <Card title="Account Owner">
        {isAccountLoading ? (
          <ContentSpinner
            trackingId={AccountDetailsTrackingId.AccountDetailsSpinner}
          />
        ) : accountDetailsRows ? (
          <DenseRowGroup sections={accountDetailsRows.accountOwnerData} />
        ) : (
          <ContentErrorEmptyState entity="account owner" />
        )}
      </Card>
      <Card title="Account Details">
        {isAccountLoading ? (
          <ContentSpinner
            trackingId={AccountDetailsTrackingId.AccountDetailsSpinner}
          />
        ) : accountDetailsRows ? (
          <DenseRowGroup sections={accountDetailsRows.accountData} />
        ) : (
          <ContentErrorEmptyState entity="account" />
        )}
      </Card>
    </>
  );
};
