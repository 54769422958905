import { mapObject } from "@taxbit-dashboard/commons";
import { DashboardFormType } from "@taxbit-dashboard/rest";
import { FourDigitYear, fourDigitYearSchema } from "@taxbit-private/datetime";

import {
  toUpdatedDashboardFormType,
  supportedFormItemTypesFromDashboardFormTypes,
} from "../api/form-items/formItemsApiTypes";

export const calculateFormTypeToYearsMap = ({
  irFormTypesByYear,
  shouldUseFormItemsForms,
}: {
  irFormTypesByYear: Record<string, DashboardFormType[]>;
  shouldUseFormItemsForms: boolean;
}) => {
  const formTypesByYearMap = {} as Record<DashboardFormType, FourDigitYear[]>;

  for (const [year, formTypes] of Object.entries(irFormTypesByYear)) {
    // Form Items Document type has no difference between G/L, UK G/L and G/L PDF
    // So in case of Form Items we need to unify them
    const updatedFormTypes = shouldUseFormItemsForms
      ? formTypes.map((type) => toUpdatedDashboardFormType(type))
      : formTypes;

    let uniqueFormTypes = [...new Set(updatedFormTypes)] as DashboardFormType[];

    /**
     * CESOP exists in some demo feature flags, but no IR pages currently support it.
     */
    uniqueFormTypes = uniqueFormTypes.filter(
      (formType) => formType !== DashboardFormType.Cesop
    );

    if (shouldUseFormItemsForms) {
      uniqueFormTypes = uniqueFormTypes.filter((formType) =>
        supportedFormItemTypesFromDashboardFormTypes.has(formType)
      );
    }

    for (const formType of uniqueFormTypes) {
      if (!formTypesByYearMap[formType]) {
        formTypesByYearMap[formType] = [];
      }

      formTypesByYearMap[formType].push(fourDigitYearSchema.parse(year));
    }
  }

  return mapObject(formTypesByYearMap, (key, value) => [
    key,
    value.sort((a, b) => b - a),
  ]);
};
