import { EligibilityDataItem, EligibilityStatus } from "@taxbit-dashboard/rest";
import { Badge, TableColumns } from "@taxbit-private/cosmic";
import {
  useCosmicLocalizationContext,
  COSMIC_VALUE_PLACEHOLDER,
} from "@taxbit-private/cosmic-localization";
import { useMemo } from "react";

export enum FormSpecificEligibilityTableTrackingIds {
  TxnsTooltip = "irEligibilityTableTxnsTooltip",
}

const TXNS_TOOLTIP_CONTENT =
  "Number of relevant transactions based on the form type selected";

const useFormSpecificEligibilityColumns = () => {
  const { formatWholeQuantity } = useCosmicLocalizationContext();

  const repTransactionsColumn = useMemo(
    (): TableColumns<EligibilityDataItem> => [
      {
        key: "taxYearTotalTaxableTransactionCount",
        label: "Txns",
        textAlign: "right",
        isContentFullWidth: true,
        renderCell: (taxYearTotalTaxableTransactionCount, eligibilityData) => {
          if (eligibilityData.isFormData) {
            return <Badge variant="secondary" label="Form Data" />;
          } else if (eligibilityData.status === EligibilityStatus.Pending) {
            return COSMIC_VALUE_PLACEHOLDER;
          } else {
            return formatWholeQuantity(
              taxYearTotalTaxableTransactionCount ?? 0
            );
          }
        },
        helpIconProps: {
          trackingId: FormSpecificEligibilityTableTrackingIds.TxnsTooltip,
          tooltipProps: {
            content: TXNS_TOOLTIP_CONTENT,
            appendTo: "body",
            isInteractive: false,
          },
        },
      },
    ],
    [formatWholeQuantity]
  );

  return { repTransactionsColumn };
};

export default useFormSpecificEligibilityColumns;
