import {
  ToastConfig,
  UrlFilterParams,
  readonlyIncludes,
  useDashboardStore,
  useUrlFilterParamsLegacy,
} from "@taxbit-dashboard/commons";
import { getFormsSearchKeySchema } from "@taxbit-dashboard/rest";
import { useCosmicLocalizationContext } from "@taxbit-private/cosmic-localization";
import { useCallback, useMemo, useState } from "react";

import validateFormsTableUrlParams from "./validateFormsTableUrlParams";
import { useGetForms } from "../../../../api/information-reporting/forms/formsApi";
import {
  FormsTableParams,
  formsEnumParamKeys,
} from "../../../../api/information-reporting/forms/formsApiTypes";
import { TOAST_TIMEOUT } from "../../../../utils/toastTimeout";
import useFormFeatureFlagDropdowns from "../../shared/useFormFeatureFlagDropdowns";

export const getMissingAccountsToastContent = (
  totalAccountsNotFound: string,
  requestIdsLength: string
): ToastConfig => {
  return {
    message: `${totalAccountsNotFound}/${requestIdsLength} accounts were not found.`,
    variant: "danger",
    timeoutMs: TOAST_TIMEOUT,
    trackingId: "forms-table-missing-accounts-toast",
  };
};

const useFormsTableData = ({
  accountExternalIdsFilter,
  setAccountExternalIdsFilter,
}: {
  accountExternalIdsFilter: string;
  setAccountExternalIdsFilter: (value: string) => void;
}) => {
  const [shouldShowMissingAccountWarning, setShouldShowMissingAccountWarning] =
    useState(true);

  const addToast = useDashboardStore((state) => state.addToast);
  const { formatWholeQuantity } = useCosmicLocalizationContext();

  const { currentForm, currentYear } = useFormFeatureFlagDropdowns({
    featureFlag: "formsPageYearsByFormType",
  });

  const validateParams = useCallback(
    (rawUrlParams: UrlFilterParams<FormsTableParams>) =>
      validateFormsTableUrlParams(rawUrlParams, {
        formDate: currentYear,
        formType: currentForm,
      }),
    [currentForm, currentYear]
  );

  const {
    urlParams,
    setUrlParams,
    setUrlParamsAndResetPagination: setFilterParams,
  } = useUrlFilterParamsLegacy({
    validateParams,
  });

  const query = useGetForms({
    ...urlParams,
    accountExternalIdsFilter,
    onMissingAccounts: (requestIdsLength, totalAccountsNotFound) => {
      if (shouldShowMissingAccountWarning) {
        addToast(
          getMissingAccountsToastContent(
            formatWholeQuantity(totalAccountsNotFound),
            formatWholeQuantity(requestIdsLength)
          )
        );
      }
    },
  });

  const clearAllFilters = useCallback(() => {
    setFilterParams((draft) => {
      for (const key of getFormsSearchKeySchema.options) {
        draft[key] = undefined;
      }

      for (const key of formsEnumParamKeys) {
        draft[key] = [];
      }

      draft.startDate = undefined;
      draft.endDate = undefined;
    });

    setAccountExternalIdsFilter("");
  }, [setFilterParams, setAccountExternalIdsFilter]);

  const filterDrawerFilterCount = useMemo(() => {
    const urlFilterCount = Object.keys(urlParams).reduce((count, key) => {
      if (key === "startDate" && urlParams.startDate) {
        return count + 1;
      } else if (readonlyIncludes(formsEnumParamKeys, key) && urlParams[key]) {
        return count + (urlParams[key]?.length ?? 0);
      }

      return count;
    }, 0);

    if (accountExternalIdsFilter.length > 0) {
      return urlFilterCount + 1;
    }

    return urlFilterCount;
  }, [urlParams, accountExternalIdsFilter]);

  return {
    ...query,
    urlParams,
    clearAllFilters,
    setPaginationParams: (setter: (draft: FormsTableParams) => void) => {
      // We don't want to show warning on pagination change
      setShouldShowMissingAccountWarning(false);
      setUrlParams(setter);
    },
    setFilterParams: (setter: (draft: FormsTableParams) => void) => {
      setShouldShowMissingAccountWarning(true);
      setFilterParams(setter);
    },
    filterDrawerFilterCount,
    shouldDisableControls: query.isLoading || query.isPreviousData,
    totalCount: query.meta?.page?.totalCount ?? 0,
  };
};

export default useFormsTableData;
