import { fourDigitYearSchema } from "@taxbit-private/datetime";

const getLastTaxYear = () => {
  const today = new Date();

  const year = fourDigitYearSchema.parse(today.getFullYear() - 1);

  const startDate = `${year}-01-01T00:00:00.000Z`;
  const endDate = `${year}-12-31T23:59:59.999Z`;

  return {
    year,
    dateStrings: {
      startDate,
      endDate,
    },
  };
};

export default getLastTaxYear;
