import { useDashboardFeatureFlags } from "@taxbit-dashboard/commons";
import { Body, Divider, Flex, HelpIcon, Modal } from "@taxbit-private/cosmic";
import {
  RhfCheckbox,
  RhfRadioGroup,
} from "@taxbit-private/cosmic-react-hook-form";
import { FormProvider } from "react-hook-form";
import styled from "styled-components";

import useGenerateTaxFormsConfirmationModal, {
  GenerateTaxFormsConfirmationModalTrackingId,
} from "./useGenerateTaxFormsConfirmationModal";
import {
  FORM_GENERATION_FORM_ID,
  FormGenerationModalForm,
} from "../../context/formGenerationModalFormTypes";

type GenerateTaxFormsConfirmationModalProps = {
  shouldIgnoreSelectedItems?: boolean;
  onClose: () => void;
  isOpen: boolean;
};

const GenerateTaxFormsConfirmationModal = ({
  shouldIgnoreSelectedItems = false,
  onClose,
  isOpen,
}: GenerateTaxFormsConfirmationModalProps) => {
  const { hasDisableFormGenerationDataComparisonAccess } =
    useDashboardFeatureFlags();

  const {
    formattedTotalAccounts,
    formGenerationFormMethods,
    handleSubmit,
    hasFiltersApplied,
    isLoading,
    populationRadioGroupOptions,
  } = useGenerateTaxFormsConfirmationModal({
    shouldIgnoreSelectedItems,
    onClose,
  });

  const shouldShowCheckboxes =
    hasDisableFormGenerationDataComparisonAccess ||
    (shouldIgnoreSelectedItems && hasFiltersApplied);

  return (
    <Modal
      title="Generate Forms"
      isOpen={isOpen}
      onClose={onClose}
      closeButtonTrackingId={
        GenerateTaxFormsConfirmationModalTrackingId.CloseButton
      }
      secondaryButtonProps={{
        label: "Cancel",
        trackingId: GenerateTaxFormsConfirmationModalTrackingId.CancelButton,
        onClick: onClose,
      }}
      primaryButtonProps={{
        form: FORM_GENERATION_FORM_ID,
        type: "submit",
        label: "Generate forms",
        trackingId: GenerateTaxFormsConfirmationModalTrackingId.SubmitButton,
        isLoading,
        loadingText: "Processing",
        variant: "button-primary",
      }}
    >
      <FormProvider {...formGenerationFormMethods}>
        <form id={FORM_GENERATION_FORM_ID} onSubmit={handleSubmit}>
          <Flex direction="column" gap="m">
            <div>
              <TextContainer>
                Clicking &quot;Generate forms&quot; below will initiate form
                generation
                {!shouldIgnoreSelectedItems || !hasFiltersApplied
                  ? formattedTotalAccounts
                  : undefined}
                .{" "}
              </TextContainer>
              <HelpIconContainer>
                <HelpIcon
                  trackingId={
                    GenerateTaxFormsConfirmationModalTrackingId.HelpIcon
                  }
                  tooltipProps={{
                    maxWidth: "320px",
                    content: (
                      <Flex direction="column" gap="m">
                        <Body>
                          A form is generated only if account data is complete
                          and differs from any previously generated form.
                        </Body>
                        <Body>
                          Form Types:
                          <ul>
                            <li>
                              <BoldSpan>Original:</BoldSpan> Generated if no
                              form has been previously filed for an eligible
                              account.
                            </li>
                            <li>
                              <BoldSpan>Correction:</BoldSpan> Generated for an
                              eligible account if a form has been previously
                              filed, and updated data has been provided.
                            </li>
                            <li>
                              <BoldSpan>Void:</BoldSpan> Generated if a form has
                              been previously filed and the Account is no longer
                              eligible.
                            </li>
                          </ul>
                        </Body>
                      </Flex>
                    ),
                  }}
                />
              </HelpIconContainer>
            </div>
            {shouldShowCheckboxes && (
              <>
                {shouldIgnoreSelectedItems && hasFiltersApplied && (
                  <RhfRadioGroup<FormGenerationModalForm, "population">
                    name="population"
                    label="Which population would you like to generate forms for?"
                    options={populationRadioGroupOptions}
                    trackingId={
                      GenerateTaxFormsConfirmationModalTrackingId.PopulationRadioGroup
                    }
                  />
                )}
                {hasDisableFormGenerationDataComparisonAccess && (
                  <>
                    <Divider />
                    <RhfCheckbox
                      name="shouldSkipCompareData"
                      label="Generate forms without data comparison"
                      trackingId={
                        GenerateTaxFormsConfirmationModalTrackingId.DataComparisonCheckbox
                      }
                    />
                  </>
                )}
              </>
            )}
          </Flex>
        </form>
      </FormProvider>
    </Modal>
  );
};

const BoldSpan = styled.span(
  ({ theme }) => `
  font-weight: ${theme.fontWeight.bold};`
);

const TextContainer = styled(Body)`
  display: inline;
`;

const HelpIconContainer = styled.div`
  display: inline-block;
  vertical-align: middle;
`;

export default GenerateTaxFormsConfirmationModal;
